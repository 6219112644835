import { config, mapToQueryString, request } from '@utils/index';

import {
  GetQuestionListReq,
  GetQuestionListRes
} from './collection.type';
import { getAnswerDetail } from './feed';
/** 获取问题列表 */
// export const getQuestionList = (
//   params: GetQuestionListReq
// ): Promise<APIResponse<GetQuestionListRes>> => {
//   const URL = `${config.api.social_host}${config.api.collection.questionList
//   }?${mapToQueryString(params ?? {})}`;
//   return request.get.call({}, URL);
// };

/**
 * 获取回答列表
 */
export const getQuestionList = (params: GetQuestionListReq): Promise<APIResponse<GetQuestionListRes>> => {
  const { slug, id, ...otherParams } = params;
  const URL = `${config.api.social_host}${config.api.feed.getFeedList.replace(/{feed_slug}/, slug).replace(/{resource_id}/, id)
  }?${mapToQueryString(otherParams ?? {})}`;
  return request.get.call({}, URL);
};
/**
 * 获取问题详情
 */
export const getQuestionDetail = getAnswerDetail;
