import dayjs from 'dayjs';
import { type Dayjs } from 'dayjs';

import isToday from 'dayjs/plugin/isToday';
// import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';
// import 'dayjs/locale/zh-cn'

// dayjs.locale('zh-cn')
dayjs.extend(isToday);
// dayjs.extend(isYesterday);
dayjs.extend(relativeTime);

// 当天：5分钟前/5小时前
// 当天之前：08/28 16:09
export const customizeRelativeTime = (time: number | Dayjs = dayjs()) => {
  const day = dayjs(time);
  if (day.isToday()) {
    return dayjs().to(day);
  }
  if (day.year() === dayjs().year()) {
    return day.format('MM/DD HH:mm');
  }
  return day.format('MM/DD/YYYY HH:mm');
};
// 时间在今年内显示纬度时月日时分，时间不在今年内显示纬度月日年时分
export const customizeRelativeTime2 = (time: number | Dayjs = dayjs()) => {
  const day = dayjs(time);
  if (day.year() === dayjs().year()) {
    return day.format('MM/DD HH:mm');
  }
  return day.format('MM/DD/YYYY HH:mm');
}