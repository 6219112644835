import React, { memo } from 'react'

import cn from 'classnames';

import I18n from './i18n'
import Icon from './media/UpAndDown.svg'
import { MoreProps } from './type'

import styles from './more.module.less'

const T:React.FC<MoreProps> = ({ onClick = () => {}, isExpand, className }) => {
  return (
        <div className={cn(styles.more, className)} onClick={onClick}>
            <span>{isExpand ? I18n.t('Hide') : I18n.t('More')}</span>
            <Icon className={cn({
              [styles.down]: isExpand
            })}/>
        </div>
  )
}
export const More = memo(T)
