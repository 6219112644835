import React from 'react';

import cn from 'classnames';

import type { CloseProps } from './type';

import styles from './modal.module.less';
export const Close: React.FC<CloseProps> = ({ className, onClick }) => {
  return (<span onClick={onClick} className={cn(className, styles.close)} />)
}
