import { config, mapToQueryString, request } from '@utils/index';

import { IProfileDynamicReq, IProfileDynamicRes } from './dynamic.type';
/**
 * 个人主页动态
 */
export const getProfileDynamic = (params: IProfileDynamicReq): Promise<APIResponse<IProfileDynamicRes>> => {
  const URL = `${config.api.social_host}${config.api.feed.getDynamic}?${mapToQueryString(params ?? {})}`;
  return request.get.call({}, URL);
};
