import React from 'react';

import { openModal } from 'base-components';

import { FollowersModal } from './followers-modal'

import styles from './OpenFollowersModal.module.less';

export const openFollowersModal = async ({
  id,
  isMySelf,
  activeKey,
  followerCount,
  followingCount,
  requestFollowerApi,
  requestFollowingApi,
  useFollowUser,
  getProfileLink,
  wrapper
}) => {
  const res = await openModal({
    mask: true,
    maskClosable: false,
    closable: true,
    className: styles.modal,
    cardClass: styles.cardClass,
    closeClass: styles.closeClass,
    headerClass: styles.headerClass,
    el: wrapper,
    children: (params) => <FollowersModal
      {...params}
      id={id}
      isMySelf={isMySelf}
      activeKey={activeKey}
      followerCount={followerCount}
      followingCount={followingCount}
      requestFollowerApi={requestFollowerApi}
      requestFollowingApi={requestFollowingApi}
      useFollowUser={useFollowUser}
      getProfileLink={getProfileLink}
    />
  });
  return res;
};
