
import { config, request } from '@utils/index';

import type { IDeleteReactionReq, IFetchReactionReq } from './reaction.type';
import { getClient } from './stream';

export const fetchReaction = (params: IFetchReactionReq) => {
  const URL = `${config.api.social_host}${config.api.reaction.addReaction}`;
  return request.post.call({}, URL, undefined, {}, params);
};

export const deleteReaction = (params: IDeleteReactionReq) => {
  const URL = `${config.api.social_host}${config.api.reaction.deleteReaction.replace(/{type}/, params.type).replace(/{id}/, params.id).replace(/{kind}/, params.kind)}`;
  return request.delete.call({}, URL, undefined, {}, params);
}
export const addLike = ({ targetViewId, userId, target }) => {
  return getClient()?.reactions.add('LIKE', targetViewId, { target }, { userId })
}

export const deleteLike = ({ targetViewId }) => {
  return getClient()?.reactions.delete(`activity_id/${targetViewId}/LIKE`);
}
