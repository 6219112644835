import React from 'react';

import { More } from 'atom-components';
import { Avatar, Button, CopyBtn, If, RichEditor } from 'base-components';
import cn from 'classnames';
import { useClient } from 'hooks';
import { AddressIcon, Cemera, CopyIcon } from 'icons';
import { formatThousandthsNum } from 'utils';

import { FOLLOW_TAB_KEY } from '@';

import { CountsRow } from '../counts-row';
import { HeaderBg } from '../headerBg';
import I18n from './i18n';
import { RenderBtn } from './render-btn';
import { PersonalInfoHeaderProps } from './type';

import styles from './personalHomeHeader.module.less';

export const PersonalInfoHeader: React.FC<
  PersonalInfoHeaderProps & { bgImg: string }
> = (props) => {
  const {
    bgImg, avatar, desc, likes, initing,
    followerCount, followingCount, isFollow, following,
    uniqueName, nickName, address, handleEditProfile,
    handleFollow, onClickFollowers, onClickAvatar, onClickBg,
    isMySelf
  } = props;
  const countsMap = [{
    label: I18n.t('likes'),
    num: formatThousandthsNum(likes || 0)
  }, {
    label: I18n.t('followers'),
    num: formatThousandthsNum(followerCount),
    cursorPointer: true,
    onClick: () => { onClickFollowers?.(FOLLOW_TAB_KEY.FOLLOWERS) }
  }, {
    label: I18n.t('following'),
    num: formatThousandthsNum(followingCount),
    cursorPointer: true,
    onClick: () => { onClickFollowers?.(FOLLOW_TAB_KEY.FOLLOWING) }
  }]
  const isPc = useClient(576);

  return (
    <HeaderBg
      bg={bgImg}
      className={cn({ [styles.editState]: isMySelf })}
      bgNode={
        <Button className={cn(styles.action)} size="small">{I18n.t('uploadCover')}</Button>
      }
      onBgClick={isMySelf ? onClickBg : undefined}
    >
      <div className={styles.main}>
        <div className={styles.avatarBox}>
          <div
            className={cn(styles.avatar, {
              [styles.editState]: isMySelf
            })}
            onClick={() => {
              if (!isMySelf) return;
              onClickAvatar?.(avatar)
            }}
          >
            <Avatar avatarSize={isPc ? 120 : 100} avatar={avatar} />
            <div className={styles.editBg}>
              <Cemera className={styles.camera} />
            </div>
          </div>
        </div>
        <div className={styles.nameRow}>
          <div>
            <span className={cn(styles.nickName, 'openSansBold')}>
              {nickName}
            </span>
            <span className={styles.uniqueName}>{uniqueName}</span>
          </div>
          <If
            condition={(isMySelf === undefined) || initing}
            true={null}
            false={<RenderBtn
              isMySelf={isMySelf}
              isFollow={isFollow}
              loading={following}
              handleEditProfile={handleEditProfile}
              handleFollow={handleFollow}
            />}
          />
        </div>
        <div
          className={cn(styles.addressBox, {
            [styles.hide]: !isMySelf
          })}
        >
          <AddressIcon />
          <span className={styles.address}>
            {address &&
              `${address.slice(0, 4).toLocaleUpperCase()}…${address
                .slice(-6)
                .toLocaleUpperCase()}`}
          </span>
          <CopyBtn
            text={address || ''}
            customerIcon={
              <span className={styles.copyIconWrapper}>
                <CopyIcon />
              </span>
            }
          />
        </div>
        <div className={styles.descRow}>
          {desc && (
            <RichEditor
              defaultValue={desc}
              readOnly
              maxLength={78}
              more={<More isExpand={false} />}
              hide={<More isExpand={true} />}
            />
          )}
        </div>
        <CountsRow countsMap={countsMap} />
      </div>
    </HeaderBg>
  );
};
