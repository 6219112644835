import React, { useImperativeHandle, useRef } from 'react';

import { RichEditor } from 'base-components';

import I18n from '../../utils/i18n'
import { FocusEleObj } from '../constants'
import type { DescriptionProps } from './type'

import styles from './index.module.less'

const Description = React.forwardRef((props: DescriptionProps, ref) => {
  const {
    maxDescLength, alioss, setFocusElement,
    setCurrentDescLength, setDesc,
    defaultEditorValue, scrollingContainer
  } = props;
  const editorRef = useRef<any>();
  const onChange = (value) => {
    setCurrentDescLength(value.textLength)
    setDesc(value.latestValue)
  }
  const on = (state) => {
    if (state.key === 'isFocus') {
      setFocusElement(state.value ? FocusEleObj.DESC : '')
    }
  }

  const getLatestData = async () => {
    await editorRef?.current?.uploadImage()
    const latestData = editorRef?.current?.getLatestValue?.()
    return latestData
  }

  useImperativeHandle(ref, () => {
    return {
      getLatestData,
      editorRef
    };
  }, []);

  return (
    <div className={styles.descContainer}>
      <RichEditor
        toolbarId='questionEditorToolbar'
        placeholder={I18n.t('description_pleaceholder')}
        ref={editorRef}
        alioss={alioss}
        defaultValue={defaultEditorValue}
        maxLength={maxDescLength}
        onChange={onChange}
        scrollingContainer={scrollingContainer}
        H5Width={576}
        on={on}
      />
    </div>
  )
})
Description.displayName = 'Description';

export const QuestionDescription = Description
