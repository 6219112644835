import React from 'react';

import cn from 'classnames'
import { FireIcon } from 'icons';

import I18n from './i18n';
import { HotValueProps } from './type';

import styles from './hotValue.module.less';

export const HotValue: React.FC<HotValueProps> = (props) => {
  const { likeCount } = props;
  return (
    <div className={styles.container}>
      <span className={styles.fireIcon}>
        <FireIcon />
      </span>
      <span className={cn(styles.hotValue, 'SFProTextSemiboldItalic')}>
        <span className="SFProTextSemiboldItalic">{likeCount}</span>&nbsp;
        {I18n.t('hotValueText')}
      </span>
    </div>
  );
};
