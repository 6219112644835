import React from 'react';

import { openModal } from 'base-components';

import { AnswerModal } from '../answer-modal'

import styles from './index.module.less';

export const onOpenAnswerModal = async ({
  customerId,
  questionId,
  avatar,
  nickName,
  communityIcon,
  communityName,
  title,
  alioss,
  isCache,
  onPost
}) => {
  const refData = {
    current: {
      dbData: {
        _rev: ''
      },
      title: '',
      description: '',
      descLength: ''
    }
  };

  const onClose = async () => {
    if (!isCache) {
      return
    }
    try {
      const { addData, updateBulletin } = await import('../utils');
      const dbData = refData?.current?.dbData
      const newData = {
        id: customerId,
        questionId,
        description: refData?.current?.description,
        descLength: refData?.current?.descLength
      }

      if (dbData?._rev) {
        updateBulletin({
          ...dbData,
          ...newData
        })
        return;
      }
      addData(newData)
    } catch (e) {
      console.log('ANSWER--DB--ADD--ERROR::', e)
    }
  }
  const res = await openModal({
    mask: true,
    maskClosable: false,
    closable: true,
    className: styles.modal,
    cardClass: styles.answerModalCard,
    closeClass: styles.answerClose,
    headerClass: styles.answerHeaderClass,
    onClose,
    children: (params) => (
      <AnswerModal
        questionId={questionId}
        avatar={avatar}
        nickName={nickName}
        communityIcon={communityIcon}
        communityName={communityName}
        title={title}
        alioss={alioss}
        refData={refData}
        onPost={onPost}
        {...params}
      />
    )
  });
  return res
}
