import React from 'react';

import cn from 'classnames';

// import { Title } from 'atom-components'
import type { TitleProps } from './type'

import styles from './index.module.less'
export const AnswerTitle: React.FC<TitleProps> = (props) => {
  const { title, className, titleRef } = props;

  return (
    <div className={cn(styles.titleContainer, className)} ref={titleRef}>
      <h4 className={styles.title}>{title}</h4>
    </div>
  )
}
