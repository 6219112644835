import React, { useContext, useRef } from 'react';

import { useIsomorphicLayoutEffect } from 'hooks';

import { TRexContext } from '@hooks/index';

import web3Auth from './web3Auth';

export const withLogin = (WrappedComponent) => {
  const WithLoginCom = (props) => {
    const { dispatch } = useContext(TRexContext);
    const web3AuthRef = useRef<any>({});
    useIsomorphicLayoutEffect(() => {
      web3AuthRef.current = web3Auth.getInstance(dispatch);
    }, []);
    return <WrappedComponent {...props} web3Auth={web3AuthRef} />
  }
  return WithLoginCom;
}
