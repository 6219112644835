export enum MODE {
  /** 弹框 */
  POPUP = 'popup',

  /** 重定向 */
  REDIRECT = 'redirect',
}

export enum AUTH_TYPE {
  TWITTER = 'Twitter',
  FACEBOOK = 'Facebook',
  GOOGLE = 'Google',
}
