import { Upload as AntUpload } from 'antd';
import { Button } from 'base-components';
import { useClient } from 'hooks';

import I18n from './i18n'

import styles from './editAvatar.module.less'

export const Upload = ({ onChange }) => {
  const isPc = useClient(576)
  const beforeUpload = () => {
    return false;
  };
  const handleChange = (info) => onChange(info.file)
  return (
    <div className={styles.upload}>
        <AntUpload
            name="avatar"
            accept=".jpg,.jpeg,.png,.webp,.gif"
            className={'image-avatar-uploader'}
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
        >
          {
            isPc ? (
            <Button type='text' className={styles.textBtn}>
              {I18n.t('uploadImg')}
            </Button>
            ) : (
              <Button type='primary' className={styles.uploadBtn} colorType='black'>
                {I18n.t('uploadImg')}
              </Button>
            )
          }
        </AntUpload>
    </div>
  )
}
