import React, { useState } from 'react';

import cn from 'classnames';

import { Loading3QuartersOutlined } from '@ant-design/icons';

import { InfiniteScroll, Spin, SPIN_STATUS } from '../';
import { ScrollLoadingProps } from './';
import I18n from './i18n';

import styles from './index.module.less';

export const ScrollLoading: React.FC<ScrollLoadingProps> = (props) => {
  const [loading, setLoading] = useState(SPIN_STATUS.SUCCESS);
  const {
    invisibleHeight,
    fetchData,
    className,
    renderLoading,
    renderError,
    renderEmpty,
    status,
    loadingClassName,
    spinClassName,
    renderNoMore,
    noMoreDesc,
    noMoreShow,
    children
  } = props;

  const loadData = async (unObserver) => {
    try {
      setLoading(SPIN_STATUS.LOADING);
      await fetchData(unObserver);
      setLoading(SPIN_STATUS.SUCCESS);
      return Promise.resolve();
    } catch (error) {
      setLoading(SPIN_STATUS.SUCCESS);
      return Promise.reject(error);
    }
  };

  const isHidden =
    (status && status === SPIN_STATUS.SUCCESS) ||
    (!status && loading === SPIN_STATUS.SUCCESS);
  const isTipShow = status && status === SPIN_STATUS.SUCCESS && noMoreShow;
  return (
    <>
      <Spin
        className={cn(styles.spin, {
          [styles.hidden]: status !== 'REFRESH'
        })}
        loadingClassName={cn(styles.loading, loadingClassName)}
        status={SPIN_STATUS.LOADING}
        renderLoading={
          renderLoading || (
            <Loading3QuartersOutlined
              className={cn(
                'anticon',
                'anticon-loading',
                'anticon-spin',
                styles.loadingIcon
              )}
            />
          )
        }
      />
      <InfiniteScroll
        invisibleHeight={invisibleHeight}
        fetchData={loadData}
        className={className}
      >
        {children}
      </InfiniteScroll>
      {status !== 'REFRESH' && <Spin
        className={cn(styles.spin, spinClassName, {
          [styles.hidden]: isHidden
        })}
        loadingClassName={cn(styles.loading, loadingClassName)}
        status={status || loading}
        renderError={renderError}
        renderEmpty={renderEmpty}
        renderLoading={
          renderLoading || (
            <Loading3QuartersOutlined
              className={cn(
                'anticon',
                'anticon-loading',
                'anticon-spin',
                styles.loadingIcon
              )}
            />
          )
        }
      />}
      {isTipShow && (
        <>
          {!renderNoMore ? (
            <div className={styles.noMore}>
              {noMoreDesc || I18n.t('noMoreNormal')}
            </div>
          ) : (
            <>{renderNoMore}</>
          )}
        </>
      )}
    </>
  );
};
