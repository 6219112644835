
import { config, request } from '@utils/index';

/** 创建问题 */
export const postQuestion = (params) => {
  const URL = `${config.api.social_host}${config.api.collection.postQuestion.replace(/{category}/, params.category)}`;
  return request.post.call({}, URL, undefined, {}, params);
};
/** 回答问题 */
export const postAnswer = (params) => {
  const URL = `${config.api.social_host}${config.api.feed.postAnswer}`;
  return request.post.call({}, URL, undefined, {}, params);
};
