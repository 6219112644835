import oss from 'ali-oss';
import type { IAliyunOSS, AsyncUploadOpt } from './type';

class AliyunOSS implements IAliyunOSS {
  public getOssConfig: IAliyunOSS['getOssConfig'];
  /** 上传成功后拼接的域名 */
  public domain: IAliyunOSS['domain'];
  private client: any;
  private uploadProgressListener: Function;
  constructor({ getOssConfig, domain }) {
    this.getOssConfig = getOssConfig;
    this.client = null;
    this.domain = domain;
  }
  async init() {
    const { accessKey, accessSecret, securityToken, endpoint, resource } = await this.getOssConfig();
    if (!(accessKey || accessSecret || securityToken || endpoint)) {
      return Promise.reject(new Error('fetch osskey error'))
    }
    const [region] = endpoint.split('.');
    this.client = oss({
      region: region,
      accessKeyId: accessKey,
      accessKeySecret: accessSecret,
      bucket: resource,
      stsToken: securityToken,
      endpoint: endpoint
    })
    return Promise.resolve();
  }
  async asyncUpload(name: string, localFile: File, opt: AsyncUploadOpt) {
    opt = opt || {}
    if (opt && opt.progress) {
      opt.progress = this.uploadProgressListener
      opt.withCredentials = true
    }
    return this.client.multipartUpload(name, localFile, opt)
  }
  addEventListener(name: string, handler: Function) {
    if (name === 'uploadProgress') {
      this.uploadProgressListener = handler
    }
  }
}

export default AliyunOSS;