import React from 'react';

import { AvatarText, If } from 'base-components';
import cn from 'classnames';

import I18n from '../../utils/i18n'
import type { AuthorProps } from './type'

import styles from './index.module.less'

export const Author: React.FC<AuthorProps> = (props) => {
  const { avatar, nickName, communityIcon, communityName, titleInTop, title } = props
  return (
    <div className={styles.authorContainer}>
      <If
        condition={titleInTop}
        true={<div className={cn(styles.title, 'openSansSemiBold')}>{title}</div>}
        false={<AvatarText
          avatar={communityIcon || avatar}
          avatarSize={20}
          nickName={<div className={styles.nickName}>{communityName || nickName}{I18n.t('who_question')}</div>}
          className={styles.author}
          borderRadius={communityIcon ? 4 : 20}
        />}
      />
    </div>
  )
}
