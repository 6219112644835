// HOME
export const HOME = '/home';
export const HOME_PATHS = {
  /** 首页 */
  INDEX: `${HOME}`
};

// 排行榜
export const TOP = '/top';
export const TOP_PATHS = {
  /** 首页 */
  INDEX: `${TOP}`
};

// 回答列表
export const ANSWER = '/answer';
export const ANSWER_PATHS = {
  /** 首页 */
  INDEX: `${ANSWER}`
};

// 个人中心
export const PROFILE = '/profile';
export const PROFILE_PATHS = {
  /** 首页 */
  INDEX: `${PROFILE}`
};
// 积分
export const ACHIEVE = '/achieve';

// 问题详情
export const QUESTION = '/question';
export const QUESTION_PATHS = {
  QUESTION_DETAIL: `${QUESTION}/[id]`
}

/** 板块详情 */
export const COMMUNITY = '/community';
export const COMMUNITY_PATHS = {
  COMMUNITY_DETAIL: `${COMMUNITY}/[id]`
}
