import { useRouter } from 'next/router';

import { QUESTION_PATHS } from '@constants/index';
interface JumpDetailParams {
  answerId?: string;
  answerUserId?: string;
  questionUserId: string;
  questionId: string;
  [key: string]: any;
}
export const useQuestionDetail = () => {
  const router = useRouter();
  const jumpDetail = (item: JumpDetailParams) => {
    const { questionId = '', answerId = '', answerUserId = '', questionUserId = '', replyUserId = '' } = item;
    router.push({
      pathname: QUESTION_PATHS.QUESTION_DETAIL.replace('[id]', questionId),
      query: { answerId, answerUserId, questionUserId, replyUserId }
    });
  }
  return {
    jumpDetail
  }
};
