import React from 'react';

import { Input as AntdInput } from 'antd';
import cn from 'classnames';

import I18n from './i18n';
import type { ITextAreaProps } from './type';

import styles from './index.module.less';

const AntdTextArea = AntdInput.TextArea;
export const TextArea: React.FC<ITextAreaProps> = (props) => {
  const {
    placeholder,
    className,
    textAreaRef,
    autoSize = true,
    bordered = true,
    showUnderlineDynamic = true,
    ...rest
  } = props;
  return (
    <div className={styles.textArea}>
      <AntdTextArea
        {...rest}
        bordered={bordered}
        ref={textAreaRef}
        autoSize={autoSize}
        className={cn(className, {
          underlineDynamicDisplay: showUnderlineDynamic && bordered
        })}
        placeholder={placeholder || I18n.t('0nVGdRkQ')}
      />
    </div>
  );
};
