import { AliOSS } from 'utils'

import { getOssRequest } from '@api/index'
import { config } from '@utils/index';

const getOssConfig = async () => {
  try {
    const response = await getOssRequest()
    return Promise.resolve({
      accessKey: response.obj.accessKey ?? '',
      accessSecret: response.obj.accessSecret ?? '',
      securityToken: response.obj.securityToken ?? '',
      resource: response.obj.resource ?? '',
      endpoint: response.obj.endpoint ?? ''
    });
  } catch (e) {
    console.log('getOssConfig--Error::', e)
  }
}

export const alioss = new AliOSS({ getOssConfig: getOssConfig, domain: config.api.upload_api })
