import React, { useState } from 'react';

import { Menu } from 'antd';
import cn from 'classnames';

import { SiderProps } from './type';

import styles from './index.module.less'

const Sider: React.FC<SiderProps> = (props) => {
  const { initOpenKey, rootSubmenuKeys, className } = props
  const [openKeys, setOpenKeys] = useState([initOpenKey])

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys?.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Menu
      mode="vertical"
      className={cn(styles.menu, className)}
      openKeys={openKeys as string[]}
      onOpenChange={onOpenChange}
      {
      ...props
      }
    />
  );
};

export default Sider;
