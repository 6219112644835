import React, { useRef } from 'react';

import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';

import { SPIN_STATUS } from '../'
import { InfiniteScrollProps } from './type';

import styles from './index.module.less';

export const InfiniteScroll: React.FC<InfiniteScrollProps> = (props) => {
  const { className, children, fetchData, invisibleHeight } = props;
  const observerRef = useRef<any>(null);
  const statusRef = useRef<any>(null); // fetchData 的状态，loading 态中 不调用 fetchData
  const intersectionObserverRef = useRef<any>(null);

  const unObserver = () => {
    intersectionObserverRef.current?.unobserve?.(observerRef.current);
  };

  const callback = async ([target]) => {
    if (statusRef.current === SPIN_STATUS.LOADING || target.intersectionRatio <= 0) {
      return;
    }
    try {
      statusRef.current = SPIN_STATUS.LOADING;
      await fetchData?.(unObserver);
    } catch (error) { }
    statusRef.current = 'end';
  };

  useIsomorphicLayoutEffect(() => {
    intersectionObserverRef.current = new IntersectionObserver(callback, {
      threshold: 0.0
    });
    intersectionObserverRef.current.observe(observerRef.current);
  }, []);

  return (
    <div className={cn(className, styles.parent)}>
      <div
        style={{ height: `${invisibleHeight}px` }}
        className={styles.observer}
        ref={observerRef}
      />
      {children}
    </div>
  );
};
