import Theme from 'styles/theme/theme';

import { color } from './color';
const themesArr = {
  default: color,
  dark: {
    colorSuccessText: 'red'
  }
}

Theme.load(themesArr);
export default Theme;
