import React from 'react';

import returnElement from '../element';
import { type IfProps } from '../types';

// @ts-ignore
const If: React.FC<IfProps> = ({ condition, ...rest }) => {
  if (condition) {
    return returnElement(rest.true);
  }
  return returnElement(rest.false);
}

export default If;
