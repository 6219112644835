import React from 'react';

function returnElement (element: React.ReactNode): React.ReactNode {
  if (React.isValidElement(element)) {
    return element;
  }
  return null;
}

export default returnElement;
