import cookie from 'js-cookie';
import chunk from 'lodash/chunk';
import flatten from 'lodash/flatten';
import md5 from 'md5';
import { createUUID } from 'utils';

import { onOpenH5LinkModal } from '../h5-link-modal'
import { createFormatImage, createFormatLink, createUiIcon } from '../register';
import { Info, PromiseSettledResult } from '../type';
import { dataURLtoBlob } from './helper';

export const createUploadPath = (fileName: string, width: number, height: number) => {
  const strArr = fileName.split('.');
  const ext = strArr[strArr.length - 1];
  const userId = cookie.get('userId')

  return `${userId}/longtext/${createUUID()}__w:${width}__h${height}.${ext}`
}

export const batchUpload = async (imageFiles: Info[], alioss): Promise<Info[]> => {
  await alioss.init();
  const resourceBlobDataBatch: Info[][] = chunk(imageFiles, 10);
  let index = 0;
  const resList: PromiseSettledResult<Info>[][] = [];
  while (index < resourceBlobDataBatch.length) {
    const source = resourceBlobDataBatch[index];
    console.log(source, 'imgdataimgdata')
    const upLoadPromise = source.map((imgdata): Promise<Info> => {
      return new Promise<Info>((resolve) => {
        alioss.asyncUpload(imgdata.uploadFilePath, imgdata.image).then(result => {
          const url = `${alioss.domain}${imgdata.uploadFilePath}`;
          console.log(url, 'urlurlurlurl')
          // @ts-ignore
          const image = document.createElement('img');
          image.src = url;
          resolve({
            ...imgdata,
            image: url
          })
        })
      })
    })
    const res = await Promise.allSettled(upLoadPromise)
    resList.push(res);
    index++
  }
  return flatten(resList).filter(item => item.status === 'fulfilled').map(item => item.value)
}

export const getImageFiles = (contents, cache = {}) => {
  const infos: Info[] = [];
  contents.ops.forEach((op, index) => {
    if (op.insert?.image && cache[md5(op.insert?.image)]) {
      // @ts-ignore
      const image = document.createElement('img');
      image.src = op.insert?.image;
      const fileName = cache[md5(op.insert?.image)].name;
      console.log(op.insert?.image, 'asdasdasd')
      const info = {
        index,
        image: dataURLtoBlob(op.insert?.image),
        width: image.width,
        height: image.height,
        fileName: fileName,
        uploadFilePath: createUploadPath(fileName, image.width, image.height)
      }
      infos.push(info)
    }
  })
  return infos;
}

const createQuill = (): Promise<any> => {
  return new Promise((resolve) => {
    // @ts-ignore
    if (window.Quill) return resolve(window.Quill);
    const script = document.createElement('script');
    script.src = '/quill.min.js';
    document.body.append(script);
    script.onload = (e) => {
      // @ts-ignore
      resolve(window.Quill)
    }
  })
}

export const getQuill = async (): Promise<any> => {
  const Quill = await createQuill();
  createFormatImage(Quill);
  createFormatLink(Quill);
  createUiIcon(Quill);
  // createFormatList(Quill);
  return Quill;
}

export const initSetEditorValue = (text: string, editor, cancheRef) => {
  const domparser = new DOMParser();
  const doc = domparser.parseFromString(text, 'text/html');
  const dataCache = doc.body.children[0]?.getAttribute?.('data-cache');
  const dataValue = doc.body.children[0]?.getAttribute?.('data-value');
  if (!dataValue) {
    editor.clipboard.dangerouslyPasteHTML(text, 'api');
    return;
  }
  if (dataCache) {
    try {
      const cache = JSON.parse(dataCache);
      cancheRef.current = cache;
    } catch (error) {
    }
  }
  if (dataValue) {
    try {
      // @ts-ignore
      const contents = JSON.parse(dataValue.replace(/\n/g, '\\n').replace(/\r/, /\\r/));
      editor.setContents(contents, 'api');
      const text = editor.getText()
      editor.setSelection(text?.length - 1, 'silent')
    } catch (error) {
      editor.clipboard.dangerouslyPasteHTML(text, 'api');
    }
  }
}

export const editorLinkEdit = (editor, H5Width) => {
  const edit = editor.theme.tooltip.edit;
  /** 这里特意选用function定义函数，为了拿到编辑器this */
  editor.theme.tooltip.edit = function (mode = 'link', preview: any = {}) {
    const isPc = document.body.clientWidth > H5Width;
    if (!isPc) {
      onOpenH5LinkModal({
        h5LinkRootRef: this.quill
      }).then(res => {
        this.save(res);
      })
      return;
    }
    edit.call(this, mode, preview);
    this.textbox.value = preview.href || '';
    this.namebox.value = preview.name;
    this.namebox.setAttribute('placeholder', this.namebox.getAttribute(`data-${mode}`) || '');
  }
}

export const editorPosition = (editor) => {
  editor.theme.tooltip.position = function (reference) {
    const left = reference.left + reference.width / 2 - this.root.offsetWidth / 2;
    const top = reference.bottom + this.quill.root.scrollTop;
    this.root.style.left = left + 'px';
    this.root.style.top = top + 'px';
    this.root.classList.remove('ql-flip');
    const containerBounds = this.boundsContainer.getBoundingClientRect();
    const rootBounds = this.root.getBoundingClientRect();
    let shift = 0;
    if (rootBounds.right > containerBounds.right) {
      shift = containerBounds.right - rootBounds.right;
      this.root.style.left = (left + shift) + 'px';
    }
    if (rootBounds.left < containerBounds.left) {
      shift = containerBounds.left - rootBounds.left;
      this.root.style.left = (left + shift) + 'px';
    }
    if (rootBounds.bottom > containerBounds.bottom) {
      const height = rootBounds.bottom - rootBounds.top;
      const verticalShift = reference.bottom - reference.top + height;
      let newTop = top - verticalShift;
      if (newTop < 0) {
        newTop = reference.bottom + reference.height
      }
      this.root.style.top = newTop + 'px';
      this.root.classList.add('ql-flip');
    }
    return shift;
  }
}

export async function handleLink (value?: string) {
  const range = this.quill.getSelection();
  const name = this.quill.getText(range);
  const tooltip = this.quill.theme.tooltip;
  tooltip.edit('link', { name });
}
