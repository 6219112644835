import { useRef, useState } from 'react';

import { message, SPIN_STATUS } from '@'

const useGetScrollList = ({
  requestApi, queryParams
}) => {
  const [rows, setRows] = useState([]);
  const [status, setStatus] = useState<SPIN_STATUS>(SPIN_STATUS.SUCCESS);
  const [total, setTotal] = useState<number>(0)
  const nextToken = useRef<string | undefined>();
  const rowsRef = useRef([]);

  const fetch = async (fetchQuery = {}, init = false) => {
    setStatus(SPIN_STATUS.LOADING);
    if (init) {
      setRows([]);
      setTotal(0);
      nextToken.current = '';
      rowsRef.current = [];
    }
    try {
      const dataRes = await requestApi({
        ...queryParams,
        ...fetchQuery,
        next_token: nextToken.current
      });
      if (dataRes.success) {
        const { obj } = dataRes;
        setTotal(obj?.total || 0);
        let newRows = obj?.rows || [];
        if (nextToken.current) {
          newRows = rowsRef.current.concat(newRows);
        }
        rowsRef.current = newRows;
        setRows(rowsRef.current);
        nextToken.current = obj.next_token || obj.nextToken;
      }
    } catch {
      message.error('system.error')
    }
    setStatus(SPIN_STATUS.SUCCESS);
  };

  return {
    status,
    total,
    nextToken,
    fetch,
    rows
  }
};

export { useGetScrollList };
