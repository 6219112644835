import { useContext } from 'react';

import { message } from 'base-components';
import { pubsub } from 'utils';
import { onOpenAnswerModal } from 'widgets';

import { postAnswer } from '@api/index'
import { QUESTION_STATUS } from '@constants/index';
import { type IWeb3Auth, TRexContext } from '@hooks/index';
import { getCommunityInfo } from '@utils/answer/helper'
import { alioss, I18n } from '@utils/index';

const useAddAnswer = (props?: { web3Auth?: IWeb3Auth }) => {
  const { web3Auth } = props || {};
  const { state } = useContext(TRexContext);
  const { isLogin, userInfo } = state;
  const onPostAnswer = async (answerDetail) => {
    try {
      const response = postAnswer({
        category: 'ANSWER',
        actor: userInfo.id,
        collection_id: answerDetail.questionId,
        text: answerDetail.desc
      })
      return response
    } catch (e) {
      console.log('postQuestion--Error::', e)
    }
  }

  const onClickAnswerFun = async (answerDetail) => {
    if (!isLogin) {
      console.log('登录');
      web3Auth?.current?.handleLogin?.();
      return;
    }
    const {
      view_id: id = '',
      title, status,
      user: { nick_name: nickName = '', avatar = '' } = {}
    } = answerDetail;
    if (status === QUESTION_STATUS.STOP) {
      message.info(I18n.t('stopQuestionDesc'));
      return
    }
    const { communityIcon, communityName } = getCommunityInfo(answerDetail?.community)

    const res: any = await onOpenAnswerModal({
      customerId: userInfo.id,
      questionId: id,
      avatar,
      nickName,
      communityIcon,
      communityName,
      title,
      alioss,
      isCache: true,
      onPost: onPostAnswer
    })
    console.log('res.answerDetails', res.answerDetails, id);
    if (res.submitSuccess) {
      pubsub.publish('ADD_ANSWER', {
        questionId: id,
        answerDetail: res.answerDetails
      })
    }
  };

  return {
    onClickAnswerFun
  }
};

export { useAddAnswer };
