export enum QUESTION_STATUS {
  NORMAL = 'NORMAL',
  STOP = 'STOP'
};

export enum QUESTION_SLUG {
  /** 推荐feed */
  RECOMMEND = 'aggregated_question_recommend',
  /** 关注feed 关注板块 */
  RELATION_COMMUNITY= 'aggregated_community_question_relation',
  /** 关注feed 关注个人 */
  RELATION_USER = 'aggregated_question_user_relation',
  /** 板块详情下的问题列表 */
  COMMUNITY = 'aggregated_community_question',
    /** 板块详情下的问题列表 */
  COMMUNITY_ANSWER = 'aggregated_community_answer',
  /** 个人主页提问流 */
  USER = 'aggregated_user_question',
  /** 排行榜 */
  RANKING = 'aggregated_question_ranking',
  /** 等你来答广场 */
  WAIT_ANSWER = 'aggregated_question_wait_answer'
}
