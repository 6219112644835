import React, { FC } from 'react';

import cn from 'classnames';
import { BackIcon } from 'icons';
import { useRouter } from 'next/router';

import type { backProps } from './type';

import styles from './index.module.less';

export const GoBack: FC<backProps> = ({ text, className }) => {
  const router = useRouter();
  const goBack = () => {
    router.back();
  }
  return (
    <div className={cn(styles.container, className)} >
      <a onClick={goBack}>
        <span className={styles.iconWrapper}><BackIcon className={styles.arrowIcon} /></span> {text}
      </a>
    </div>
  );
};
