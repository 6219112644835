import { useCallback } from 'react';

import { useIsomorphicLayoutEffect } from 'hooks';
import throttle from 'lodash/throttle';

import { UseCeillingParams } from './type';

import styles from './index.module.less';
export const useCeiling = ({ scrollEl, mainRef }: UseCeillingParams) => {
  const onScroll = useCallback((e) => {
    // @ts-ignore
    const mainEl = getComputedStyle(mainRef.current);
    const mainHeight = parseInt(mainEl.height);
    const scrollTop = e.target.scrollTop;
    console.log('line15', scrollEl);
    // @ts-ignore
    const classList = Array.from(mainRef.current.classList);
    if (scrollTop > mainHeight && !classList.includes(styles.ceiling)) {
      // @ts-ignore
      mainRef.current.classList.add(styles.ceiling)
    }
    if (scrollTop < mainHeight && classList.includes(styles.ceiling)) {
      // @ts-ignore
      mainRef.current.classList.remove(styles.ceiling)
    }
  }, [])
  useIsomorphicLayoutEffect(() => {
    // @ts-ignore
    // const el = document.querySelector(scrollEl);
    scrollEl?.current?.addEventListener('scroll', throttle(onScroll, 200))
  }, [scrollEl?.current])
}
