import React from 'react';

import { Button } from 'base-components';
import { useClient } from 'hooks';

import I18n from '../../utils/i18n'
import type { PostButtonProps } from './type'

import styles from './index.module.less'

export const PostButton: React.FC<PostButtonProps> = ({ disabled, onClickPost }) => {
  const isPc = useClient(576);

  return (
    <div className={styles.btnContainer}>
      <Button
        type='primary'
        className={styles.button}
        disabled={disabled}
        onClick={onClickPost}
        customizeSize={!isPc ? 'xsmall' : 'default'}
      >
        {I18n.t('answer_post')}
      </Button>
    </div>
  )
}
