import React, { useImperativeHandle, useRef } from 'react';

import { TextArea } from 'base-components';
import { useIsomorphicLayoutEffect } from 'hooks';

import I18n from '../../utils/i18n'
import { FocusEleObj } from '../constants'
import type { TitleProps } from './type'

import styles from './index.module.less'

const Title = React.forwardRef((props: TitleProps, ref) => {
  const textAreaRef = useRef<any>(null);
  const { maxTitleLength, setFocusElement, setTitle, title, titleRef } = props;
  const onFocus = () => {
    setFocusElement(FocusEleObj.TITLE);
  }
  const onBlur = () => {
    setFocusElement('');
  }
  const onChange = (e) => {
    setTitle(e.target.value)
  }
  useIsomorphicLayoutEffect(() => {
    textAreaRef?.current!.focus({
      cursor: 'start'
    })
  }, [])

  useImperativeHandle(ref, () => ({
    blur: () => {
      textAreaRef?.current!.blur?.()
    }
  }));
  return (
    <div className={styles.titleContainer} ref={titleRef}>
      <TextArea
        value={title}
        placeholder={I18n.t('title_pleaceholder')}
        bordered={false}
        maxLength={maxTitleLength}
        textAreaRef={textAreaRef}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  )
})
Title.displayName = 'Description';

export const QuestionTitle = Title
