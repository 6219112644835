import React, { Fragment } from 'react';
import reactDom from 'react-dom';

import { handleMaskScroll } from 'utils/index';

import type { IOpenBaseModalParams, ModalBaseProps } from './type';

import styles from './modal.module.less';

const Modal: React.FC<ModalBaseProps> = (props) => {
  const { reject, resolve, removePreview, children } = props;
  return (
    <Fragment>
      {children({ reject, resolve, removePreview })}
    </Fragment>
  )
}

export const openBaseModal = ({ el: wrapper, children }: IOpenBaseModalParams) => {
  let el: HTMLElement|undefined = document.createElement('div');
  wrapper = wrapper ?? document.body;
  wrapper.append(el);
  document.body.classList.add(styles.bodyOverflow);
  handleMaskScroll(true);
  const removePreview = () => {
    if (!el) return
    (wrapper as HTMLElement).removeChild(el)
    el = undefined;
    document.body.classList.remove(styles.bodyOverflow)
    handleMaskScroll(false)
  }
  return new Promise((resolve, reject) => {
    reactDom.render(<Modal resolve={resolve} reject={reject} removePreview={removePreview}>{children}</Modal>, el as HTMLElement)
  })
}
