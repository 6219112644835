import React, { Fragment } from 'react';

import returnElement from '../element';
import { ElementNames, Elements } from '../types';

function returnElements (
  rest: Elements | {},
  elementsNames: ElementNames
): React.ReactNode {
  if (Object.keys(rest).length > 0 && elementsNames.length > 0) {
    return (
      <Fragment>{elementsNames.map((e) => returnElement(rest[e]))}</Fragment>
    );
  }
  return null;
}

export default returnElements;
