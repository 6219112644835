import React from 'react';

import cn from 'classnames';

import { Input } from '@/input';

import type { SearchProps } from '../select.type';

import styles from '../index.module.less';

export const Search: React.FC<SearchProps> = ({ search, onSearch, onChange, className }) => {
  if (!search) {
    return null
  }
  const { placeholder, className: searchClass, isClear } = search;
  return (
    <div className={cn(styles.searchContainer, className, searchClass)}>
      <Input
        prefix={<span className={cn('icon', 'icon-search')} />}
        placeholder={placeholder}
        className={cn(styles.selectSearchInput)}
        allowClear={isClear}
        onChange={e => {
          onChange?.(e.target.value)
        }}
        onPressEnter={e => {
          // @ts-ignore
          onSearch?.(e.target.value)
        }}
      />
    </div>
  )
}
