import React from 'react';

import cn from 'classnames';

import { CountsRowProps } from './index';

import styles from './CountsRow.module.less';

export const CountsRow: React.FC<CountsRowProps> = (props) => {
  const { countsMap = [] } = props;
  return (
    <div className={styles.countsBox}>
      {countsMap.map((item, index) => {
        const { label, num, cursorPointer = false, onClick } = item;
        return (
          <div
            className={cn(styles.countsItem, { [styles.cursorPointer]: cursorPointer })}
            key={`${label}${index}`}
            onClick={() => { onClick?.() }}
          >
            <span className={cn(styles.num, 'openSansSemiBold')}>
              {num}
            </span>
            &nbsp;{label}
          </div>
        )
      })}
    </div>
  );
};
