import React from 'react';

import { Button } from 'antd';
import cn from 'classnames';

import Arrow from './medium/arrow.svg'
import { IPaginationProps } from './type';

import styles from './index.module.less';

export const Pagination: React.FC<IPaginationProps> = (props) => {
  const { className, preDisable = false, nextDisable = false, clickPre, clickNext } = props;
  console.log('line13preDisable', preDisable, nextDisable);
  return (
    <div className={cn(styles.pagination, className)}>
      <Button className={styles.prevIconContainer} disabled={preDisable} onClick={clickPre} type='text'>
        <Arrow />
      </Button>
      <Button className={styles.nextIconContainer} disabled={nextDisable} onClick={clickNext} type='text'>
        <Arrow className={styles.nextIcon}/>
      </Button>
    </div>

  )
}
