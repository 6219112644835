export const getImagesByRichText = (html?: string) => {
  if (!html) return [];
  try {
    const domparser = new DOMParser();
    const doc = domparser.parseFromString(html, 'text/html');
    const imgs = doc.body.querySelectorAll('img');
    return Array.from(imgs).map(img => img.getAttribute('src'))
  } catch (error) {
    return []
  }
}

/** 是否有多个段落 */
const isParagraph = (doc: Document) => {
  const p = doc.querySelectorAll('p');
  return p.length > 1;
}

/** 是否有加粗 */
const isBold = (doc: Document) => {
  const b = doc.querySelectorAll('b');
  const strong = doc.querySelectorAll('strong');
  return !!b.length || !!strong.length;
}

/** 是否有斜体 */
const isItalics = (doc: Document) => {
  const i = doc.querySelectorAll('i');
  const em = doc.querySelectorAll('em');
  return !!i.length || !!em.length;
}

/** 是否有列表 */
const isList = (doc: Document) => {
  const li = doc.querySelectorAll('li');
  return !!li.length;
}

/** 是否有超链接 */
const isLink = (doc: Document) => {
  const a = doc.querySelectorAll('a');
  return !!a.length;
}

/** 是否有图片 */
const isImage = (doc: Document) => {
  const img = doc.querySelectorAll('img');
  return !!img.length;
}
/**
 * 根据富文本(html)返回纯文本 文字内容 和 是否包含其他格式
 * @param html html文本可选
 * @returns
 */
export const getTextByRichText = (html?: string): {text?: string;specialFormat?: boolean} => {
  if (!html) return {};
  try {
    const domparser = new DOMParser();
    const doc = domparser.parseFromString(html, 'text/html');
    const textContent = doc.body.textContent;
    const rules = [isParagraph, isBold, isItalics, isList, isLink, isImage];
    let specialFormat = false;
    let i = 0;
    while (i < rules.length && !specialFormat) {
      specialFormat = rules[i](doc);
      if (!specialFormat) i++;
    }
    return { text: textContent as string, specialFormat };
  } catch (error) {
    return {}
  }
}

/** 根据 html 内容 和 maxLength 来判断在 maxLength 限定内，是否还存在没有显示完整的内容 */
export const isIncomplete = (html: string, maxLength: number) => {
  const { specialFormat, text = '' } = getTextByRichText(html);
  return specialFormat || text.length > maxLength
}
