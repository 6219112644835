import { useContext } from 'react';

import { useRouter } from 'next/router';
import { pubsub } from 'utils';
import { onOpenPostSuccessModal, onOpenQuestionModal } from 'widgets';

import { getCommunityList, IUserInfo, postAnswer } from '@api/index'
import { ACHIEVE_REFRESH, COMMUNITY_ORDER, QUESTION } from '@constants/index';
import { TRexContext } from '@hooks/index';
import { alioss } from '@utils/index'
const useAddQuestion = () => {
  const router = useRouter();
  const {
    state: {
      userInfo = {} as IUserInfo
    }
  } = useContext(TRexContext);
  const onSuccess = ({
    questionId,
    questionImages,
    avatar
  }) => {
    const onClickView = () => {
      router.push({
        pathname: `${QUESTION}/${questionId}`
      })
    }
    const res = onOpenPostSuccessModal({
      onClickView,
      questionImages,
      avatar,
      persistTime: 5000
    })
    pubsub.publish(ACHIEVE_REFRESH)
    console.log('res:line26', res)
  }

  const onPostQuestion = (questionDetail) => {
    try {
      const response = postAnswer({
        category: 'QUESTION',
        text: questionDetail.desc,
        title: questionDetail.title,
        actor: userInfo.id,
        community_id: questionDetail.communityId
      })
      return response
    } catch (e) {
      console.log('postQuestion--Error::', e)
    }
  }

  const onClickPostFun = async () => {
    const res: any = await onOpenQuestionModal({
      customerId: userInfo.id,
      avatar: userInfo.avatar,
      nickName: userInfo.nickName,
      userName: userInfo.uniqueName,
      alioss,
      isCache: true,
      communityListRequestApi: getCommunityList,
      communityListQueryParams: {
        ranking: COMMUNITY_ORDER.MODIFIED,
        limit: 20
      },
      onPost: onPostQuestion
    })

    if (res.submitSuccess && !!res.questionId) {
      onSuccess({
        questionId: res.questionId,
        questionImages: res.images,
        avatar: userInfo.avatar
      })
    }
  };

  return {
    onClickPostFun
  }
};

export { useAddQuestion };
