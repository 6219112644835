import { message } from 'base-components';
import cookie from 'js-cookie';
import { pubsub } from 'utils';

import web3Auth from '@hooks/login/web3Auth';
import I18n from '@utils/i18n';

export const noLoginCodeEum = {
  kweb: '1000',
  ksocial: 9998
}

export const initData = () => {
  cookie?.remove?.('jwt');
  cookie?.remove?.('userId');
  pubsub.publish('LOGOUT');
}

export const handleNoLogin = () => {
  message.error(I18n.t('noLoginTips'));
  initData();
  const web3AuthCur = web3Auth.getInstance(() => {});
  if (web3AuthCur.web3AuthInstance?.provider) {
    web3AuthCur.web3AuthInstance?.logout();
  }
  setTimeout(() => {
    location.reload();
  }, 2000);
}
