import React from 'react';

import returnElement from '../element';
import returnElements from '../elements';
import { SimplifyProps } from '../types';

// @ts-ignore
const Simplify: React.FC<SimplifyProps> = ({
  conditions,
  multiple = false,
  ...rest
}) => {
  if (typeof conditions === 'string') {
    const stringProp = conditions.trim();
    return returnElement(rest[stringProp]);
  }

  if (typeof conditions === 'object') {
    if (multiple) {
      const stringsProp = Object.keys(conditions).filter(
        (k) => !!conditions[k]
      );
      return returnElements(rest, stringsProp);
    } else {
      const stringProp = Object.keys(conditions).find((k) => !!conditions[k]);
      if (stringProp) {
        return returnElement(rest[stringProp]);
      }
    }
  }

  return null;
}

export default Simplify;
