import { COMMUNITY, COMMUNITY_STATUS, PROFILE } from '@constants/index';

export const getAnswerAndPlateMsg = (user, community) => {
  const { nick_name: name = '', avatar = '' } = user
  const { relations = [], icon, title: communityTitle, status } = community
  const isJoin = relations.some(i => i === 'JOIN')
  const isFocus = isJoin && status !== COMMUNITY_STATUS.STOP
  const avatarUrl = isFocus ? icon : avatar
  const nameText = isFocus ? communityTitle : name
  const linkTo = isFocus ? `${COMMUNITY}/${community.id}` : `${PROFILE}?id=${user.id}`
  return {
    avatar: avatarUrl,
    name: nameText,
    nickName: name,
    linkTo,
    isFocus
  }
}

export const getCommunityInfo = (community) => {
  const { relations = [], icon, title: communityTitle, status } = community || {}
  const isJoin = relations.some(i => i === 'JOIN')
  const showCommunity = isJoin && status !== COMMUNITY_STATUS.STOP
  const communityIcon = showCommunity ? icon : '';
  const communityName = showCommunity ? communityTitle : ''
  return {
    communityIcon,
    communityName
  }
}
