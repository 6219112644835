/** 行为类型 */
export enum REACTION_TYPE {
  REPLY = 'REPLY',
  COMMENT = 'COMMENT',
  LIKE = 'LIKE'
};

/** 排序类型 */
export enum RANKING_TYPE {
  TIME = 'TIME',
};

/** 目标类型 */
export enum TARGET_TYPE {
  ACTIVITY = 'ACTIVITY',
  REACTION = 'REACTION'
};
