export const createFormatImage = (Quill) => {
  const customImage = Quill.import('formats/custom-image');
  if (customImage) return;
  const BlockEmbed = Quill.import('blots/block/embed');
  class ImageBlot extends BlockEmbed {
    static create (value) {
      const node = super.create();
      node.setAttribute('contenteditable', 'false')
      const img = document.createElement('img');
      const close = document.createElement('span');
      close.classList.add('ql-close')
      img.setAttribute('alt', value.alt);
      img.setAttribute('src', value.url);
      if (typeof value === 'string') {
        img.setAttribute('src', value);
      }
      node.addEventListener('click', (e) => {
        e.stopPropagation();
      })
      node.append(img);
      node.append(close);
      close.addEventListener('mousedown', () => {
        node.remove()
      })
      return node;
    }

    static value (node) {
      const img = node.querySelector('img');
      return img.getAttribute('src');
    }
  }
  ImageBlot.blotName = 'image';
  ImageBlot.tagName = 'DIV';
  ImageBlot.className = 'image-wrapper';
  Quill.register({
    'formats/custom-image': ImageBlot
  })
}
