import React from 'react';

import { type InputProps, Input as AntdInput } from 'antd';
import cn from 'classnames';

import I18n from './i18n';

import styles from './index.module.less';

export const Input: React.FC<InputProps & { showUnderlineDynamic?: boolean, inputRef?: any }> = (props) => {
  const { placeholder, className, inputRef, showUnderlineDynamic = true, bordered = true, ...otherProps } = props;
  return (
    <div className={styles.input}>
      <AntdInput
        {...otherProps}
        ref={inputRef}
        bordered={bordered}
        className={cn(className, {
          underlineDynamicDisplay: showUnderlineDynamic && bordered
        })}
        placeholder={placeholder || I18n.t('0nVGdRkQ')}
      />
    </div>
  );
};
