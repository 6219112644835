import React, { FC } from 'react';

import cn from 'classnames';

import { TitleProps } from './type';

import styles from './title.module.less';

export const Title: FC<TitleProps> = (props) => {
  const { children, fontSize, color, isBold = true, className } = props;
  return (
    <div
      className={cn(styles.title, 'openSansSemiBold', className, {
        [styles.bold]: isBold
      })}
      style={{
        color,
        fontSize
      }}
    >
      {children}
    </div>
  );
};
