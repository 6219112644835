import React from 'react';

import cn from 'classnames';
import { copyText } from 'utils/clipboard'

import { message } from '@';

import DefaultIcon from './defaultIcon.svg';
import I18n from './i18n';
import type { CopyBtnProps } from './type';

import styles from './index.module.less';

export const CopyBtn: React.FC<CopyBtnProps> = (props) => {
  const { className, text, customerIcon, ...rest } = props;

  return (
    <span
      {...rest}
      className={cn(styles.copyIcon, className)}
      onClick={() => {
        copyText(text)
        message.success(I18n.t('copySuccess'))
      }}
    >
      {customerIcon || <DefaultIcon />}
    </span>

  )
}
