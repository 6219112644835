import React, { useState } from 'react';

import cn from 'classnames';

import { openBaseModal } from './baseModal';
import { Close } from './close';
import type { IOpenModalParams, ModalProps } from './type'

import styles from './modal.module.less';

const Modal: React.FC<ModalProps> = (props) => {
  const {
    className,
    cardClass,
    headerClass,
    closeIcon,
    mask,
    closePosition,
    maskClosable,
    closable,
    closeClass,
    onClose,
    title,
    maskClass,
    children,
    direction = 'bottom',
    ...rest
  } = props;
  const [isStart, setIsStart] = useState(true);
  const { removePreview } = rest;
  const onBeforeClose = async () => {
    const resAny = await onClose?.();
    if ((typeof resAny === 'boolean' && resAny) || typeof resAny === 'undefined') {
      close();
    }
  }
  const close = () => {
    setIsStart(false);
    setTimeout(() => {
      removePreview()
    }, 316);
  }
  return (
    <div className={cn(styles.containerModal, className)}>
      {mask && <div className={cn(maskClass, styles.mask, {
        [styles.mountMask]: isStart,
        [styles.destroyMask]: !isStart
      })} onClick={maskClosable ? onBeforeClose : undefined} />}
      <div className={cn(cardClass, styles.container, {
        [styles.destroyCard]: !isStart,
        [styles.cardBottom]: direction === 'bottom' && isStart,
        [styles.cardLeft]: direction === 'left' && isStart,
        [styles.cardRight]: direction === 'right' && isStart
      })}>
        <header className={cn(headerClass, styles.header, {
          [styles.headerLeft]: closePosition === 'left'
        })}>
          <div>{(!!title && closePosition !== 'left') && title}</div>
          {closable && (closeIcon ?? <Close className={closeClass} onClick={onBeforeClose} />)}
        </header>
        {children({ ...rest, close })}
      </div>
    </div>
  )
}

export const openModal = ({ el, children, className, ...rest }: IOpenModalParams) => {
  return openBaseModal({
    el,
    children: (params) => <Modal className={className} {...rest} {...params}>{children}</Modal>
  })
}
