import { baseColor } from 'styles/color';

export const color = {
  colorPrimary: baseColor.cyan6,
  colorPrimaryActive: baseColor.cyan8,
  colorPrimaryHover: baseColor.cyan5,
  colorPrimaryBg: baseColor.color13,
  colorPrimaryText: baseColor.cyan7,
  colorPrimaryTextHover: baseColor.cyan5,
  colorPrimaryTextActive: baseColor.cyan8,
  colorSuccess: baseColor.cyan6,
  // colorSuccessHover: baseColor.color6,
  // colorSuccessBg: baseColor.color7,
  // colorSuccessText: baseColor.color5,
  // colorSuccessTextHover: baseColor.color6,
  colorWarning: baseColor.warningColor,
  // colorWarningHover: baseColor.color9,
  // colorWarningBg: baseColor.color10,
  // colorWarningBgHover: baseColor.color9,
  // colorWarningBorder: baseColor.color8,
  // colorWarningBorderHover: baseColor.color9,
  // colorWarningTex: baseColor.color8,
  // colorWarningTextHover: baseColor.color9,
  colorError: baseColor.errorColor,
  // colorErrorHover: baseColor.color12,
  // colorErrorBorder: baseColor.color11,
  // colorErrorBorderHover: baseColor.color11,
  // colorErrorText: baseColor.color11,
  // colorErrorTextHover: baseColor.color12,
  colorSplit: baseColor.grey10,
  colorTextDescription: baseColor.grey5,
  colorTextDisabled: baseColor.grey9,
  colorTextHeading: baseColor.grey1,
  colorTextLabel: baseColor.grey1,
  colorTextPlaceholder: baseColor.grey7,
  colorSecondaryBtn: baseColor.grey10,
  colorSecondaryBtnActive: baseColor.color30,
  colorSecondaryBtnHover: baseColor.color31,
  colorBgMask: baseColor.color32,
  colorIconBg1Hover: baseColor.grey10,
  colorIconBg2Hover: baseColor.grey9,
  navBarPopoverContent: baseColor.grey10,
  popoverContentSplit: baseColor.grey8,
  navBarIconColor: baseColor.cyan6,
  menuIconNormal: baseColor.color38,
  menuIconHover: baseColor.cyan6,
  modalwebAuthBg: baseColor.grey10,
  modalwebAuthFooterBg: baseColor.color40,
  commentBorderColor: baseColor.grey9,
  modalBackground: baseColor.grey10,
  bactTopBgc: baseColor.grey9,
  bactTopBgcHover: baseColor.grey10,
  iconActive: baseColor.grey3,
  feedRichEditorTextColor: baseColor.grey3,
  feedRichEditorHoverTextColor: baseColor.grey4,
  iconHover: baseColor.grey9,
  iconDisabled: baseColor.grey8,
  achieveTitleColor: baseColor.grey5,
  achieveValueColor: baseColor.grey1,
  taskTitleColor: baseColor.grey1,
  taskItemPointColor: baseColor.cyan6,
  taskItemTitleColor: baseColor.grey1,
  taskItemDesColor: baseColor.grey5,
  taskItemTimeColor: baseColor.grey5,
  progressBackground: baseColor.grey9,
  progressColor: baseColor.cyan6,
  tableItemTitle: baseColor.grey1,
  tableItemTime: baseColor.grey5,
  tableItemPoints: baseColor.grey1,
  tipColor: baseColor.grey7,
  moreColor: baseColor.cyan11,
  answerPageTextHover: baseColor.grey2
}
