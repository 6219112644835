import React from 'react';

import { openModal } from 'base-components';

import I18n from '@/add-question/utils/i18n'

import { CommunityList } from '../community-list'

import styles from './index.module.less';

export const onOpenCommunityModal = async ({
  setCommunity,
  communityListRequestApi,
  communityListQueryParams

}) => {
  const onClose = async () => {
  }
  const res = await openModal({
    mask: true,
    maskClosable: true,
    closable: true,
    className: styles.modal,
    cardClass: styles.modalCard,
    title: <div className={styles.modalTitle}>{I18n.t('add_community')}</div>,
    closeClass: styles.close,
    // headerClass: styles.headerClass,
    onClose,
    children: (params) => (
      <CommunityList
        {...params}
        setCommunity={setCommunity}
        communityListRequestApi={communityListRequestApi}
        communityListQueryParams={communityListQueryParams}
      />
    )
  });
  return res
}
