import React from 'react';

import { Tabs } from 'antd';
import cn from 'classnames';

import type { ITabsProps } from './type';

import styles from './secondeTabs.module.less';

export const SecondeTabs: React.FC<ITabsProps> = (props) => {
  const { className, ...restProps } = props;
  return (
    <Tabs {...restProps} type="card" className={cn(styles.secTabs, className)} />
  );
};
