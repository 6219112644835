import React, { Fragment } from 'react';

import cn from 'classnames';

import { FeedMultipleImage } from '@/feed-multiple-image';

import type { SummaryProps } from './type';

import styles from './summary.module.less';

export const Summary: React.FC<SummaryProps> = (props) => {
  const { text, className, more, onMore, isImages, images, moreClass, feedMultipleImageClass } = props;
  return (
    <Fragment>
      <div className={cn(className, styles.summary)}>
        <span className={styles.text}>{text}</span>
        <div onClick={onMore} className={cn(styles.moreBtn, moreClass)}>{more}</div>
      </div>
      {isImages && images.length > 0 && <FeedMultipleImage
        className={cn(feedMultipleImageClass, styles.feedMultipleImage)}
        imgGroup={images}
      />}
    </Fragment>
  )
}
