import { useEffect, useLayoutEffect } from 'react'

/**
 * 修复控制台：Warning: useLayoutEffect does nothing on the server, because its effect cannot be encoded into the server renderer's output format. This will lead to a mismatch between the initial, non-hydrated UI and the intended UI. To avoid this, useLayoutEffect should only be used in components that render exclusively on the client. See https://reactjs.org/link/uselayouteffect-ssr for common fixes.
 * https://usehooks-ts.com/react-hook/use-isomorphic-layout-effect
 */

export const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

// export default useIsomorphicLayoutEffect
