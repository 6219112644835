import React from 'react';

import { AvatarText, If } from 'base-components';
import cn from 'classnames';

import type { AuthorProps } from './type'

import styles from './index.module.less'

export const Author: React.FC<AuthorProps> = (props) => {
  const { avatar, nickName, userName, titleInTop, title } = props

  return (
    <div className={cn(styles.authorContainer)}>
      <If
        condition={titleInTop}
        true={<div className={cn(styles.title, 'openSansSemiBold')}>{title}</div>}
        false={<AvatarText
          avatar={avatar}
          avatarSize={48}
          nickName={<div className={cn(styles.nickName, 'openSansSemiBold')}>{nickName}</div>}
          userName={<div className={styles.userName}>{userName}</div>}
          className={styles.author}
        />}
      />
    </div>
  )
}
