import React from 'react';

import cn from 'classnames';

import { openModal } from '@';

import I18n from '../i18n'
import { H5LinkModal } from './H5LinkModal'

import styles from './H5LinkModal.module.less';

export const onOpenH5LinkModal = async ({ h5LinkRootRef }) => {
  const res = await openModal({
    el: h5LinkRootRef.current,
    mask: true,
    maskClosable: false,
    closable: true,
    className: styles.modal,
    cardClass: styles.modalCard,
    closeClass: styles.close,
    headerClass: styles.headerClass,
    title: <h4 className={cn(styles.modalTitle, 'openSansSemiBold')}>{I18n.t('addLink')}</h4>,
    children: (params) => (
      <H5LinkModal
        {...params}
      />
    )
  });
  return res
}
