import { ANSWER_PATHS, COMMUNITY_PATHS, HOME_PATHS, QUESTION_PATHS, TOP_PATHS } from '@constants/index';

// const zhCN = 'zh-CN';
// const zhTC = 'zh-TC';
const en = 'en';

const HEADER = {
  [HOME_PATHS.INDEX]: {
    [en]: [
      <title key="1">home | en</title>,
      <meta
        property="og:title"
        key="title"
        name="description"
        content="home | en"
      />,
      <meta
        property="og:title"
        key="title"
        name="keywords"
        content="home | en"
      />
    ]
  },
  [TOP_PATHS.INDEX]: {
    [en]: [
      <title key="1">top | en</title>,
      <meta
        property="og:title"
        key="title"
        name="description"
        content="top | en"
      />,
      <meta
        property="og:title"
        key="title"
        name="keywords"
        content="top | en"
      />
    ]
  },
  [ANSWER_PATHS.INDEX]: {
    [en]: [
      <title key="1">answer | en</title>,
      <meta
        property="og:title"
        key="title"
        name="description"
        content="answer | en"
      />,
      <meta
        property="og:title"
        key="title"
        name="keywords"
        content="answer | en"
      />
    ]
  },
  [QUESTION_PATHS.QUESTION_DETAIL]: {
    [en]: [
      <title key="1">question | en</title>,
      <meta
        property="og:title"
        key="title"
        name="description"
        content="question | en"
      />,
      <meta
        property="og:title"
        key="title"
        name="keywords"
        content="question | en"
      />
    ]
  },
  [COMMUNITY_PATHS.COMMUNITY_DETAIL]: {
    [en]: [
      <title key="1">community | en</title>,
      <meta
        property="og:title"
        key="title"
        name="description"
        content="community | en"
      />,
      <meta
        property="og:title"
        key="title"
        name="keywords"
        content="community | en"
      />
    ]
  }
};

export const getPageHeader = ({ pathname, locale }) => {
  return HEADER[pathname]?.[locale] || HEADER[HOME_PATHS.INDEX][locale];
};
