/**
 * 将UI给的像素值(切图上的px值)，转化为当前设备需要的数值
 * elemSize * (layoutWidthSize / designWidthSize )
 * @param n
 * @returns {number}
 */

export function p (n) {
  // const WIDTH = Dimensions.get('window').width;
  // return Math.round((n) * (PixelRatio.getPixelSizeForLayoutSize(WIDTH) / PixelRatio.get()) / 750);

  // console.log(Math.round((n) * (WIDTH / 750)))
  // console.log(Math.round((n) * (PixelRatio.getPixelSizeForLayoutSize(WIDTH) / PixelRatio.get()) / 750))

  let displayPX: any = Math.round(n * 0.5);

  displayPX = displayPX + 'px'

  return displayPX;
}

