import { useContext, useRef, useState } from 'react';

import { message, SPIN_STATUS } from 'base-components';
import { useIsomorphicLayoutEffect } from 'hooks';
import { useRouter } from 'next/router';

import { type IProfileDynamicItem, getProfileDynamic } from '@api/index';
import { TRexContext } from '@hooks/index';
import { type IProfileRouterQuery } from '@widget/index';

type DynamicFetchDataProps = {
  init?: boolean;
}
const LIMIT = 20;

const useDynamic = () => {
  const [dynamicList, setDynamicList] = useState<IProfileDynamicItem[]>([]);
  const [status, setStatus] = useState<SPIN_STATUS>(SPIN_STATUS.SUCCESS);
  const nextToken = useRef<string | undefined>();
  const rowsRef = useRef<IProfileDynamicItem[]>([]);

  const { state: { isLogin, userInfo } } = useContext(TRexContext);
  const router = useRouter();
  const { id } = router.query as IProfileRouterQuery;
  const userId = userInfo?.id || '';
  const isMySelf = !id || id === userId;

  const curUserIdRef = useRef<string>(userId);
  const fetchDynamicData = async (props?: DynamicFetchDataProps) => {
    setStatus(SPIN_STATUS.LOADING);
    const { init } = props || {};
    try {
      const dataRes = await getProfileDynamic({
        user_id: curUserIdRef.current,
        next_token: (nextToken.current && !init) ? encodeURIComponent(nextToken.current) : '',
        limit: LIMIT
      });
      if (dataRes.success) {
        const { obj } = dataRes;
        let newRows = obj?.rows || [];
        if (nextToken.current && !init) {
          newRows = rowsRef.current.concat(newRows);
        }
        rowsRef.current = newRows;
        setDynamicList(rowsRef.current);
        nextToken.current = obj.next_token;
      }
    } catch {
      message.error('system.error');
    }
    setStatus(SPIN_STATUS.SUCCESS);
  };

  useIsomorphicLayoutEffect(() => {
    if (!isLogin && !id) return;
    curUserIdRef.current = isMySelf ? userId : id;
    fetchDynamicData({ init: true });
  }, [id, isLogin, userInfo?.avatar, userInfo?.nickName, userInfo?.uniqueName]);

  const onScroll = () => {
    if (!nextToken.current) {
      return;
    }
    fetchDynamicData();
  };

  return {
    status,
    dynamicList,
    onScroll,
    nextToken
  }
};

export { useDynamic };
