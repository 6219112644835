import { useState } from 'react'

import cookie from 'js-cookie';
import { createUUID } from 'utils';

export const useEditAvatar = ({ defaultUrl, resolve, alioss, cropRef, close }) => {
  const [imageUrl, setImageUrl] = useState(defaultUrl);
  const [loading, setLoading] = useState(false);
  const [scale, setScale] = useState(1);

  const finishSelected = async () => {
    const handleSave = cropRef.current.handleSave
    const croppedImageUrl = await handleSave()
    setLoading(true)
    if (!croppedImageUrl) return
    const userId = cookie.get('userId')
    const imageFile = createUUID() + '.png'
    const filePath = userId + '/avatar/' + imageFile
    const res = await fetch(croppedImageUrl);
    const blob = await res.blob();
    const file = new File([blob], imageFile, { type: 'image/png' });
    try {
      await alioss.init();
      const url = `${alioss.domain}${filePath}`;
      await alioss.asyncUpload(filePath, file)
      resolve(url)
      close()
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  };

  const uploadChange = (imageUrl) => setImageUrl(imageUrl)

  const onProgress = (progress: number) => {
    const scale = progress * 4 + 1
    setScale(scale)
  }

  return {
    imageUrl,
    loading,
    scale,
    finishSelected,
    uploadChange,
    onProgress
  }
}
