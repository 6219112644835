import React from 'react';

import cn from 'classnames';

import { CoreEl } from '../CoreEl';
import { Summary } from '../Summary';
import { EditorRefCurrent, RichEditorProps } from './type';
import { useMain } from './useMain'

import styles from './main.module.less';
const Main = React.forwardRef<EditorRefCurrent, RichEditorProps>((props, ref) => {
  const {
    readOnly, className, summaryClass, editorClass, hideClass, isImages, more, hide, moreClass
  } = props;
  const {
    toolbarRef,
    editorDivRef,
    isHide,
    text = '',
    isRenderMore,
    images = [],
    onMoreClick
  } = useMain(props, ref)
  return (
    <div className={className}>
      <CoreEl
        toolbarRef={toolbarRef}
        editorDivRef={editorDivRef}
        className={cn(editorClass, {
          [styles.hidden]: isHide
        })}
        toolbarClass={''}
        readOnly={readOnly}
      />
      <Summary
        text={text || ''}
        images={images as string[]}
        isImages={isImages}
        more={isRenderMore ? more : undefined}
        className={cn(summaryClass, {
          [styles.hidden]: !isHide
        })}
        feedMultipleImageClass={cn({
          [styles.hidden]: !isHide
        })}
        onMore={(e) => {
          e.stopPropagation();
          onMoreClick(false);
        }}
        moreClass={moreClass}
      />
      <div
        className={cn(hideClass, {
          [styles.hidden]: isHide
        })}
        onClick={(e) => {
          e.stopPropagation();
          onMoreClick(true);
        }}
      >{hide}</div>
    </div>
  )
})

Main.displayName = 'Editor'

export const RichEditor = Main;
