import React from 'react';
import ReactDom from 'react-dom';

import { useIsomorphicLayoutEffect } from 'hooks'

import { ImageGroupPreview } from '@/image-group-preview';

const Preview = (props) => {
  const { removePreview, imgGroup, el, src } = props;
  useIsomorphicLayoutEffect(() => {
    el.querySelector('.ant-image')?.click()
    removePreview()
  }, [])
  return (
    <ImageGroupPreview
      width="1000"
      height="600"
      curSrc={src}
      currentIndex={imgGroup.findIndex(item => item === src)}
      previewImgGroup={imgGroup}
      imgStyle={{ borderRadius: 4 }}
      maskClassName='image_borderRadius_4px'
    />
  )
}

export const prevewImage = (props) => {
  const el = document.createElement('div');
  document.body.append(el)
  const removePreview = () => {
    document.body.removeChild(el)
  }
  ReactDom.render(<Preview removePreview={removePreview} el={el} {...props} />, el)
}
