/* eslint-disable comma-dangle */
import { useRef, useState } from 'react';

import { message, SPIN_STATUS } from 'base-components';
import { useIsomorphicLayoutEffect } from 'hooks';
import { isEmpty } from 'lodash';

import {
  type CommentItem,
  type GetCommentListReq,
  addComment,
  getCommentList,
  getReplyList,
} from '@api/index';
import { getUserInfo, handleNoLogin, I18n, noLoginCodeEum } from '@utils/index';

const useCommentData = (options: GetCommentListReq) => {
  const {
    isReply = false,
    answerId = '',
    limit,
    defaultLimit,
    defaultLoad = true,
    defaultCommentList = [],
    defaultHasMore,
    web3Auth
  } = options;

  const [commentData, setCommentData] = useState<CommentItem[]>(defaultCommentList);
  const [status, setStatus] = useState<SPIN_STATUS>(SPIN_STATUS.SUCCESS);
  const [nextToken, setNextToken] = useState<string | undefined>(defaultHasMore ? String(defaultHasMore) : '');
  const commentRef = useRef<CommentItem[]>(defaultCommentList);

  useIsomorphicLayoutEffect(() => {
    if (defaultLoad && defaultLimit) {
      loadData({ pageLimit: defaultLimit, loadMore: false });
    }
  }, []);

  const loadData = async ({ pageLimit, loadMore = true }: { loadMore?: boolean; pageLimit?: number }) => {
    setStatus(SPIN_STATUS.LOADING);
    const getFunc = isReply ? getReplyList : getCommentList;
    const params: { parentId: string, limit: number, nextToken?: string } = {
      parentId: answerId,
      limit: pageLimit || limit
    }
    if (loadMore) {
      params.nextToken = nextToken;
    }
    const dataRes = await getFunc(params);
    const { obj = {}, success, code } = dataRes || {};
    if (!success) {
      if (code === noLoginCodeEum.ksocial) {
        handleNoLogin();
      }
      return;
    }
    if (obj.rows) {
      const newRows = loadMore ? commentRef.current.concat(obj.rows) : obj.rows;
      commentRef.current = newRows;
      setCommentData(commentRef.current);
    }
    setStatus(SPIN_STATUS.SUCCESS);
    setNextToken(obj.next_token);
  };

  const saveComment = async (targetViewId: string, value: string, kind?: string, target?: CommentItem) => {
    const userInfo = getUserInfo();
    const isLogin = !isEmpty(userInfo);
    if (!isLogin) {
      web3Auth?.current?.handleLogin?.();
      return Promise.resolve(false);
    }
    const promise = addComment(targetViewId, value, kind);
    const res = await promise;
    // 列表为时间正序排列，当没有下一页的时候判断是否需要再列表尾部新增
    if (res?.success) {
      if (!nextToken) {
        commentRef.current.push({
          ...res.obj,
          target,
          user: {
            avatar: userInfo.avatar,
            nick_name: userInfo.nickName,
            id: res.obj?.user_id || ''
          }
        });
        setCommentData([...commentRef.current]);
      }
      message.success('success');
    } else if (res.code === noLoginCodeEum.ksocial) {
      handleNoLogin();
      // 问题被暂停1008，提示内容与点击操作区域不一致
    } else if (res.code === 1008) {
      message.error(I18n.t('operateStopDesc'));
    } else {
      message.error(I18n.t(res?.msg));
    }
    return promise;
  }

  const fetchMoreData = () => {
    if (!nextToken) {
      return;
    }
    loadData({});
  }

  return {
    fetchData: () => { loadData({ pageLimit: limit }) },
    refreshData: () => { loadData({ pageLimit: limit, loadMore: false }) },
    fetchMoreData,
    saveComment,
    status,
    commentData,
    nextToken
  };
};

export { useCommentData };
