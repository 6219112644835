
import React, { useState } from 'react';

import { useIsomorphicLayoutEffect } from 'hooks';
import { p } from 'utils/index';

import { ImageGroupPreview } from '@/image-group-preview';

import type { ThreeImageProps } from './type'
import useClient from './useClient';

import styles from './index.module.less'

// 展示两张及两张以上图片
export const ThreeImage: React.FC <ThreeImageProps> = ({ images }) => {
  const isPc = useClient(576);
  const [size, setSize] = useState({
    imgW: p(360),
    imgH: p(360),
    marginR: p(32)
  });
  useIsomorphicLayoutEffect(() => {
    const defaultSize = {
      imgW: p(360),
      imgH: p(360),
      marginR: p(32)
    };
    const length = images.length;
    const innerWidth = window.innerWidth;
    if (innerWidth && innerWidth < 576) {
      const marginR = 10;
      if (length === 2) {
        setSize({
          ...defaultSize,
          marginR: marginR,
          imgW: (innerWidth - 40) / 2,
          imgH: (innerWidth - 40) / 2
        })
      } else {
        setSize({
          ...defaultSize,
          marginR: marginR,
          imgW: (innerWidth - 50) / 3,
          imgH: (innerWidth - 50) / 3
        })
      }
    } else {
      setSize(defaultSize);
    }
  }, [isPc, images])
  return (
    <div className={styles.itemThreeContainer}>
      {images.map((imageItem, index) => {
        if (index > 2) {
          return null;
        }
        return (
          <div
            key={'images' + index}
            style={{
              marginRight:
                index === images.length - 1 || index === 2 ? 0 : size.marginR
            }}
          >
            <ImageGroupPreview
              width={size.imgW}
              height={size.imgH}
              curSrc={imageItem}
              currentIndex={index}
              previewImgGroup={images}
              imgStyle={{ borderRadius: p(8) }}
              maskClassName="image_borderRadius_4px"
            />
          </div>
        );
      })}
      {images.length > 3 ? (
        <div className={styles.imgCount}>
          <span className={styles.imgCountNum}>+{images.length - 3}</span>
        </div>
      ) : null}
    </div>
  );
};
