import React from 'react'
if (process.env.NODE_ENV === 'development' ) {
  if (typeof window !== 'undefined') {
    console.log('Applying whyDidYouRender, to help you locate unnecessary re-renders during development. See https://github.com/welldone-software/why-did-you-render');
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React, {
      trackAllPureComponents: true,
      trackHooks: true,
      logOwnerReasons: true,
      logOnDifferentValues: true,
      hotReloadBufferMs: 1500
    })
  }
}
