export enum RELATION_TYPE {
  JOIN = 'JOIN',
  UNKNOWN = 'UNKNOWN',
  FOLLOW = 'FOLLOW',
  SUBSCRIBE = 'SUBSCRIBE',
  BLOCK = 'BLOCK'
};

export enum QUESTION_TYPE {
  NORMAL = 0,
  STOP = 1,
  INNER=9
};
