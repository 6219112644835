import '../utils/firebase';
import 'wdyr/index';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/en-au';
import 'dayjs/locale/zh-hk';

import * as React from 'react';
import { useEffect } from 'react';
import { KeepAliveProvider } from 'react-next-keep-alive';

import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import { useClient, useIsomorphicLayoutEffect } from 'hooks';
// import dynamic from 'next/dynamic'
import Head from 'next/head';
import { useRouter } from 'next/router';

import { TRexContext, useGlobalData } from '@hooks/index';
import localFont from '@next/font/local';
import Theme from '@styles/theme/theme';
import { getPageHeader } from '@utils/index';

// import 'antd/dist/antd.css';
import 'antd/dist/reset.css';
import 'styles/common/app.less';
import '../styles/common/app.css';
import 'base-components/rich-editor/quill.snow.css';
// import Script from 'next/script'

const OpenSansBold = localFont({
  src: [
    {
      path: '../../public/fonts/OpenSans-Bold.ttf'
    }
  ]
});
const OpenSansLight = localFont({
  src: [
    {
      path: '../../public/fonts/OpenSans-Light.ttf'
    }
  ]
});
const OpenSansSemiBold = localFont({
  src: [
    {
      path: '../../public/fonts/OpenSans-SemiBold.ttf'
    }
  ]
});

const OpenSans = localFont({
  src: [
    {
      path: '../../public/fonts/OpenSans-Regular.ttf'
    }
  ]
});

const SFProTextHeavyItalic = localFont({
  src: [
    {
      path: '../../public/fonts/SF-Pro-Text-HeavyItalic.otf'
    }
  ]
});

const SFProTextSemiboldItalic = localFont({
  src: [
    {
      path: '../../public/fonts/SF-Pro-Text-SemiboldItalic.otf'
    }
  ]
});

export default function MyApp ({ Component, pageProps }) {
  const router = useRouter();
  const isPc = useClient(576);
  if (!router.locale || router.locale === 'en') {
    dayjs.locale('en');
  }
  const { state, dispatch } = useGlobalData();
  pageProps.locale = router.locale;
  pageProps.locales = router.locales;
  pageProps.route = router.route;
  const getLayout = Component.getLayout || ((page) => page);

  useIsomorphicLayoutEffect(() => {
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('--innerHeight', `${window.innerHeight}px`);
  }, [isPc]);
  useEffect(() => {
    Theme.update('default');
  }, [router.events]);

  return getLayout(
    <>
      <Head>
        {getPageHeader({ pathname: router.route, locale: router.locale })}
      </Head>
      <style jsx global>{`
        :root {
          --OpenSansBold: ${OpenSansBold.style.fontFamily};
          --OpenSansLight: ${OpenSansLight.style.fontFamily};
          --OpenSansSemiBold: ${OpenSansSemiBold.style.fontFamily};
          --OpenSans: ${OpenSans.style.fontFamily};
          --SFProTextHeavyItalic: ${SFProTextHeavyItalic.style.fontFamily};
          --SFProTextSemiboldItalic: ${SFProTextSemiboldItalic.style.fontFamily};
        }
      `}</style>
      <TRexContext.Provider
        value={{
          state,
          dispatch
        }}
      >
        <ConfigProvider autoInsertSpaceInButton={false}>
          <KeepAliveProvider router={router}>
            <Component {...pageProps} />
          </KeepAliveProvider>
        </ConfigProvider>
      </TRexContext.Provider>
    </>
  );
}
