import { message as AntMessage } from 'antd'

import ErrorSvg from './medium/error.svg'
import SuccessSvg from './medium/success.svg'
import WarningSvg from './medium/warning.svg'

import styles from './index.module.less'

const success = (content) => {
  AntMessage.success({
    content,
    className: styles.success,
    icon: <SuccessSvg className={styles.svg}/>
  })
}

const error = (content) => {
  AntMessage.error({
    content,
    className: styles.error,
    icon: <ErrorSvg className={styles.svg}/>
  })
}

const warning = (content) => {
  AntMessage.warning({
    content,
    className: styles.warning,
    icon: <WarningSvg className={styles.svg}/>
  })
}

const info = (content) => {
  AntMessage.info({
    content,
    className: styles.info,
    icon: <span />
  })
}

export const message = {
  success,
  error,
  warning,
  info
}
