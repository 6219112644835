import { useRef, useState } from 'react';

import { message, SPIN_STATUS } from 'base-components'

import { type GetCommendListReq, CommendItem, getCommendList } from '@api/index';

type ICommendListProps = Omit<GetCommendListReq,
  'ranking' | 'limit' |
  'ranking_offset' | 'enrich'
  | 'recentReactionsLimit' | 'id_lt'
  | 'reverse'>

const useCommendList = () => {
  const [commendList, setCommendList] = useState<CommendItem[]>([]);
  const [status, setStatus] = useState<SPIN_STATUS>(SPIN_STATUS.SUCCESS);
  const [total, setTotal] = useState<number>(0)
  const nextToken = useRef<string | undefined>();
  const rowsRef = useRef<CommendItem[]>([]);

  const fetchCommendData = async (props: ICommendListProps & { init?: boolean }) => {
    setStatus(SPIN_STATUS.LOADING);
    const { init, ...otherProps } = props;
    try {
      const dataRes = await getCommendList({
        ranking: 'ACTIVITY_TIME',
        limit: 20,
        ranking_offset: 0,
        enrich: true,
        recentReactionsLimit: 1,
        id_lt: (nextToken.current && !init) ? encodeURIComponent(nextToken.current) : '',
        reverse: true,
        ...otherProps
      });
      if (dataRes.success) {
        const { obj } = dataRes;
        setTotal(obj?.total || 0);
        let newRows = obj?.rows || [];
        if (nextToken.current && !init) {
          newRows = rowsRef.current.concat(newRows);
        }
        rowsRef.current = newRows;
        setCommendList(rowsRef.current);
        nextToken.current = obj.nextToken;
      }
    } catch {
      message.error('system.error')
    }
    setStatus(SPIN_STATUS.SUCCESS);
  };

  return {
    status,
    total,
    nextToken,
    fetchCommendData,
    commendList
  }
};

export { useCommendList };
