import React, { useState } from 'react';

import { Button } from 'base-components';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';
import { customizeRelativeTime, pubsub } from 'utils';

import I18n from './i18n';
import { AnswerNumProps } from './type';

import styles from './answerNum.module.less';

export const AnswerNum: React.FC<AnswerNumProps> = (props) => {
  const { created, activityCount = 0, questionId, handleClickAnswer, answerDesc } = props;
  const [answerCount, setAnswerCount] = useState(activityCount || 0);
  const isShowAnswer = activityCount !== null;
  const isHasBehindDesc = [created, answerDesc].some(item => !!item);
  const answerCountChange = (params) => {
    if (params.questionId === questionId) {
      setAnswerCount(answerCount + 1);
    }
  };

  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe('ADD_ANSWER', answerCountChange);
    return () => {
      pubsub.unSubscribe('ADD_ANSWER', answerCountChange);
    };
  }, [answerCount]);
  return (
    <div className={styles.container}>
      <div>
        {isShowAnswer && <span>
          <span className={cn(styles.answersNum, 'openSansSemiBold')}>
            {answerCount}
          </span>
          <span className="openSansSemiBold">{I18n.t('answerText')}{isHasBehindDesc ? ' · ' : ''}</span>
        </span>}
        {!!created && <span>{customizeRelativeTime(created)}</span>}
        {!!answerDesc && <span className={styles.answerDesc}>{answerDesc}</span>}
      </div>
      <Button
        type="primary"
        btnClassName={styles.answerBtn}
        onClick={handleClickAnswer}
      >
        {I18n.t('answerText')}
      </Button>
    </div>
  );
};
