import { BgColorsOutlined } from '@ant-design/icons';
import { baseColor } from '../color';
export default {
  modalDivNormal: baseColor.grey1,
  modalDivCardNormal: baseColor.grey10,
  modalSpanCloseHoverBg: baseColor.color21,
  modalMaskNormal: baseColor.color13,
  modalCloseHover: baseColor.grey9,
  inputCaretColor: baseColor.cyan6,
  inputPlaceholderColor: baseColor.grey7,
  inputColor: baseColor.grey1,
  inputBackgroundColor: baseColor.transparent,
  inputBorderColor: baseColor.grey9,
  inputErrorBorderColor: baseColor.errorColor,
  inputFocusBorderColor: baseColor.cyan6,
  inputFocusBackgroundColor: baseColor.transparent,
  inputAffixFocusBackgroundColor: baseColor.color21,
  inputDisabledBackgroundColor: baseColor.color24,
  inputFocusShadowColor: baseColor.color22,
  inputErrorFocusBackgroundColor: baseColor.transparent,
  buttonPrimaryBackgroundColor: baseColor.cyan6,
  buttonPrimaryFocusBackgroundColor: baseColor.cyan8,
  buttonPrimaryHoverBackgroundColor: baseColor.cyan3,
  buttonPrimaryColor: baseColor.color13,
  buttonPrimaryDisabledBackgroundColor: baseColor.cyan10,
  buttonPrimaryDisabledColor: baseColor.color29,
  buttonGhostBackgroundColor: baseColor.transparent,
  buttonGhostBorderColor: baseColor.grey9,
  buttonGhostColor: baseColor.cyan6,
  buttonGhostDisabledBorderColor: baseColor.color33,
  buttonGhostDisabledColor: baseColor.cyan10,
  buttonGhostHoverBorderColor: baseColor.color35,
  buttonGhostHoverColor: baseColor.cyan5,
  buttonGhostFocusBorderColor: baseColor.color36,
  buttonGhostFocusColor: baseColor.cyan8,
  buttonDefaultColor: baseColor.cyan6,
  buttonDefaultBackgroundColor: baseColor.grey9,
  buttonDefaultHoverColor: baseColor.cyan5,
  buttonDefaultHoverBackgroundColor: baseColor.color30,
  buttonDefaultFocusColor: baseColor.cyan8,
  buttonDefaultFocusBackgroundColor: baseColor.grey10,
  buttonDefaultDisabledColor: baseColor.cyan8,
  buttonDefaultDisabledBackgroundColor: baseColor.grey10,
  buttonLinkColor: baseColor.linkColor,
  buttonLinkHoverColor: baseColor.color45,
  buttonLinkFocusColor: baseColor.color46,
  buttonLinkDisabledColor: baseColor.color47,
  paginationIconBackgroundColor: baseColor.grey1,
  paginationIconBorderColor: baseColor.color18,
  paginationIconColor: baseColor.color27,
  paginationIconHoverBorderColor: baseColor.color18,
  paginationIconHoverBackgroundColor: baseColor.grey1,
  paginationIconHoverColor: baseColor.color28,
  selectLabelColor: baseColor.color16,
  selectValueColor: baseColor.color13,
  selectButtonBgColor: baseColor.color18,
  selectActiveColor: baseColor.color1,
  selectEmptyColor: baseColor.color17,
  bactTopBgc: baseColor.grey1,
  bactTopBgcHover: baseColor.grey1,
  bactTopIconColor: baseColor.color13,
  bactTopIconHoverColor: baseColor.color1,
  copyBtnColor: baseColor.color15,
  videoCloseIcon: baseColor.grey1,
  videoCloseIconHover: baseColor.color1,
  videoCloseBtn: baseColor.color19,
  videoPreviewMask: baseColor.color13,
  infiniteScrollBackgroundColor: baseColor.cyan6,
  scrollLoadingIconColor: baseColor.cyan6,
  goBackANormal: baseColor.grey1,
  goBackIconNormal: baseColor.grey1,
  threeDivBg: baseColor.color29,
  threeSpanNormal: baseColor.grey1,
  goBackIconHover: baseColor.grey10,
  avatarUserInfoColor: baseColor.grey1,
  titleTextColor: baseColor.grey1,
  tabsActiveColor: baseColor.cyan6,
  tabsTextColor: baseColor.grey5,
  tabsActiveTextColor: baseColor.grey1,
  separationColor: baseColor.color39,
  textCountColor: baseColor.grey5,
  userNameModalErrorMessageColor: baseColor.errorColor,
  userNameModalTitleColor: baseColor.grey1,
  userNameModalDescColor: baseColor.grey5,
  answerItemColor: baseColor.grey1,
  answerItemHoverBg: baseColor.grey11,
  answerItemBorderColor: baseColor.grey10,
  answerItemHoverColor: baseColor.cyan6,
  answerItemViewsColor: baseColor.grey5,
  answerNumColor: baseColor.grey5,
  editorCaretColor: baseColor.cyan6,
  editorTextColor: baseColor.grey1,
  editorPlaceholderColor: baseColor.grey7,
  editorLinkCardBg: baseColor.color37,
  editorLinkTextColor: baseColor.grey1,
  editorLinkSaveBg: baseColor.cyan6,
  editorLinkSaveHoverBg: baseColor.cyan5,
  editorLinkSaveColor: baseColor.grey10,
  editorBtnTextColor: baseColor.cyan6,
  editorLinkInputBg: baseColor.transparent,
  editorLinkBorderColor: baseColor.grey8,
  editorLinkColor: baseColor.linkColor,
  editorCloseBgNormal: baseColor.grey7,
  editorCloseHover: baseColor.grey10,
  editorCloseNormal: baseColor.grey10,
  editorLinkInTooltipColor: baseColor.grey1,
  editorIconHoverColor: baseColor.grey9,
  questionBaseInfoBorder: baseColor.grey10,
  questionBaseInfoBg: baseColor.color13,
  questionRankItemDetail: baseColor.grey3,
  questionItemRankTextColor: baseColor.grey5,
  questionItemHotValueColor: baseColor.grey5,
  answerModalUserNameColor: baseColor.grey7,
  questionRank1TextColor: baseColor.color41,
  questionRank2TextColor: baseColor.color42,
  questionRank3TextColor: baseColor.color43,
  questionModalUserNameColor: baseColor.grey7,
  questionModalNickNameColor: baseColor.grey1,
  answerModalNickNameColor: baseColor.grey7,
  loginModalDescColor: baseColor.grey7,
  feedItemHoverBg: baseColor.grey11,
  commentBtnHover: baseColor.color44,
  questionModalCommunityCard: baseColor.color48,
  questionModalCommunityCardHover: baseColor.color49,
  questionModalCommunityNameHover: baseColor.cyan6,
  questionModalDeleteBg: baseColor.grey7,
  questionModalDeleteIcon: baseColor.grey10,
  questionModalAddIcon: baseColor.cyan6,
  questionModalDescTextColor: baseColor.grey1,
  questionModalDescPlaceHolderColor: baseColor.grey5,
  iconHoverBackground: baseColor.color13,
  postSuccessModalBg: baseColor.color48,
  postSuccessModalText: baseColor.grey1,
  personalHomeHeaderColorMain: baseColor.grey1,
  personalHomeHeaderColorSecondary: baseColor.grey5,
  personalHomeHeaderAvatarBorderColor: baseColor.color13,
  personalHomeHeaderCountRowHoverColor: baseColor.cyan6,
  progressBar: baseColor.cyan6,
  messageErrorTextColor: baseColor.errorColor,
  messageWarningTextColor: baseColor.warningColor,
  messageBackground: baseColor.grey9,
  messageSuccesTextColor: baseColor.cyan6,
  messageInfoTextColor: baseColor.grey3,
  iconColor: baseColor.grey5,
  iconHover: baseColor.grey9,
  iconActive: baseColor.color31,
  iconDisabled: baseColor.color37,
  imgErrorTipColor: baseColor.grey8,
  blackButtomNormal: baseColor.cyan6,
  blackButtomNormalBackground: baseColor.grey9,
  blackButtomHoverNormalBackground: baseColor.color30,
  blackButtomActiveNormalColor: baseColor.grey10,
  blackButtomisabledTextColor: baseColor.cyan10,
  blackButtomActiveTextColor: baseColor.cyan8,
  blackButtomDisabledNormalBackground: baseColor.color50,
  blurMask: baseColor.color51,
  blurAvatarMask: baseColor.color52,
  communityAboutText: baseColor.grey3,
  followIconColor: baseColor.grey3,
  communityHoverBg: baseColor.grey12,
  profileCommunityHoverBg: baseColor.grey13,
  buttonTextNormal: baseColor.cyan6,
  buttonTextHover: baseColor.cyan5,
  buttonTextActive: baseColor.cyan8,
  buttonTextDisabled: baseColor.grey8,
  alinkText: baseColor.grey1,
  answerUsername: baseColor.grey5,
  topItemdesColor: baseColor.grey3,
  communityHoverTitle: baseColor.grey3,
  textButtomColor: baseColor.grey5,
  followerUserNameText: baseColor.grey5,
  followerUserIntroText: baseColor.grey5,
  colorSplit2: baseColor.grey9,
  communityHeaderSeparation: baseColor.grey5,
  tabsActiveBg: baseColor.grey10,
  moreColor: baseColor.cyan11,
  addLinkModalTitle: baseColor.grey1,
  imageBorder: baseColor.grey10,
  draProgressIcon: baseColor.grey5,
};
