import { type ForwardedRef, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { useIsomorphicLayoutEffect } from 'hooks'

import type { EditorRefCurrent as EditorRef } from '../CoreEl';
import { useEditor } from '../CoreEl';
import { addEllipsisToStr, getImagesByRichText, getTextByRichText } from '../utils';
import type { EditorRefCurrent, RichEditorProps } from './type';
// import { RenderMode } from './type';

export const useMain = (props: RichEditorProps, ref: ForwardedRef<EditorRefCurrent>) => {
  const {
    autofocus, toolbarId, onChange, placeholder,
    maxLength = Infinity, alioss, readOnly, defaultValue,
    on, scrollingContainer, defaultIsExpend, hideChange: propsOnMoreClick, H5Width
  } = props;
  const editorRef = useRef<EditorRef>(null)
  const {
    toolbarRef,
    editorDivRef
  } = useEditor({
    autofocus,
    toolbarId,
    onChange,
    placeholder,
    maxLength,
    alioss,
    readOnly,
    defaultValue,
    on,
    scrollingContainer,
    H5Width
  }, editorRef)
  const [isHide, setIsHide] = useState(!defaultIsExpend && !!readOnly);
  /** 摘要文字 */
  const { text, images, isRenderMore } = useMemo(() => {
    if (!readOnly) return {};
    const { text: textContent = '', specialFormat = false } = getTextByRichText(defaultValue)
    return {
      text: addEllipsisToStr(textContent, maxLength),
      images: getImagesByRichText(defaultValue),
      isRenderMore: specialFormat || textContent.length > maxLength
    }
  }, [defaultValue, readOnly])
  const onMoreClick = (state?: boolean) => {
    const newValue = typeof state === 'undefined' ? !isHide : state;
    setIsHide(newValue);
  }
  useIsomorphicLayoutEffect(() => {
    propsOnMoreClick?.(isHide)
  }, [isHide])
  useImperativeHandle(ref, () => ({
    onMoreClick,
    getImages: () => {
      return editorRef.current?.getImages() || []
    },
    getLatestValue: () => {
      return editorRef.current?.getLatestValue() || ''
    },
    uploadImage: () => {
      return editorRef.current?.uploadImage() || Promise.resolve();
    },
    blur: () => {
      editorRef.current?.blur?.()
    }
  }))

  return {
    toolbarRef,
    editorDivRef,
    isHide,
    text,
    onMoreClick,
    images,
    isRenderMore
  }
}
