import React from 'react';

import type { TextCountProps } from './type'

import styles from './index.module.less'

export const TextCount: React.FC<TextCountProps> = ({ maxLength, currentLength }) => {
  return (
    <span className={styles.textCount}>
      {`${currentLength || 0}/${maxLength}`}
    </span>
  )
}
