import React from 'react';

import cn from 'classnames'

import { type SpinProps, SPIN_STATUS } from './';

import styles from './index.module.less'

export const Spin: React.FC<SpinProps> = (props) => {
  const { renderLoading, renderError, renderEmpty, status, children, className = '', loadingClassName } = props;
  const render = () => {
    return (
      <div className={cn(styles.container, loadingClassName)}>
        {status === SPIN_STATUS.LOADING && renderLoading}
        {status === SPIN_STATUS.ERROR && renderError}
        {status === SPIN_STATUS.EMPTY && renderEmpty}
      </div>
    )
  }
  const notSuccess = status !== SPIN_STATUS.SUCCESS;
  return (
    <div className={cn(styles.body, className, {
      [styles.overflow]: notSuccess
    })}>
      {notSuccess && render()}
      {children}
    </div>
  )
}
