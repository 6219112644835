import React from 'react';

import { Tabs as AntdTabs } from 'antd';
import cn from 'classnames';

import type { ITabPaneProps, ITabsProps } from './type'

import styles from './index.module.less';
const AntdTabPane = AntdTabs.TabPane;

export const Tabs: React.FC<ITabsProps> = (props) => {
  const { className, ...restProps } = props
  return (
    <AntdTabs
      {...restProps}
      className={cn(styles.tabs, className)}
    />
  )
}

export const TabPane: React.FC<ITabPaneProps> = (props) => {
  const { className, ...restProps } = props
  return (
    <AntdTabPane
      className={cn(styles.tabPane, className)}
      {...restProps}
    />
  )
}
