import React from 'react';

import cn from 'classnames';
import Image from 'next/image';

import { ImageTextProps } from './type';

import styles from './index.module.less';

const ImageTextComponent: React.FC<ImageTextProps> = (props) => {
  const {
    btnText,
    src,
    onClick,
    imageWidth = 45,
    imageHeight = 45,
    className,
    textClassName
  } = props;
  return (
    <div className={cn(styles.loginBottom, className)} onClick={onClick}>
      {src && (
        <Image alt="TRex" src={src} width={imageWidth} height={imageHeight} />
      )}
      <div className={cn(styles.label, 'DINPro', textClassName)}>{btnText}</div>
    </div>
  );
};

export const ImageText: React.FC<ImageTextProps> = React.memo<ImageTextProps>(ImageTextComponent);
