import React, { useRef } from 'react'

import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks'
import { BigPlayButton, Player } from 'video-react';

import { previewVideo } from './preview'
import { VideoComponentProps } from './type'

import 'video-react/dist/video-react.css';
import styles from './index.module.less'

// 组件地址: https://github.com/video-react/video-react
// API: https://video-react.js.org/components/player/

const VideoComponent: React.FC<VideoComponentProps> = (props) => {
  const { videoSource, width, height, fluid = false, poster, className } = props;
  const refVideo = useRef<any>(null)
  const onClick = (e) => {
    e.stopPropagation()
    refVideo.current = previewVideo(props)
  }
  useIsomorphicLayoutEffect(() => {
    return () => {
      refVideo.current?.()
    }
  }, [])
  return (
    <div
      className={cn('video-component', className)}
      style={{ height: height, width: width }}
    >
      <Player
        fluid={fluid}
        width={width}
        height={height}
        src={videoSource}
        playsInline={false}
        className={styles.player}
        poster={poster}
      >
        <div className={styles.mask} onClick={onClick} />
        <div
          className={cn(
            'video-react-button',
            'video-react-big-play-button',
            'video-react-big-play-button-center',
            styles.bigPlayBtn
          )}
          onClick={onClick}
        >
          <span className='video-react-control-text' />
        </div>
        <BigPlayButton position="center" />
      </Player>
    </div>
  );
};

export default VideoComponent
