import cookie from 'js-cookie'

import { RANKING_TYPE, REACTION_TYPE, TARGET_TYPE } from '@constants/index';

import { getClient } from './stream';

const getUserId = () => {
  return cookie.get('userId')
}

export const getCommentList = async ({ parentId, nextToken, limit = 20 }:
  { parentId: string, nextToken?: string, limit: number }) => {
  const query = {
    activity_id: parentId,
    targetId: parentId,
    targetType: TARGET_TYPE.ACTIVITY,
    ranking: RANKING_TYPE.TIME,
    reverse: false,
    kind: REACTION_TYPE.COMMENT,
    withActivity: false,
    withUser: true,
    withTarget: true,
    withChildCounts: true,
    withChild: true,
    childLimit: 2,
    childKinds: `${REACTION_TYPE.REPLY},${REACTION_TYPE.LIKE}`,
    limit,
    withOwnChildren: true,
    userId: getUserId(),
    nextToken: nextToken || ''
  };
  return await getClient()?.reactions.filter(query) as unknown as any;
}

export const getReplyList = async ({ parentId, nextToken, limit = 20 }:
  { parentId: string, nextToken?: string, limit?: number }) => {
  const query = {
    reaction_id: parentId,
    targetId: parentId,
    targetType: TARGET_TYPE.REACTION,
    ranking: RANKING_TYPE.TIME,
    reverse: false,
    kind: REACTION_TYPE.REPLY,
    withActivity: false,
    withUser: true,
    withTarget: true,
    childKinds: `${REACTION_TYPE.REPLY},${REACTION_TYPE.LIKE}`,
    withChildCounts: true,
    withChild: true,
    childLimit: 2,
    limit,
    withOwnChildren: true,
    userId: getUserId(),
    nextToken: nextToken || ''
  };
  return await getClient()?.reactions.filter(query) as unknown as any;
}

export const addComment = async (targetViewId: string, value: string, kind: string = REACTION_TYPE.COMMENT) => {
  const userId = JSON.parse(localStorage.getItem('userInfo') || '')?.id;
  const data = {
    text: value,
    images: [],
    target: targetViewId
  };

  return getClient()?.reactions.addChild(kind, targetViewId, data, { userId }) as unknown as any;
}
