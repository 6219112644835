import { useEffect, useImperativeHandle, useState } from 'react'

import { useClient, useDebounce } from 'hooks';

/* 背景图的长宽比 */
const ASPECT_RATIO = 744 / 269
/* canvans画布放大比率 */
const rate = 4

export const useCropImg = ({ imageUrl, cropRef, type, editor, cropContainRef }) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [imgCropBorderH, setImgCropBorderHeight] = useState<number>(0)
  const [imgCropBorderW, setImgCropBorderW] = useState<number>(0)
  const [image, setImage] = useState<string | File>(imageUrl);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const isPc = useClient(576)

  useEffect(() => {
    // 监听屏幕大小变化
    getresize()
    window.addEventListener('resize', debounceResize)
    return () => {
      window.removeEventListener('resize', debounceResize)
    }
  }, [imageUrl, isPc])

  useEffect(() => {
    if (!imageUrl) return
    setImage(imageUrl)
  }, [imageUrl])

  useImperativeHandle(cropRef, () => {
    return {
      handleSave
    }
  });

  const handleSave = async () => {
    const cropImg = await getImageUrl()
    return cropImg
  }

  const getImageUrl = async () => {
    const dataUrl = editor.current?.getImageScaledToCanvas().toDataURL()
    const result = await fetch(dataUrl)
    const blob = await result.blob()
    return window.URL.createObjectURL(blob)
  }

  const getresize = () => {
    const { clientHeight, clientWidth } = cropContainRef.current
    const height = !isPc ? clientHeight : 320
    const width = !isPc ? clientWidth : 320
    console.log(clientHeight, clientWidth, height, width, '范围ddd')
    /* 裁剪框水平边框宽度 */
    let borderLeft
    /* 裁剪画布中间裁剪框的宽度 */
    let canvansCropW
    /* 裁剪框的水平边框宽度 */
    let imgCropBorderW
    /* 裁剪框画布中间裁剪框的高度 */
    let canvansCropH
    if (type === 'avatar') {
      borderLeft = !isPc ? 20 : 60
      canvansCropW = width - borderLeft * 2
      imgCropBorderW = (height - canvansCropW) / 2
      canvansCropH = height - imgCropBorderW * 2

      setWidth(canvansCropW)
      setHeight(canvansCropH)
      setImgCropBorderW(borderLeft)
      setImgCropBorderHeight(imgCropBorderW)
      return
    }
    canvansCropW = width * rate
    canvansCropH = width / ASPECT_RATIO * rate
    /* 裁剪框的垂直边框宽度 */
    const imgCropBorderH = (height * rate - canvansCropH) / 2
    console.log(imgCropBorderH, 'imgCropBorderH', canvansCropW, canvansCropH)
    setWidth(canvansCropW)
    setHeight(canvansCropH)
    setImgCropBorderW(0)
    setImgCropBorderHeight(imgCropBorderH)
  }
  const debounceResize = useDebounce(getresize, 100)

  const handlePositionChange = (position) => {
    setPosition(position);
  };

  return {
    width,
    height,
    imgCropBorderH,
    imgCropBorderW,
    image,
    position,
    handlePositionChange
  }
}
