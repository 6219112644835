import React from 'react';

import cn from 'classnames';
import Link from 'next/link';
import { prefixInteger0 } from 'utils';

import { QuestionItem } from '../questionItem';
import { QuestionRankItemProps } from './type';

import styles from './questionRankItem.module.less';

export const QuestionRankItem: React.FC<QuestionRankItemProps> = (props) => {
  const { link = '', order, className, ...rest } = props;
  const orderNum = Number(order);
  return (
    <div className={cn(styles.container, className)}>
      <Link href={link}>
        <div className={styles.main}>
          <div
            className={cn(styles.sequenceNumber, 'SFProTextHeavyItalic', {
              [styles.rank1Color]: orderNum === 1,
              [styles.rank2Color]: orderNum === 2,
              [styles.rank3Color]: orderNum === 3
            })}
          >
            {prefixInteger0(orderNum, 2)}.
          </div>
          <div className={styles.answerBox}>
            <QuestionItem {...rest} />
          </div>
        </div>
      </Link>
    </div>
  );
};
