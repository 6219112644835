import React from 'react';

import Image from 'next/legacy/image';

import { If } from '@/condition-render'

import { IdentificationProps } from './type'

const Identification: React.FC<IdentificationProps> = (props) => {
  const { identificationSource, size, className } = props;
  return (
    <If
      condition={!identificationSource}
      true={null}
      false={(
        <div className={className}>
          <Image
            src={identificationSource}
            width={size}
            height={size}
            layout="fixed"
            alt={'vip'}
          />
        </div>
      )}
    />
  );
};

export default Identification;
