import React from 'react';

import cn from 'classnames';
import type { ImageProps } from 'next/image';

import { Avatar } from '../index';
import { TextAvatarProps } from './type';

import styles from './avatar.module.less';
const defaultAvatar =
  require('./media/defaultAvatar.webp') as ImageProps['src'];

const TextAvatar: React.FC<TextAvatarProps> = (props) => {
  const { nickName, userName, isSquare, className, userInfoClassName, ...rest } = props;

  return (
    <Avatar
      {...rest}
      defaultAvatar={rest.defaultAvatar || defaultAvatar}
      className={cn(styles.avatar, className, { [styles.squareImg]: isSquare })}
    >
      <div className={cn({ [styles.userInfo]: nickName || userName }, userInfoClassName)}>
        {nickName}
        {userName}
      </div>
    </Avatar>
  );
};

export default TextAvatar;
