import React from 'react';

import { Image as AntdImage } from 'antd';
import cn from 'classnames';
import Image from 'next/legacy/image';

import { FeedImageProps } from './type'

import styles from './index.module.less';

const FeedImage: React.FC<FeedImageProps> = (props) => {
  const {
    src,
    width = 'auto',
    height = 'auto',
    className = '',
    style = {},
    preview = false,
    alt = '',
    placeholderSrc
  } = props;

  return (
    <div onClick={e => e.stopPropagation()}>
    <AntdImage
      src={src}
      width={width}
      height={height}
      className={cn(styles.feedImage, className)}
      style={{ ...style, width, height }}
      preview={preview}
      alt={alt}
      placeholder={
        <Image
          className={styles.feedImage}
          src={placeholderSrc}
          layout='fill'
          alt={alt}
        />
      }
   />
    </div>
  )
}

export default FeedImage;
