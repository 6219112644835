import React from 'react';

import { Progress as AntdProgress } from 'antd';
import cn from 'classnames';

import type { IProgressProps } from './type'

export const Progress: React.FC<IProgressProps> = (props) => {
  const { className, ...rest } = props;
  return (
    <AntdProgress
      className={cn(className)}
      {...rest}
    />
  )
}
