
export interface Achievement {
    point: number
}

export interface MemberPointLedgersReq {
    type: 'transfer_in' | 'transfer_out',
    offset: number,
    limit: number
}

export interface PointLedgersItem{
    amount: string,
    descI18n: string,
    created: string,
    operateType: number, // ??
    id: string,
    businessType: number,
    menu: 'string'
}

export interface MemberPointLedgersRes {
    customerId: string,
    point: string,
    pointLedgers: PointLedgersItem[]
}

export interface TaskReq {
    type: 0 | 1
}

export interface TaskResItem {
    remarkI18n: string,
    amount: number,
    process: number,
    completeThreshold : number,
    completeTimes : number,
    level : string,
    nameI18n : string,
    icon : string,
    remark : string,
    url : string,
    startTime : number,
    expiredTime : number,
    name : string,
    callback : string,
    event : string,
    status : TaskStatus,
    progress : number,
    id: string;
}

// 定义枚举
export enum TaskStatus {
    /** 待领取 **/
    RECEIVE = 0,
    /** 进行中 **/
    APPENDING = 1,
    /** 已完成 **/
    DONE = 2
}
