import React from 'react';

import { Empty, ScrollLoading, SPIN_STATUS, useGetScrollList } from 'base-components';
import { useIsomorphicLayoutEffect } from 'hooks'

import { FOLLOW_TAB_KEY } from '../const'
import { FollowerItem } from '../follower-item'
import I18n from '../i18n'
import { FollowersTabProps } from './type';

import styles from './FollowersTab.module.less';

export const FollowersTab: React.FC<FollowersTabProps> = (props) => {
  const { id, activeKey, requestApi, useFollowUser, getProfileLink } = props;
  const {
    status,
    fetch,
    rows,
    nextToken
  } = useGetScrollList({
    requestApi,
    queryParams: {
      slug: 'USER',
      relation: 'FOLLOW',
      with_target: true,
      with_relations: ['FOLLOW'],
      mutual: false,
      limit: 20
    }
  })

  useIsomorphicLayoutEffect(() => {
    const init = true;
    fetch({ id }, init)
  }, [activeKey]);

  const onScroll = () => {
    if (!nextToken.current) {
      return;
    }
    fetch({ id })
  };

  if (status === SPIN_STATUS.SUCCESS && rows.length === 0) {
    const emptyTitle = activeKey === FOLLOW_TAB_KEY.FOLLOWERS ? I18n.t('no_followers') : I18n.t('no_followeing')
    return (<Empty
    emptyTitle={emptyTitle}
    emptyDesc=' '
    />)
  }
  return (
    <div className={styles.container}>
      <ScrollLoading
        invisibleHeight={50}
        status={status}
        fetchData={onScroll}
        noMoreShow={!nextToken.current && rows.length !== 0}
      >
        {rows.map((item, index) => {
          const {
            relations = [],
            target: {
              id = '',
              avatar = '',
              intro = '',
              nick_name: nickName = '',
              unique_name: uniqueName = ''
            }
          } = item;
          return (
            <FollowerItem
              key={`${index}_${id}`}
              id={id}
              avatar={avatar}
              intro={intro}
              nickName={nickName}
              uniqueName={uniqueName}
              relations={relations}
              useFollowUser={useFollowUser}
              linkTo={getProfileLink(id)}
            />
          );
        })}
      </ScrollLoading>
    </div>
  );
};
