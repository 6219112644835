const sourceList = [
  'https://upload.dev.trex.xyz/',
  'https://upload.trex.xyz/',
  'http://upload.dev.trex.xyz',
  'https://upload.trexprotocol.com/',
  'https://trex-dev.oss-ap-southeast-1.aliyuncs.com/',
  'https://kiki-dev.oss-ap-southeast-1.aliyuncs.com/',
  'https://trex-beta.oss-ap-southeast-1.aliyuncs.com/',
  'https://trex-prod.oss-ap-southeast-1.aliyuncs.com/'
]

export const isValidSourceTRex = (
  src = '',
  type: 'image' | 'video' = 'image'
) => {
  let isValid = false;
  const source = src || '';
  const startValid = sourceList.some(item => source.startsWith(item));
  switch (type) {
    case 'image': {
      const endValid =
        /\.(png|jpg|xbm|tif|pjp|svgz|jpeg|ico|tiff|gif|svg|jfif|webp|bmp|pjpeg|avif)$/.test(
          source.toLocaleLowerCase()
        );
      isValid = startValid && endValid;
      break;
    }
    case 'video': {
      const endValid = /\.(mpg|mpeg|avi|rm|rmvb|mov|wmv|asf|dat|mp4)$/.test(
        source.toLocaleLowerCase()
      );
      isValid = startValid && endValid;
      break;
    }
  }
  return isValid;
};

export const isBase64 = (str) => {
  console.log(str, 'strstr')
  if (str === '' || str?.trim() === '') { return false; }
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}