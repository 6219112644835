import React from 'react'

import { Button, DragProgress, openModal } from 'base-components';
import { useClient } from 'hooks';

import { CropImg } from '../crop-img'
// @ts-ignore
import Close from './close.svg';
import I18n from './i18n';
import { Upload } from './upload'
import { useEditAvatar } from './useEditAvatar'

import 'react-image-crop/dist/ReactCrop.css';
import styles from './editAvatar.module.less';

type ContentPropsTypes ={
  imgUrl: string,
  type: 'background' | 'avatar',
  alioss: any,
  resolve: (url: any) => void,
  close: () => void,
  onCancel?: ()=> void,
}

const Content:React.FC<ContentPropsTypes> = ({ close, imgUrl: defaultUrl, type, alioss, resolve }) => {
  const cropRef = React.useRef(null)
  const {
    imageUrl,
    loading,
    scale,
    finishSelected,
    uploadChange,
    onProgress
  } = useEditAvatar({
    defaultUrl, resolve, alioss, cropRef, close
  })

  const isPc = useClient(576)
  return (
    <div className={styles.content}>
      {
        !isPc && (
          <div className={styles.title}>
            <Close onClick={close} className={styles.icon}/>
            <span className='openSansSemiBold'>move and zoom</span>
            <Button type='primary' customizeSize='xsmall' onClick={finishSelected}>Save</Button>
          </div>
        )
      }
        <CropImg
              imageUrl={imageUrl}
              scale={scale}
              type={type}
              cropRef={cropRef}
        />
        {
          isPc && (
            <DragProgress onProgress={onProgress} className={styles.progress}/>
          )
        }
        {
          isPc ? (
          <div className={styles.bottom}>
              <Upload onChange={uploadChange}/>
              <Button
                type='primary'
                className={styles.buttom}
                onClick={close}
                colorType='black'
              >
                {I18n.t('discard')}
              </Button>
              <Button
                type='primary'
                className={styles.buttom}
                loading={loading}
                onClick={finishSelected}
                >
                  {I18n.t('save')}
              </Button>
          </div>
          ) : (
            <div className={styles.bottom}>
                <DragProgress onProgress={onProgress} className={styles.progress}/>
                <Upload onChange={uploadChange}/>
            </div>
          )
        }
    </div>
  );
};

export const openEditImage = async (props) => {
  const res = await openModal({
    title: <span>{I18n.t('AddCommunity')}</span>,
    mask: true,
    maskClosable: false,
    closable: true,
    className: styles.modal,
    cardClass: styles.cardClass,
    closeClass: styles.closeClass,
    headerClass: styles.headerClass,
    maskClass: styles.maskClass,
    children: (params) => <Content {...params} {...props} />
  });
  return res;
};
