import React, { FC, useMemo } from 'react';

import { Title } from 'atom-components';
import { getImagesByRichText, RichEditor } from 'base-components';
import cn from 'classnames';
import { useClient } from 'hooks';
import Image from 'next/image';
import { createRandomColorImg, isValidSourceTRex } from 'utils';

import { HotValue } from './hotValue';
import { QuestionItemProps } from './type';

import styles from './questionItem.module.less';

export const QuestionItem: FC<QuestionItemProps> = (props) => {
  const { likeCount, title, className, detail, titleClassName } = props;
  const isPc = useClient(576);

  const { img, isImageSource } = useMemo(() => {
    const images = getImagesByRichText(detail);
    // base64
    const isImageSource = isValidSourceTRex(images[0] ?? '', 'image') || (images[0] ?? '').startsWith('data:image/')
    return {
      img: (
        <div
          className={cn(styles.imgBox, {
            [styles.hide]: !isImageSource
          })}
        >
          {isImageSource && (
            <Image
              alt="TRex"
              src={images[0] ?? ''}
              width={isPc ? 192 : 126}
              height={isPc ? 120 : 80}
              placeholder="blur"
              blurDataURL={createRandomColorImg(1)[0]}
            />
          )}
        </div>
      ),
      isImageSource
    };
  }, [isPc, detail]);
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.main}>
        <Title className={cn(styles.title, titleClassName)}>{title}</Title>
        <div className={styles.detail}>
          <div className={styles.richEditorBox}>
            <div className={styles.content}>
              {!!detail && (
                <RichEditor defaultValue={detail} readOnly maxLength={isImageSource ? 110 : 180} />
              )}
            </div>
            <HotValue likeCount={likeCount ?? 0} />
          </div>
          {!isPc && img}
        </div>
      </div>
      {isPc && img}
    </div>
  );
};
