export const baseColor = {
  transparent: 'transparent',
  color1: '#6F40EE',
  color2: 'rgba(111, 64, 238, 0.06)',
  color3: 'rgba(246, 248, 251, 0.45)',
  color4: '#6339D5',
  color5: '#1EAE45',
  color6: '#1A9C3D',
  color7: 'rgba(30, 174, 69, 0.10)',
  color8: '#E39C05',
  color9: '#CB8C04',
  color10: '#FCF5E6',
  color11: '#ED220D',
  color12: '#D41E0B',
  color13: '#000',
  color14: '#1F2126',
  color15: '#BEC2CD',
  color16: '#696F7F',
  color17: '#8E939F',
  color18: '#F6F8FB',
  color19: 'rgba(0,0,0,0.4)',
  color20: 'rgba(0,0,0,0.06)',
  color21: '#f05b4c00',
  color22: '#fffafa',
  color23: 'rgba(237, 34, 13, 0.80)',
  color24: 'rgba(240, 91, 76, 0.03)',
  color25: 'rgba(111, 64, 238, 0.4)',
  color26: 'rgba(255, 255, 255, 0.4)',
  color27: '#434b60',
  color28: 'rgba(67, 75, 96, 0.4)',
  color29: 'rgba(0, 0, 0, .4)',
  color30: '#45474F',
  color31: '#2F3036',
  color32: 'rgba(0, 0, 0, .8)',
  color33: '#1A1B1F',
  color34: 'rgba(0,235,154,0.4)',
  color35: '#5C5F6B',
  color36: '#464952',
  color37: '#232528',
  color38: '#4E586E',
  color39: 'rgba(151, 151, 151, .12)',
  color40: '#212326',
  color41: '#ff5a51',
  color42: '#ff9151',
  color43: '#ffd351',
  color44: 'rgba(255, 255, 255, .1)',
  color45: '#789CEF',
  color46: '#1F389C',
  color47: '#AEC9F7',
  color48: 'rgba(47, 49, 55, .5)',
  color49: 'rgba(71, 74, 82, .5)',
  color50: '#121214',
  color51: 'rgba(0, 0, 0, 0.4)',
  color52: 'rgba(0, 0, 0, 0.8)',
  cyan1: '#E8FFF3',
  cyan2: '#B7FBDA',
  cyan3: '#87F7C5',
  cyan4: '#58F3B3',
  cyan5: '#2BEFA4',
  cyan6: '#00EB9A',
  cyan7: '#00C386',
  cyan8: '#009C70',
  cyan9: '#007457',
  cyan10: '#004D3C',
  cyan11: '#4D9179',
  purple1: '#F4E8FF',
  purple2: '#E1CBFB',
  purple3: '#CEAEF7',
  purple4: '#B993F3',
  purple5: '#A478EF',
  purple6: '#8D5EEB',
  purple7: '#643BC3',
  purple8: '#401F9C',
  purple9: '#240C74',
  purple10: '#0F004D',
  lime1: '#FFFFE8',
  lime2: '#F9FBB7',
  lime3: '#F0F787',
  lime4: '#E4F358',
  lime5: '#D5EF2B',
  lime6: '#C4EB00',
  lime7: '#9CC300',
  lime8: '#779C00',
  lime9: '#557400',
  lime10: '#364D00',
  yellow1: '#FEFFE8',
  yellow2: '#FEFEC9',
  yellow3: '#FCF9AB',
  yellow4: '#FBF38C',
  yellow5: '#F9EC6F',
  yellow6: '#F8E151',
  yellow7: '#CDB332',
  yellow8: '#A2871A',
  yellow9: '#775E0A',
  yellow10: '#4D3800',
  grey1: '#FFFFFF',
  grey2: '#E4E5E7',
  grey3: '#C8CAD0',
  grey4: '#ADB0B8',
  grey5: '#9295A0',
  grey6: '#777B88',
  grey7: '#5F626D',
  grey8: '#474A52',
  grey9: '#2F3137',
  grey10: '#18191B',
  grey11: 'rgba(24,25,27, 0.3)', // grey10 的半透明
  grey12: 'rgba(47,49,55, 0.3)', // grey9 的半透明
  grey13: 'rgba(24,25,27, 0.8)', // grey10 的半透明
  errorColor: '#E33F5E',
  warningColor: '#FFCD42',
  linkColor: '#5E84EB',
  iconHoverBacnground: '#979797'
};
