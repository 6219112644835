export const handleMaskScroll = (isFixed: boolean) => {
  const bodyEl = document.body;
  if (isFixed) {
    const top = window.scrollY;
    bodyEl.style.position = 'fixed'
    bodyEl.style.top = -top + 'px'
  } else {
    const y = Math.abs(parseInt(bodyEl.style.top)) || window.scrollY;
    bodyEl.style.position = ''
    bodyEl.style.top = ''
    window.scrollTo(0, y) // 回到原先的top
  }
}
