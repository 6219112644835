// Pixel GIF code adapted from https://stackoverflow.com/a/33919020/266535
const colors = ['#002B41', '#00414E', '#345056', '#495659', '#143B3B', '#1F4937', '#2E5537', '#475949'];
/** base64 编码字典 0 A 1 B ..... */
const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

const triplet = (e1: number, e2: number, e3: number) =>
  keyStr.charAt(e1 >> 2) +
  keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
  keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
  keyStr.charAt(e3 & 63)

const rgbDataURL = (r: number, g: number, b: number) =>
  `data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, r, g) + triplet(b, 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`
/**
 * 生成随机 base64 格式的纯色图片，用来给 next/image 的 blurDataURL 用 目的是当图片未加载回来时显示占位符
 * @param count 要生成的数量
 * @returns 由base64组成的数组
 */
export const createRandomColorImg = (count = 1) => {
  return new Array(count).fill(0).map(() => {
    const index = Math.floor(Math.random() * colors.length);
    const color = colors[index];
    const red = parseInt(color.slice(1, 3), 16);
    const green = parseInt(color.slice(3, 5), 16);
    const blue = parseInt(color.slice(5, 7), 16);
    return rgbDataURL(red,green,blue);
  })
}