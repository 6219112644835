import { useState } from 'react';

import { message } from 'base-components';

import { AnswerDetail, getAnswerDetail, getQuestionDetail } from '@api/index';

const useQuestionDetailPage = () => {
  const [questionDetail, setQuestionDetail] = useState<any>();
  const [isLoading, setLoading] = useState(false);
  const [topAnswer, setTopAnswer] = useState<AnswerDetail[]>([]);
  const [isStop, setIsStop] = useState<boolean>(false);

  const fetchData = async (id: string, questionUserId: string) => {
    setLoading(true);
    try {
      const enrichOptions = {
        enrich: true,
        withRecentReactions: false,
        recentReactionsLimit: 1,
        withOwnReactions: false,
        withOwnChildren: false,
        withReactionCounts: false,
        reactionKindsFilter: [],
        with_community: true
      }
      const dataRes = await getQuestionDetail({
        feed_slug: 'question',
        resource_id: questionUserId,
        view_id: id,
        ...enrichOptions
      });
      if (dataRes.success) {
        setQuestionDetail(dataRes.obj);
      } else {
        setQuestionDetail({});
        if (dataRes.code === 1001) {
          setIsStop(true)
        } else {
          message.error(dataRes?.msg);
        }
      }
    } catch {
      message.error('system.error')
    }
    setLoading(false)
  };

  const fetchAnswerDetail = async (answerId: string, answerUserId: string) => {
    try {
      const enrichOptions = {
        enrich: true,
        withRecentReactions: true,
        recentReactionsLimit: 20,
        withOwnReactions: true,
        withOwnChildren: true,
        withReactionCounts: true,
        reactionKindsFilter: [
          'COMMENT'
        ]
      }
      if (!(typeof answerId === 'string' && typeof answerUserId === 'string')) {
        setTopAnswer([]);
        return
      }
      const dataRes = await getAnswerDetail({
        feed_slug: 'timeline_answer',
        resource_id: answerUserId,
        view_id: answerId,
        ...enrichOptions
      });
      if (dataRes.success) {
        console.log('success', dataRes)
        setTopAnswer([dataRes.obj])
      }
    } catch (e) {
      console.log('getAnswerDetail_Error:::', e)
    }
  };

  return {
    isStop,
    isLoading,
    fetchData,
    questionDetail,
    topAnswer,
    fetchAnswerDetail
  }
};

export { useQuestionDetailPage };
