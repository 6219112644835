type ILANG = 'zh-CN' | 'zh-TC' | 'en';

const LANG: ILANG[] = ['zh-CN' ,'zh-TC', 'en']

type ILangSource = {
  [key in ILANG]?: {
    [key: string]: string;
  }
}

type IOptions = {
  [key: string]: string|number;
}

const handleMsg = (msg: string, options: IOptions = {}) => {
  const reg = /(%\{([^}]+)\})/g;
  const groups = Array.from(msg.matchAll(reg));
  if (!groups.length) {
    return msg
  }
  let newMessage = msg;
  groups.forEach(([group, , key]) => {
    newMessage = newMessage.replace(group, options?.[key.trim()]?.toString());
  })
  return newMessage
}

class I18N {
  static instance: I18N | null;
  private lang: ILangSource = {};
  public locale: ILANG = 'en';
  constructor() {
    this.lang = {};
    this.locale = 'en'
  }
  t(key: string, options?: IOptions) {
    return handleMsg(this.lang[this.locale]?.[key] ?? key, options);
  }
  load(lang: ILangSource) {
    Object.keys(lang).forEach((key: ILANG) => {
      if (LANG.includes(key)) {
        this.lang[key] = Object.assign({}, this.lang[key], lang[key]);
      } else {
        throw new Error(`${key} 是不被支持的语言，如确认请修改 I18n 的类型约束，然后周知其他相关同事`);
      }
    })
  }
  updateLocale(locale: ILANG) {
    this.locale = locale;
  }
  static getInstance() {
    if (!this.instance) {
      this.instance = new I18N();
    }
    return this.instance;
  }
}

export default I18N.getInstance();