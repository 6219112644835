import React, { useImperativeHandle, useRef } from 'react';

import { RichEditor } from 'base-components/rich-editor';

import I18n from '../../utils/i18n'
import type { DescriptionProps } from './type'

import styles from './index.module.less'

const Description = React.forwardRef((props: DescriptionProps, ref) => {
  const { maxDescLength, alioss, setCurrentDescLength, setDesc, defaultEditorValue, setDescText, scrollingContainer } = props;
  const editorRef = useRef<any>();
  const editorReferenceRef = useRef({});
  const onChange = (value) => {
    setCurrentDescLength(value.textLength)
    setDesc(value.latestValue)
    setDescText(value.text)
  }

  const getLatestData = async () => {
    await editorRef?.current?.uploadImage()
    const latestData = editorRef?.current?.getLatestValue?.()
    return latestData
  }

  useImperativeHandle(ref, () => {
    return {
      editorRef,
      getLatestData,
      editorReferenceRef
    };
  }, []);

  return (
    <div className={styles.descContainer}>
      <RichEditor
        toolbarId='answerEditorToolbar'
        placeholder={I18n.t('answer_description_pleaceholder')}
        ref={editorRef}
        alioss={alioss}
        defaultValue={defaultEditorValue}
        maxLength={maxDescLength}
        onChange={onChange}
        autofocus={true}
        scrollingContainer={scrollingContainer}
        H5Width={576}
      />
    </div>
  )
})
Description.displayName = 'Description';

export const AnswerDescription = Description
