import I18n from 'i18n';

import en from './en.json';
import msgKey from './msgKey.json'

const uploadHost = process.env.NEXT_PUBLIC_TREX_UPLOAD_API

const remoteLanguag = `${uploadHost}language/data-en.json?${new Date().getTime()}`

export const fetchRemoteLocale = async () => {
  const response = await fetch(remoteLanguag);
  const res = await response.json();
  I18n.load({ en: res });
}
fetchRemoteLocale()
I18n.load({
  en: {
    ...en,
    ...msgKey
  }
})

export default I18n
