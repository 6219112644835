import { COMMUNITY_STATUS, QUESTION_STATUS } from '@constants/index';

export enum dynamicTypeEum {
  /** 发布问题 */
  PUBLISH_COLLECTION = 'PUBLISH_COLLECTION',
  /** 回答问题 */
  ANSWER = 'ANSWER',
  /** 喜欢回答 */
  LIKE_ANSWER = 'LIKE_ANSWER',
  /** 评论回答 */
  COMMENT_ANSWER = 'COMMENT_ANSWER',
  /** 关注板块 */
  JOIN_COMMUNITY = 'JOIN_COMMUNITY',
}

type CollectionItem = {
  /** 问题描述 */
  title: string;
  ['user_id']: string;
  /** 问题答案数量 */
  ['child_activity_count']?: number;
  ['view_id']: string;
  /** 问题状态  */
  status: QUESTION_STATUS;
  user: IUser;
}
type IUser = {
  ['nick_name']: string;
  ['unique_name']: string;
  avatar: string;
  id: string;
}
type ActivityItem = {
  ['comment_count']: number;
  ['like_count']: number;
  /** 回答内容 */
  text: string;
  ['view_id']: string;
  ['user_id']: string;
  user: IUser;
  owns: {
    LIKE?: boolean;
  }
}
type ReactionItem = {
  /** 回复内容 */
  text: string;
  user: IUser;
}

type CommunityItem = {
  id: string;
  /** 板块标题 */
  title: string;
  /** 板块描述 */
  desc: string;
  icon: string;
  status: COMMUNITY_STATUS;
}
export interface IProfileDynamicReq {
  limit?: number;
  ['next_token']?: string;
  ['user_id']?: string;
}

export interface IProfileDynamicRes {
  ['next_token']?: string;
  rows?: IProfileDynamicItem[]
}

export interface IProfileDynamicItem {
  created: number;
  verb: dynamicTypeEum;
  id: string;
  collection?: CollectionItem;
  activity?: ActivityItem;
  reaction?: ReactionItem;
  community?: CommunityItem;
}
