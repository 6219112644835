import React, { memo, useRef } from 'react'
import AvatarEditor from 'react-avatar-editor'

import { useCropImg } from './useCropImg'

import styles from './index.module.less';

export const ASPECT_RATIO = 744 / 269

interface CropImgProps{
    imageUrl: string,
    scale: number,
    type: 'avatar' | 'background',
    cropRef: any
}

const newData = new Date().getTime()

const T:React.FC<CropImgProps> = ({ imageUrl, scale, type, cropRef }) => {
  const editor = useRef<any>(null);
  const cropContainRef = useRef(null)
  const {
    width, height, imgCropBorderW,
    imgCropBorderH, position, handlePositionChange, image
  } = useCropImg({
    imageUrl, cropRef, type, editor, cropContainRef
  })

  const imgUrl = typeof image !== 'string' ? image : `${image}?${newData}`
  return (
        <div ref={cropContainRef} className={styles.cropContain}>
          <AvatarEditor
              ref={editor}
              scale={scale}
              width={width}
              height={height}
              position={position}
              onPositionChange={handlePositionChange}
              image={imgUrl}
              crossOrigin='anonymous'
              disableHiDPIScaling={true}
              border={[imgCropBorderW, imgCropBorderH]}
          />
      </div>
  )
}

export const CropImg = memo(T)
