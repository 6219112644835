import { Dispatch } from 'react';

import { message } from 'base-components'
import cookie from 'js-cookie';
import { isEmpty } from 'lodash';
import { pubsub } from 'utils';
import Web3 from 'web3';

import {
  type IUserInfo,
  getSocialUserInfo,
  getUserInfo as fetchUserInfo,
  ISocialUserInfo,
  login, logout
} from '@api/index';
import TrexLogo from '@components/layout/components/menu/media/Trex-logo.webp';
import { IAction, IActionEum } from '@hooks/index';
import { I18n, initData } from '@utils/index';
import { ADAPTER_EVENTS, CHAIN_NAMESPACES, WALLET_ADAPTERS } from '@web3auth/base';

const clientId = 'BJueUZ5N7vYzeeJnsgLl8WXUvaMc1N7R12R3bfRsxf_YZK23VWW0nu7luPmPmOTmmpk8YBnQ0vJ_8Jkw0UZWaMA'; // get from https://dashboard.web3auth.io

class Web3AuthCustom {
  static instance: Web3AuthCustom | null;
  public web3AuthInstance: any;
  public dispatch: Dispatch<IAction>;
  constructor (dispatch) {
    this.web3AuthInstance = null;
    this.dispatch = dispatch;
    this.init();
  }

  async getAccounts () {
    try {
      const web3 = new Web3(this.web3AuthInstance?.provider);
      const accounts = await web3.eth.getAccounts();
      console.log(web3.eth.ens, 'web3.eth.ens', web3.eth, accounts)
      return accounts[0] || ''
    } catch (error) {
      console.error('Error', error);
      console.log('error', error);
    }
    return '';
  };

  initData () {
    let userInfo = {} as IUserInfo;
    let socialInfo = {} as ISocialUserInfo;
    let walletAddress = '';
    try {
      userInfo = JSON.parse(localStorage.getItem('userInfo') || '');
      socialInfo = JSON.parse(localStorage.getItem('socialInfo') || '');
      walletAddress = localStorage.getItem('walletAddress') || '';
    } catch (err) { }
    if (!isEmpty(userInfo)) {
      this.dispatch({
        type: IActionEum.SET_USERINFO,
        payload: userInfo
      });
      this.dispatch({
        type: IActionEum.SET_SOCIAL_USERINFO,
        payload: socialInfo
      });
      this.dispatch({
        type: IActionEum.UPDATE_WALLET_ADDRESS,
        payload: walletAddress
      });
    }
  }

  async init (force = false) {
    this.initData();
    if (this.web3AuthInstance && !force) return;
    const { Web3Auth } = await import('@web3auth/modal')
    this.web3AuthInstance = new Web3Auth({
      clientId,
      chainConfig: {
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: '0x1'
      },
      uiConfig: {
        theme: 'dark',
        defaultLanguage: 'en',
        appLogo: TrexLogo.src
      },
      web3AuthNetwork: 'testnet'
    });
    this.web3AuthInstance.on(ADAPTER_EVENTS.ERRORED, (error) => {
      if (error?.errCode === 9999) {
        message.info(I18n.t('pluginsConflictsTips'));
      }
    });
    await this.web3AuthInstance.initModal({
      modalConfig: {
        [WALLET_ADAPTERS.METAMASK]: {
          showOnDesktop: true,
          showOnModal: true,
          showOnMobile: true,
          label: 'Metamask'
        },
        [WALLET_ADAPTERS.TORUS_EVM]: {
          showOnDesktop: false,
          showOnModal: false,
          showOnMobile: false,
          label: 'Torus'
        }
      }
    });
  }

  async #getUserInfo () {
    try {
      const userRes = await fetchUserInfo();
      const { success, obj } = userRes;
      if (!success) return;
      this.dispatch({
        type: IActionEum.SET_USERINFO,
        payload: obj
      });
      return obj;
    } catch (err) { }
  }

  async #getUserSocialInfo () {
    try {
      const userRes = await getSocialUserInfo();
      const { msg, obj } = userRes;
      if (!msg) return false;
      this.dispatch({
        type: IActionEum.SET_SOCIAL_USERINFO,
        payload: obj
      });
      return obj;
    } catch (err) { }
  }

  successLoginPubsub () {
    pubsub.publish('LOGIN_SUCCESS')
  }

  async handleLogin () {
    try {
      if (!this.web3AuthInstance?.provider) {
        // 取消连接之后，二维码过期强制生成新的二维码（新的实例）。
        await this?.init?.(true);
        await this.web3AuthInstance?.connect();
      }
      message.info(I18n.t('goSignatureTips'));
      let idToken = ''
      try {
        const idTokenRes = await this.web3AuthInstance?.authenticateUser();
        idToken = idTokenRes?.idToken || '';
      } catch (err) {
        message.error(I18n.t('noSignLoginTips'));
      }
      if (!idToken) return;
      const address = await this.getAccounts();
      this.dispatch({
        type: IActionEum.UPDATE_WALLET_ADDRESS,
        payload: address
      })
      const { success, obj } = await login({
        idToken,
        publicKey: address,
        thirdAuthSource: 'EXTERNAL_WALLET'
      });
      if (!success) {
        message.error(I18n.t('loginFailure'));
        return;
      }
      message.success(I18n.t('loginSuccess'));
      const { jwtKey, id } = obj || {}
      cookie?.set?.('jwt', jwtKey, { expires: 7 });
      cookie?.set?.('userId', id, { expires: 7 });
      await this.#getUserInfo();
      await this.#getUserSocialInfo();
      this.successLoginPubsub();
    } catch (err) {
      // 登录接口报错之后，需要断开钱包链接
      if (this.web3AuthInstance?.provider) {
        this.web3AuthInstance?.logout();
      }
      console.error('Wallet Connect Failed', err);
    }
  }

  async handleLogout () {
    try {
      const { success } = await logout();
      if (!success) return;
      await this.web3AuthInstance?.logout();
      this.web3AuthInstance = null;
      this.dispatch({
        type: IActionEum.REMOVE_USERINFO
      });
      initData();
    } catch (err) {
      console.error('Wallet Disconnect Failed', err);
    }
  }

  static getInstance (dispatch) {
    if (!this.instance) {
      this.instance = new Web3AuthCustom(dispatch);
    }
    return this.instance;
  }
}

export default Web3AuthCustom;
