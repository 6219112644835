import type { ForwardedRef } from 'react';
import { useImperativeHandle, useRef } from 'react';

import { useIsomorphicLayoutEffect } from 'hooks';
import md5 from 'md5';

import { prevewImage } from '@/PreviewImage';

import {
  batchUpload,
  editorLinkEdit,
  editorLinkSave,
  editorPosition,
  getBase64ByImage,
  getImageFiles,
  getImagesByRichText,
  getQuill,
  handleLink,
  initSetEditorValue
} from '../utils';
import type { EditorRefCurrent, UseEditorParams } from './type';

export const useEditor = (props: UseEditorParams, ref: ForwardedRef<EditorRefCurrent>) => {
  const {
    autofocus,
    toolbarId,
    onChange,
    placeholder,
    maxLength,
    alioss,
    readOnly,
    defaultValue,
    on,
    scrollingContainer,
    H5Width
  } = props;
  const toolbarRef = useRef<HTMLDivElement>(null);
  const editorDivRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<any>(null);
  const defaultValueRef = useRef({ isAction: false, text: '' });
  const cancheRef = useRef<any>({});
  const getEditorContentLength = () => {
    const length = editorRef.current.getLength() - 1;
    return length;
  }
  /** 这里特意选择使用 function 声明，为了拿到 toolbar 的this */
  async function handleImage () {
    const Quill = await getQuill();

    const ImageCompressor = (await import('js-image-compressor')).default;
    const toolbarEl = this.container;
    let fileInput = toolbarEl.querySelector('input.ql-image[type=file]');
    if (fileInput == null) {
      fileInput = document.createElement('input');
      fileInput.setAttribute('type', 'file');
      fileInput.setAttribute('accept', 'image/png, image/jpeg');
      fileInput.classList.add('ql-image');
      fileInput.addEventListener('change', async function () {
        if (fileInput.files != null && fileInput.files[0] != null) {
          const image = await getBase64ByImage(fileInput.files[0], ImageCompressor);
          const range = editorRef.current.getSelection(true);
          const Delta = Quill.import('delta')
          cancheRef.current[md5(image)] = {
            name: fileInput.files[0].name
          }
          const detail = new Delta().retain(range.index).delete(range.length).insert({ image })
          editorRef.current.updateContents(detail, 'user')
          editorRef.current.setSelection(range.index + 2, 'silent');
          toolbarEl.removeChild(fileInput)
        }
      });
      toolbarEl.appendChild(fileInput);
    }
    fileInput.click();
  }
  const updateToolbar = () => {
    if (!toolbarId) return;
    const toolbarEl = document.querySelector(`#${toolbarId}`);
    toolbarEl?.appendChild(toolbarRef.current as HTMLDivElement);
  }

  const addImagePreview = (images: string[]) => {
    editorRef.current.container.addEventListener('click', (e: any) => {
      if (e.target.tagName === 'IMG') {
        e.stopPropagation();
        prevewImage({ src: e.target.src, imgGroup: images });
      }
    })
  }

  const editorTextChange = (delta, oldDelta, source) => {
    if (readOnly) {
      const images = getImagesByRichText(defaultValue || '') as string[];
      addImagePreview(images);
      return
    };
    const text: string = editorRef.current.getText();
    const textfilterEmpty = text.replace(/\s+/g, '').replace(/[\r\n]/g, '')
    const textLength = textfilterEmpty.length;
    const alltextLength = text.length - 1;
    if (typeof maxLength === 'number' && textLength > maxLength && source === 'user') {
      editorRef.current.setContents(oldDelta, 'api');
      return
    }
    const range = editorRef.current.getSelection(true);
    const reference = editorRef.current.getBounds(range);
    const latestValue = getLatestValue();
    onChange?.({ text, textLength, latestValue, reference, alltextLength });
  }
  // 编辑器初始化完成之后 所做的一些副作用
  const initEffect = (Quill) => {
    editorRef.current.on('text-change', editorTextChange);
    const { text, isAction } = defaultValueRef.current;
    const length = getEditorContentLength();
    if (!!text && !isAction && length < 1) {
      initSetEditorValue(text, editorRef.current, cancheRef);
      defaultValueRef.current.isAction = true;
    }
    const focusEl = editorRef.current.container.querySelector('[contenteditable="true"]');

    const Delta = Quill.import('delta');
    editorLinkSave(editorRef.current, Delta);
    editorPosition(editorRef.current);
    editorLinkEdit(editorRef.current, H5Width);
    autofocus && editorRef.current.focus();
    editorRef.current.clipboard.addMatcher('img', function (node, delta) {
      return new Delta().insert(delta[0]?.attributes?.alt || '');
    });
    focusEl.addEventListener('focus', () => {
      on?.({ key: 'isFocus', value: true })
    })
    focusEl.addEventListener('blur', () => {
      on?.({ key: 'isFocus', value: false })
    })
    // const bindings = editorRef.current.keyboard.bindings;
    // const [{ ...rest }] = bindings[32];
    // editorRef.current.keyboard.bindings[32] = [];
    // editorRef.current.keyboard.addBinding({
    //   ...rest,
    //   prefix: /^\s*?(\d+\.|\[ ?\]|\[x\])$/
    // })
  }
  const initEditor = async () => {
    const Quill = await getQuill();
    updateToolbar();
    editorRef.current = new Quill(editorDivRef.current, {
      placeholder,
      theme: 'snow',
      modules: {
        toolbar: {
          container: toolbarRef.current,
          handlers: {
            image: handleImage,
            link: handleLink
          }
        }
      },
      readOnly,
      formats: ['bold', 'italic', 'link', 'list', 'image'],
      scrollingContainer,
      bounds: editorDivRef.current
    })
    initEffect(Quill);
  }
  // 初始化编辑器
  useIsomorphicLayoutEffect(() => {
    if (!editorRef.current) {
      initEditor()
    }
    return () => {
      editorRef.current = null;
    }
  }, [])
  // 处理外部传入的默认内容
  useIsomorphicLayoutEffect(() => {
    if (!defaultValue) return;
    if (!editorRef.current && !defaultValueRef.current.text) {
      defaultValueRef.current = {
        text: defaultValue,
        isAction: false
      }
      return
    }
    if (editorRef.current) {
      const length = getEditorContentLength();
      if (length > 0 && !readOnly) return;
      if (defaultValueRef.current.text && !readOnly) return;
      defaultValueRef.current = {
        text: defaultValue,
        isAction: true
      }
      initSetEditorValue(defaultValue, editorRef.current, cancheRef);
    };
  }, [defaultValue])
  // 获取编辑器内的最终内容 返回 html ，在标签上添加结构化数据
  const getLatestValue = () => {
    const length = getEditorContentLength();
    if (length < 1) return '';
    const html = editorRef.current.container.querySelector('[contenteditable="true"]').cloneNode(true);
    const contents = editorRef.current.getContents();
    html.childNodes[0].setAttribute('data-value', JSON.stringify(contents));
    html.childNodes[0].setAttribute('data-cache', JSON.stringify(cancheRef.current));
    return [...html.children].reduce((prev, curr) => `${prev}${curr.outerHTML}`, '')
  };
  const uploadImage = async () => {
    const contents = editorRef.current.getContents();
    const imageFiles = getImageFiles(contents, cancheRef.current);
    const res = await batchUpload(imageFiles, alioss);
    res.forEach(item => {
      contents.ops[item.index].insert.image = item.image;
    })
    const { index } = editorRef.current.getSelection(true);
    editorRef.current.setContents(contents, 'api');
    editorRef.current.setSelection(index, 'silent');
    return Promise.resolve();
  };
  const getImages = () => {
    const contents = editorRef.current.getContents();
    const ops = contents.ops.filter(op => !!op.insert?.image)
    return ops.map((op) => op.insert?.image);
  }

  useImperativeHandle(ref, () => ({
    getLatestValue,
    uploadImage,
    getImages,
    blur: () => {
      editorRef.current?.blur?.()
    }
  }))

  return {
    toolbarRef,
    editorDivRef
  }
}
