import { useEffect, useState } from 'react'

import { useDebounce } from 'hooks'

export const useMore = (contentRef) => {
  const [hasMore, setHasMore] = useState<boolean>(false)
  const [isMore, setisMore] = useState<boolean>(false)

  useEffect(() => {
    more()
    window.addEventListener('resize', moreFn)
    return () => window.removeEventListener('resize', moreFn)
  }, [contentRef?.current])

  const more = () => {
    const el = contentRef?.current
    if (!el) return
    const hasMore = !!(el && el.scrollHeight > el.offsetHeight)
    console.log(el.scrollHeight, el.offsetHeight, hasMore, 'hasMorehasMores')
    setHasMore(hasMore)
  }
  const moreFn = useDebounce(more, 500)

  const onClick = () => setisMore(!isMore)

  console.log(hasMore, isMore, 'asdasdasd')

  return {
    onClick,
    hasMore,
    isMore
  }
}
