import React from 'react'

import cn from 'classnames'

import { useProgress } from './useProgress'

import styles from './DragProgress.module.less'

type H5ProgressProps = {
    onProgress: (progress: number) => void,
    className?: string,
}

const T:React.FC<H5ProgressProps> = (props) => {
  const { className, onProgress } = props
  const refs = React.useRef<HTMLDivElement>(null)
  const { onDrag, left } = useProgress({ refs, onProgress })

  return (
    <div className={cn(styles.progress, className)} id='parent' ref={refs}>
      <div
        className={styles.progressBtn}
        onTouchStart={onDrag}
        onMouseDown={onDrag}
        onClick={() => console.log('click')}
        style={{ left }}
        id="block"
      />
    </div>
  )
}

export const DragProgress = React.memo(T)
