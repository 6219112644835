import React from 'react';

import { AvatarText } from 'base-components';
import { isValidSourceTRex } from 'utils';

import type { ListItemProps } from './type'

import styles from './index.module.less'

export const ListItem: React.FC<ListItemProps> = (props) => {
  const { id, icon, title, setCommunity, close } = props
  const onClick = () => {
    setCommunity({
      id,
      icon,
      title
    })
    close?.()
  }
  const showIcon = isValidSourceTRex(icon, 'image') ? icon : '';
  return (
    <div
      className={styles.ListItemContainer}
      onClick={onClick}
    >
      <AvatarText
        avatar={showIcon}
        avatarSize={24}
        borderRadius={8}
        iconClassName={styles.icon}
        className={styles.avatarText}
        userInfoClassName={styles.userInfoClassName}
        userName={<span className={styles.communityName}>{title || ''}</span>}
      />
    </div>
  )
}
