
export enum COMMUNITY_STATUS {
  NORMAL = 'NORMAL',
  STOP = 'STOP'
};

export enum COMMUNITY_ORDER {
  MODIFIED = 'MODIFIED',
  ORDER = 'ORDER'
};
