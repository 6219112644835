const getScrollContainer = (child: any): HTMLElement => {
  let parent: any = child.parentNode;

  while (parent != null && parent.nodeType === 1) {
    const overflow = window.getComputedStyle(parent).getPropertyValue('overflow-y');
    if (overflow === 'auto' || overflow === 'scroll') {
      if (
        parent.offsetHeight < parent.scrollHeight ||
        parent.offsetWidth < parent.scrollWidth
      ) {
        return parent;
      }
    }
    parent = parent.parentNode;
  }

  return document.body;
}

export const getAnchorPosition = (anchorElementId: string) => {
  const anchorElement = document.getElementById(anchorElementId);
  const scrollTop = anchorElement?.offsetTop
  const scroContain = getScrollContainer(anchorElement)
  console.log(scrollTop, 'scrollTop', scroContain)
  if (scrollTop) {
    scroContain?.scrollTo(0, scrollTop)
  }
}
