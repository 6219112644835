import React, { useEffect, useState } from 'react';

import { Button, TextArea } from 'base-components';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';
import { ToTopIcon } from 'icons';
import Image from 'next/legacy/image';
import { pubsub } from 'utils';

// @ts-ignore
import answerRankmock from './answerRankmock.webp';
import I18n from './i18n';
import { ReplyProps } from './type';

import styles from './comment.module.less';

export const Comment: React.FC<ReplyProps> = (props) => {
  const {
    defaultValue = '',
    getUserInfo,
    onChange,
    onSubmit,
    className,
    onBlur
  } = props;
  const [value, setValue] = useState<string>('');
  const [avatar, setAvatar] = useState(props.avatar);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onTextChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange?.(newValue);
  };

  const handleSubmit = async () => {
    if (!value) {
      return;
    }
    const success = await onSubmit?.(value);
    if (success) {
      setValue('');
    }
  };

  const onLoginSuccess = () => {
    setAvatar(getUserInfo?.()?.avatar)
  }

  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe('LOGIN_SUCCESS', onLoginSuccess);
    return () => {
      pubsub.unSubscribe('LOGIN_SUCCESS', onLoginSuccess);
    };
  }, []);

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.imgBox}>
        <Image
          src={avatar || answerRankmock}
          width={40}
          height={40}
          className={'image_borderRadius_40px'}
          alt="TRex"
        />
      </div>
      <div className={styles.textAreaBox}>
        <TextArea
          placeholder="Please enter the content"
          value={value}
          // bordered={false}
          // maxLength={maxTitleLength}
          className={styles.textArea}
          onChange={onTextChange}
          onBlur={() => onBlur?.(value)}
          autoSize={{ minRows: 1, maxRows: 5 }}
        />
      </div>
      <div className={styles.btnBox}>
        <Button
          type="primary"
          disabled={!value}
          btnClassName={styles.replyBtn}
          onClick={handleSubmit}
        >
          {I18n.t('commentBtnText')}
        </Button>
        <div className={styles.toTopIcon} onClick={handleSubmit}>
          <ToTopIcon />
        </div>
      </div>
    </div>
  );
};
