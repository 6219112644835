import isUrl from 'is-url';

function handleValueString (node, value) {
  value = this.sanitize(value);
  node.setAttribute('rel', 'noopener noreferrer');
  try {
    if (isUrl(value)) {
      node.setAttribute('target', '_blank');
      node.setAttribute('href', value);
      node.addEventListener('click', (e) => e.stopPropagation())
      return node;
    }
  } catch (error) {
  }
  node.setAttribute('href', 'javascript:;');
  return node;
}

export const createFormatLink = (Quill) => {
  const customLink = Quill.import('formats/custom-link');
  if (customLink) return;
  const Link = Quill.import('formats/link');
  class CustomeLink extends Link {
    static create (value) {
      const node = super.create(value);
      return handleValueString.call(this, node, value);
    }
  }
  CustomeLink.blotName = 'link';
  CustomeLink.tagName = 'A';
  CustomeLink.SANITIZED_URL = 'about:blank';
  CustomeLink.PROTOCOL_WHITELIST = ['http', 'https'];
  Quill.register({
    'formats/custom-link': CustomeLink
  })
}
