import { connect } from 'getstream';

import { config, getJwtToken } from '@utils/index';

const defaultOptions = {
  urlOverride: {
    api: config.api.social_host || ''
  },
  timeout: 10000,
  browser: true,
  version: 'v2'
};

const getClient = (token?: string) => {
  try {
    const jwtToken = token || getJwtToken();
    const client = connect(
      'key',
      jwtToken ? `Bearer ${jwtToken}` : null,
      'app_id',
      defaultOptions
    );

    return client
  } catch (e) {
    console.error(e);
  }
};

export { getClient }
