const HOST = process.env.NEXT_PUBLIC_TREX_HOST_API;
const SOCIAL_HOST = process.env.NEXT_PUBLIC_TREX_SOCIAL_HOST_API;
const UPLOAD_API = process.env.NEXT_PUBLIC_TREX_UPLOAD_API;
export const config = {
  // host_api: 'https://webbeta.kikitrade.com/newapi/',
  api: {
    host: HOST,
    social_host: SOCIAL_HOST,
    // upload_api: 'https://upload.dipbit.xyz/',
    upload_api: UPLOAD_API,
    user: {
      login: 'customer/login/oauth',
      logout: 'customer/logout',
      updateUserInfo: 'customer',
      getUserInfo: 'customer',
      getSocialUserInfo: 'v2/user/{id}',
      modifySocialUserInfo: 'v2/user'
    },
    feed: {
      getFeedList: 'v2/feed/{feed_slug}/{resource_id}', // 回答列表
      answerDetail: 'v2/feed/{feed_slug}/{resource_id}/{view_id}', // 回答详情
      postAnswer: 'v2/feed/user/resource', // 回答问题
      getFollowerList: 'v2/feed/{feed_slug}/{user_id}/follower', // 粉丝列表
      getFollowingList: 'v2/feed/{feed_slug}/{user_id}/following', // 关注列表
      getDynamic: 'v2/dynamic' // 个人主页动态
    },
    collection: {
      questionList: 'v2/collection/question', // 问题列表
      questionDetail: 'v2/collection/question/{id}', // 问题详情
      postQuestion: 'v2/collection/{category}' // 创建问题
    },
    reaction: {
      fetchReaction: 'v2/reaction/{id}',
      addReaction: 'v2/reaction',
      deleteReaction: 'v2/reaction/{type}/{id}/{kind}'
    },
    oss: {
      ossKey: 'v1/customer/credential/token'
    },
    community: {
      communityList: 'v2/communities',
      communityDetail: 'v2/communities/{id}'
    },
    achievement: {
      achievement: 'v1/achievement', // 个人成就
      member: 'v1/member/points/ledgers/{type}', // 积分流水
      task: 'v1/member/task/{type}', // 任务列表
      receive: 'v1/member/task/{taskId}/receive' // 领取任务
    }
  }
};
