import defaultTheme from './default';
class Theme {
  static instance: Theme | null;
  public theme: Object = {};
  constructor() {
    this.theme = {
      default: defaultTheme
    }
  }
  load(theme) {
    Object.keys(theme).forEach(key => {
      this.theme[key] = {
        ...this.theme[key],
        ...theme[key]
      }
    })
  }
  update(type) {
    // const obj = Object.assign(this.theme['default'], this.theme[type]);
    const obj = {
      ...this.theme['default'],
      ...this.theme[type]
    }
    for (const key in obj) {
      document
        .getElementsByTagName('body')[0]
        .style.setProperty(`--${key}`, obj[key]);
    }
  }
  static getInstance() {
    if (!this.instance) {
      this.instance = new Theme();
    }
    return this.instance;
  }
}

export default Theme.getInstance();