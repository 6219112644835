import cookie from 'js-cookie'

import { config, mapToQueryString, request } from '@utils/index';

import { getClient } from './stream';
const getUserId = () => {
  return JSON.parse(localStorage.getItem('userInfo') || '')?.id;
}
/** 登录 */
export const login = (params) => {
  const URL = `${config.api.host}${config.api.user.login}?${mapToQueryString(params ?? {})}`;
  return request.post.call({}, URL);
};

/** 登出 */
export const logout = () => {
  const URL = `${config.api.host}${config.api.user.logout}`;
  return request.post.call({}, URL);
};

/** 更新用户信息 */
export const updateUserInfo = (params) => {
  const URL = `${config.api.host}${config.api.user.updateUserInfo}?${mapToQueryString(params ?? {})}`;
  return request.post.call({}, URL);
};

/** 获取用户信息 */
export const getUserInfo = () => {
  const URL = `${config.api.host}${config.api.user.getUserInfo}`;
  return request.get.call({}, URL);
};

/** 获取用户的社区信息 */
export const getSocialUserInfo = (socialUserId?: string, withRelations?: Array<string>) => {
  const id = socialUserId || cookie.get('userId') || '';
  const query = {
    user_id: id,
    with_relations: withRelations
  }
  const URL = `${config.api.social_host}${config.api.user.getSocialUserInfo.replace(/{id}/, id)}?${mapToQueryString(query)}`;
  return request.get.call({}, URL);
};

/** 更新用户的社区信息 */
export const modifyFerchSocialUserInfo = (params) => {
  console.log(params, 'paramsparams')
  const URL = `${config.api.social_host}${config.api.user.modifySocialUserInfo}`;
  return request.post.call({}, URL, {}, {}, params);
};

// 关注用户
export const followUser = async (targetId?: string) => {
  return getClient()?.feed('USER', getUserId()).follow('USER', `${targetId}_FOLLOW` as string) as unknown as any;
};

// 取消关注用户
export const unFollowUser = async (targetId?: string) => {
  return getClient()?.feed('USER', getUserId()).unfollow('USER', `${targetId}_FOLLOW` as string) as unknown as any;
};
