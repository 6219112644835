import React, { useRef } from 'react';

import { Title } from 'atom-components';
import { AvatarText, Button } from 'base-components';
import cn from 'classnames';
import { isValidSourceTRex } from 'utils';

import I18n from './i18n';
import { AnswerNum } from './index';
import { AnswerItemProps } from './type';

import styles from './answerItem.module.less';
export const AnswerItem: React.FC<AnswerItemProps> = (props) => {
  const {
    avatar,
    customUserInfo,
    answerDesc,
    title,
    created,
    name,
    activityCount,
    questionId,
    handleItemClick,
    className,
    onClickAnswer,
    customerId,
    linkTo,
    isFocus,
    nickName,
    avatarTextClass
  } = props;
  const refDiv = useRef<any>();

  const handleClickAnswer = (e) => {
    if (typeof onClickAnswer === 'function') {
      e.stopPropagation();
      e.preventDefault();
      onClickAnswer();
    }
  };

  return (
    <div onClick={() => { handleItemClick(props); }} className={cn(styles.container, className)}>
      <div className={styles.main}>
        {customUserInfo || <AvatarText
          avatar={isValidSourceTRex(avatar, 'image') ? avatar : ''}
          customerId={customerId}
          linkTo={linkTo}
          userName={
            <div className={cn({ [styles.username]: isFocus })}>
              <div className={styles.name}>{nickName}</div>
              <span>{I18n.t('questionDesc')}</span>
            </div>
          }
          nickName={isFocus ? <div>{name}</div> : ''}
          isSquare={isFocus}
          avatarSize={isFocus ? 40 : 20}
          userInfoClassName={cn(styles.avatar, avatarTextClass)}
        />}
        <div className={cn(styles.row2, { [styles.customUserInfo]: !!customUserInfo })}>
          <Title className={styles.question}>{title}</Title>
          <Button
            type="primary"
            className={styles.answerBtnBox}
            btnClassName={styles.answerBtn}
            size="small"
            onClick={handleClickAnswer}
          >
            {I18n.t('answerText')}
          </Button>
        </div>
        <AnswerNum
          created={created}
          answerDesc={answerDesc}
          activityCount={activityCount}
          handleClickAnswer={handleClickAnswer}
          questionId={questionId}
        />
      </div>
      <div ref={refDiv}></div>
    </div>
  );
};
