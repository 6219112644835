import { useContext, useState } from 'react';

import { message } from 'base-components'
import { pubsub } from 'utils';
import I18n from 'widgets/add-answer/utils/i18n';

import { followCommunity, unFollowCommunity } from '@api/index';
import { FOLLOW_COMMUNITY_SUCCESS } from '@constants/index'
import { type IWeb3Auth, TRexContext } from '@hooks/index';
import { handleNoLogin, noLoginCodeEum } from '@utils/index';

export const useFollowCommunity = (web3Auth: IWeb3Auth) => {
  const [loading, setLoading] = useState(false);
  const { state: { isLogin } } = useContext(TRexContext);
  const successPubsub = (targetId, isJoin) => {
    pubsub.publish(FOLLOW_COMMUNITY_SUCCESS, {
      communityId: targetId,
      isJoinNew: isJoin
    })
  }

  const followFun = async (targetId) => {
    setLoading(true)
    try {
      const res = await followCommunity(targetId)
      if (res.success) {
        successPubsub(targetId, true);
        message.success(I18n.t('operationSuccess'));
        setLoading(false);
        return true;
      } else {
        if (res.code === '1003' || res.code === '999') {
          message.error(I18n.t(res.msg));
        } else if (res.code === noLoginCodeEum.ksocial) {
          handleNoLogin();
        } else {
          message.error(I18n.t('operationFailure'));
        }
        setLoading(false)
        return false;
      }
    } catch (e) {
      setLoading(false)
      console.log('follow-Error:::', e)
    }
  }

  const unFollowFun = async (targetId) => {
    setLoading(true)
    try {
      const res = await unFollowCommunity(targetId)
      if (res.success) {
        successPubsub(targetId, false);
      } else {
        if (res.code === 1007) {
          // not.support.remove.relation 板块创建人不能取关
          message.error(I18n.t(res.msg));
        } else if (res.code === noLoginCodeEum.ksocial) {
          handleNoLogin()
        } else {
          message.error(I18n.t('operationFailure'))
        }
      }
    } catch (e) {
      console.log('unfollow-Error:::', e)
    }
    setLoading(false)
  }

  const onFollow = async (isJoin, targetId) => {
    if (!isLogin) {
      web3Auth?.current?.handleLogin?.();
      return;
    }
    if (isJoin) {
      unFollowFun(targetId)
    } else {
      return await followFun(targetId);
    }
  };

  return {
    onFollow,
    loading
  }
};
