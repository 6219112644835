import floor from 'lodash/floor'
import truncate from 'lodash/truncate';

/** 将 base64 转为 blob */
export const dataURLtoBlob = (dataurl: string) => {
  const arr = dataurl.split(',');
  const mime = arr?.[0]?.match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const blob = new Blob([u8arr], { type: mime });
  return blob;
}
/** 图片转base64 */
export const imageToBase64 = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const image = e.target?.result as string;
      resolve(image)
    };
    reader.readAsDataURL(file);
  })
}

/** 对File类型的图片进行压缩 */
export const compress = (file: File, ImageCompressor): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const M = 1024 * 1024;
    let quality = 1;
    quality = file.size <= M ? quality : floor(M / file.size, 3);
    const options = {
      file,
      mimeType: file.type,
      quality,
      success: (res) => {
        resolve(res);
      }
    }
    // eslint-disable-next-line
    new ImageCompressor(options);
  })
}

/** 获取图片文件的base64 会对图片进行压缩 */
export const getBase64ByImage = async (file, ImageCompressor) => {
  const fileBlob = await compress(file, ImageCompressor);
  const base64 = await imageToBase64(fileBlob);
  return base64;
}

/** 对超出限制长度的文字添加... */
export const addEllipsisToStr = (text: string, maxLength: number) => {
  return truncate(text, { length: maxLength })
}
