

export const formatThousandthsNum = (num: number = 0) => {
  if (num < 10000) {
    return `${num}`
  } else {
    const tempNum = Math.floor(num / 100);
    let result = tempNum / 10 + ''
    if (tempNum % 10 === 0) {
      result = result + '.0'
    }
    return `${result}k`
  }
}
