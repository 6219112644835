export enum SPIN_STATUS {
  /** 数据加载成功 正常渲染 */
  SUCCESS = 'success',
  /** 数据加载中 遮盖内容 显示 loading */
  LOADING = 'loading',
  /** 数据加载失败 遮盖内容 渲染错误状态 */
  ERROR = 'error',
  /** 没有数据 */
  EMPTY = 'empty'
}
