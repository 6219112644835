import { config, mapToQueryString, request } from '@utils/index';

import type {
  AnswerDetail,
  GetAnswerDetailReq,
  GetCommendListReq,
  GetCommendListRes,
  GetFollowerListReq,
  GetFollowerListRes
} from './feed.type';

/**
 * 获取回答列表
 */
export const getCommendList = (params: GetCommendListReq): Promise<APIResponse<GetCommendListRes>> => {
  const { slug, id, ...otherParams } = params;
  const URL = `${config.api.social_host}${config.api.feed.getFeedList.replace(/{feed_slug}/, slug).replace(/{resource_id}/, id)
  }?${mapToQueryString(otherParams ?? {})}`;
  return request.get.call({}, URL);
};

/**
 * 获取回答详情
 */
export const getAnswerDetail = (
  params: GetAnswerDetailReq
): Promise<APIResponse<AnswerDetail>> => {
  // eslint-disable-next-line camelcase
  const { feed_slug, resource_id, view_id, ...otherParams } = params;
  const URL = `${config.api.social_host}${config.api.feed.answerDetail
    .replace('{feed_slug}', feed_slug)
    .replace('{resource_id}', resource_id)
    .replace('{view_id}', view_id)
  }?${mapToQueryString(otherParams ?? {})}`;
  return request.get.call({}, URL);
};

/**
 * 获取Follower列表
 */
export const getFollowerList = (params: GetFollowerListReq): Promise<APIResponse<GetFollowerListRes>> => {
  const { slug, id, ...otherParams } = params;
  const URL = `${config.api.social_host}${config.api.feed.getFollowerList.replace(/{feed_slug}/, slug).replace(/{user_id}/, id)
  }?${mapToQueryString(otherParams ?? {})}`;
  return request.get.call({}, URL);
};

/**
 * 获取Following列表
 */
export const getFollowingList = (params: GetFollowerListReq): Promise<APIResponse<GetFollowerListRes>> => {
  const { slug, id, ...otherParams } = params;
  const URL = `${config.api.social_host}${config.api.feed.getFollowingList.replace(/{feed_slug}/, slug).replace(/{user_id}/, id)
  }?${mapToQueryString(otherParams ?? {})}`;
  return request.get.call({}, URL);
};
