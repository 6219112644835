import React, { useMemo, useState } from 'react';

import { useIsomorphicLayoutEffect } from 'hooks';

import { FeedImage } from '@/feed-image';

import type { OneImageProps } from './type';
import useClient from './useClient';

import styles from './index.module.less'
function getImageMode ({ src = '' }) {
  if (!src) return '';
  const img = new Image();
  img.src = src;
  return new Promise<string>((resolve) => {
    img.onload = function () {
      const width = img.width;
      const height = img.height;
      let mode = '';
      if (width / height < 0.9) {
        mode = 'vertical'; // 竖图
      }
      if (height / width < 0.9) {
        mode = 'horizontal'; // 宽图
      }
      resolve(mode);
    };
  });
}
// 单图的宽图定宽，长图定高

export const SingleImageAuto: React.FC<OneImageProps> = ({ imgSrc = '' }) => {
  const isPc = useClient(960);
  const [mode, setMode] = useState('');
  console.log('isPc', isPc);
  useIsomorphicLayoutEffect(() => {
    (async () => {
      const mode = await getImageMode({ src: imgSrc });
      setMode(mode);
    })();
  }, [imgSrc]);

  const size = useMemo(() => {
    const defaultSize = {
      width: 180,
      height: 180
    };
    if (mode === 'horizontal') {
      return { ...defaultSize, width: isPc ? 570 : '100%', height: 'auto' };
    }
    if (mode === 'vertical') {
      return { ...defaultSize, width: 'auto', height: isPc ? 400 : 200 };
    }
    return defaultSize;
  }, [isPc, mode]);

  return (
    imgSrc ? (
      <FeedImage
        src={imgSrc}
        width={size.width}
        height={size.height}
        className={styles.feedImage}
        preview={{
          maskClassName: 'image_borderRadius_5px'
        }}
      />
    ) : null
  );
};
