import React, { useState } from 'react';

import { More, Title } from 'atom-components';
import { AvatarText, Button, RichEditor } from 'base-components';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';
import { customizeRelativeTime, pubsub } from 'utils';

import { QuestionBaseInfoProps } from './';
import { useCeiling } from './useCeiling';

import styles from './index.module.less';

const BaseInfoComponent: React.FC<QuestionBaseInfoProps> = (props) => {
  const [moreState, setMoreState] = useState(true);
  const {
    title,
    lastUpdateTime,
    activityCount = 0,
    viewCount = 0,
    nickName,
    className,
    avatar,
    desc,
    mainRef,
    questionId,
    userId,
    linkTo,
    onClickAnswer,
    scrollEl
  } = props;
  const [answerCount, setAnswerCount] = useState(activityCount)
  const answerCountChange = (params) => {
    if (params.questionId === questionId) {
      setAnswerCount(answerCount + 1)
    }
  }

  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe('ADD_ANSWER', answerCountChange)
    return () => {
      pubsub.unSubscribe('ADD_ANSWER', answerCountChange);
    }
  }, [answerCount]);
  useCeiling({ mainRef, scrollEl });
  console.log('line47', userId, linkTo, scrollEl);
  return (
    <div className={cn(styles.container, className)} ref={mainRef}>
      <div>
        <div className={styles.avatarContainer}>
          <AvatarText
            linkTo={linkTo}
            customerId={userId}
            className={styles.avatar}
            avatar={avatar}
            userInfoClassName={styles.userInfo}
            avatarSize={20}
            userName={<div className={styles.nickName}>{nickName}</div>} />
          <div className={styles.viewNum}>
            <span>{viewCount} Views</span>
          </div>
        </div>
        <Title className={cn(styles.title, 'openSansSemiBold')}>
          {title}
        </Title>
      </div>
      <div className={styles.editorContainer}>
        {
          !!desc &&
          <RichEditor
            className={cn(styles.desc, {
              [styles.moreStateDesc]: moreState
            })}
            readOnly
            defaultValue={desc}
            maxLength={78}
            moreClass={cn(styles.editorMore, {
              [styles.editorMoreAbsolute]: moreState
            })}
            more={<More isExpand={false} />}
            hide={<More isExpand={true} />}
            hideChange={setMoreState}
          />
        }
      </div>

      <div className={styles.answer}>
        <span className="openSansSemiBold">{answerCount} Answers</span>
        {
          !!lastUpdateTime &&
          customizeRelativeTime(lastUpdateTime)
        }
      </div>
      <Button className={styles.button} onClick={onClickAnswer}>Answer</Button>
    </div>
  )
};

const QuestionBaseInfo: React.FC<QuestionBaseInfoProps> = React.memo(BaseInfoComponent);
export { QuestionBaseInfo };
