import React from 'react';

import { openModal } from 'base-components';

import { QuestionModal } from '../question-modal'

import styles from './index.module.less';

export const onOpenQuestionModal = async ({
  alioss,
  avatar,
  nickName,
  userName,
  customerId,
  onPost,
  isCache,
  communityListRequestApi,
  communityListQueryParams
}) => {
  const refData = {
    current: {
      dbData: {
        _rev: ''
      },
      title: '',
      community: {},
      description: '',
      descLength: ''
    }
  };

  const onClose = async () => {
    if (!isCache) {
      return
    }
    try {
      const { addData, updateBulletin } = await import('../utils');
      const dbData = refData?.current?.dbData
      const newData = {
        id: customerId,
        title: refData?.current?.title,
        community: refData?.current?.community,
        description: refData?.current?.description,
        descLength: refData?.current?.descLength
      }
      if (dbData?._rev) {
        updateBulletin({
          ...dbData,
          ...newData
        })
        return;
      }
      addData(newData)
    } catch (e) {
      console.log('QUESTION--DB--ADD--ERROR::', e)
    }
  }
  const res = await openModal({
    mask: true,
    maskClosable: false,
    closable: true,
    cardClass: styles.questionModalCard,
    closeClass: styles.questionClose,
    headerClass: styles.questionHeaderClass,
    className: styles.modal,
    onClose,
    children: (params) => (
      <QuestionModal
        avatar={avatar}
        nickName={nickName}
        userName={userName}
        alioss={alioss}
        refData={refData}
        onPost={onPost}
        communityListRequestApi={communityListRequestApi}
        communityListQueryParams={communityListQueryParams}
        {...params}
      />
    )
  });
  return res
}
