import { CommunityItem, getCommunityList } from '@api/index';
import { RELATION_TYPE } from '@constants/index';

import { usePagination } from '../';

const useRecommendCommunity = (props) => {
  console.log('lineProps', props);
  const params = usePagination<CommunityItem>({
    fetchData: getCommunityList,
    props: {
      ranking: 'ORDER',
      limit: 5,
      with_relations: [RELATION_TYPE.JOIN],
      exclude_own: true,
      exclude_ids: [props?.id]
    }
  });
  return params;
};

export { useRecommendCommunity };
