import bold from '../media/bold.svgxml';
import bullet from '../media/bullet.svgxml';
import em from '../media/em.svgxml';
import image from '../media/image.svgxml';
import link from '../media/link.svgxml';
import order from '../media/order.svgxml';
export const createUiIcon = (Quill) => {
  const icons = Quill.import('ui/icons');
  if (icons.customeIcon) return;
  icons.image = image;
  icons.link = link;
  icons.bold = bold;
  icons.italic = em;
  icons.list.ordered = order;
  icons.list.bullet = bullet;
  icons.customeIcon = true;
  Quill.register({
    'ui/icons': icons
  }, true)
}
