import cookie from 'js-cookie';

import { config, mapToQueryString, request } from '@utils/index';

import {
  GetCommunityDetailReq,
  GetCommunityDetailRes,
  GetCommunityListReq,
  GetCommunityListRes,
  PageResultResponse
} from './';
import { getClient } from './stream';

const DEFAULT_LIMIT = 5;

const getUserId = () => {
  return cookie.get('userId');
}

/** 关注的板块列表 */
export const getMyJoinCommunityList = async ({ nextToken, limit = DEFAULT_LIMIT, userId = getUserId() }:
  { nextToken?: string, limit?: number, userId?: string }) => {
  let curUserId = userId;
  if (!userId) {
    curUserId = getUserId();
  }
  if (!curUserId || !cookie.get('jwt')) return;
  const query = {
    limit,
    relation: 'JOIN',
    nextToken: nextToken || '',
    with_target: true,
    with_relations: 'JOIN'
  };
  return await getClient()?.feed('USER', userId)?.following(query) as unknown as PageResultResponse<GetCommunityListRes>;
};

// 关注板块
export const followCommunity = async (communityId?: string) => {
  return getClient()?.feed('USER', getUserId()).follow('COMMUNITY', `${communityId}_JOIN` as string) as unknown as any;
};

// 取消关注板块
export const unFollowCommunity = async (communityId?: string) => {
  return getClient()?.feed('USER', getUserId()).unfollow('COMMUNITY', `${communityId}_JOIN` as string) as unknown as any;
};

/** 获取板块列表(推荐/所有) */
export const getCommunityList = (
  params: GetCommunityListReq
): Promise<PageResultResponse<any>> => {
  const URL = `${config.api.social_host}${config.api.community.communityList
  }?${mapToQueryString(params ?? {})}`;
  return request.get.call({}, URL);
};

/** 获取板块详情 */
export const getCommunityDetail = (
  params: GetCommunityDetailReq
): Promise<APIResponse<GetCommunityDetailRes>> => {
  const URL = `${config.api.social_host}${config.api.community.communityDetail.replace(/{id}/, params.id)
  }?${mapToQueryString(params ?? {})}`;
  return request.get.call({}, URL);
};
