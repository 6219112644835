import React, { Fragment } from 'react';

import cn from 'classnames';

import type { CoreElProps } from './type';

import styles from './editorEl.module.less';

export const CoreEl: React.FC<CoreElProps> = (props) => {
  const { toolbarRef, toolbarClass, readOnly, className, editorDivRef } = props;
  return (
    <Fragment>
      <div ref={toolbarRef} className={cn(toolbarClass, {
        [styles.hidden]: readOnly
      })} >
        <button className="ql-image" type="button"></button>
        <button className="ql-link"></button>
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
      </div>
      <div className={cn(className)}>
        <div
          ref={editorDivRef}
        />
      </div>
    </Fragment>
  )
}
