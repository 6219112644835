import React from 'react';

import { Button, openModal } from 'base-components';
import cn from 'classnames';
import Image from 'next/image';

import I18n from './i18n';

import styles from './logout.module.less';

interface IProps {
  reject: <T = unknown>(reason?: T) => void;
  resolve: <P = unknown>(value?: P) => void;
  close: () => void;
}

const ConfirmModal: React.FC<IProps> = (props) => {
  const { resolve, close, className } = props;

  const handleConfirm = async () => {
    resolve(true);
    close();
  };
  const handleCancel = () => {
    close();
  };
  return (
    <div className={cn(styles.container, className)}>
      <Image
        alt="TRex"
        className={styles.image}
        src={require('./media/image1.webp')}
      />
      <div className={styles.info}>
        <div className={styles.title}>{I18n.t('title')}</div>
        <div className={styles.desc}>{I18n.t('dec')}</div>
      </div>
      <Button
        type="default"
        size="large"
        onClick={handleConfirm}
        className={styles.button}
      >
        {I18n.t('confirm')}
      </Button>
      <Button
        type="primary"
        size="large"
        onClick={handleCancel}
        className={styles.button}
      >
        {I18n.t('cancel')}
      </Button>
    </div>
  );
};

const openLogoutModal = async () => {
  return openModal({
    mask: true,
    closable: true,
    closePosition: 'right',
    cardClass: styles.modalCard,
    children: (params) => <ConfirmModal {...params} />
  });
};

export { openLogoutModal };
