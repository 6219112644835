import { Dispatch } from 'react';

import type { ISocialUserInfo, IUserInfo } from '@api/index'

export type IState = {
  walletAddress: string;
  userInfo: IUserInfo;
  socialUserInfo: ISocialUserInfo;
  isLogin: boolean;
}
export enum IActionEum {
  SET_USERINFO = 'SET_USERINFO',
  SET_SOCIAL_USERINFO = 'SET_SOCIAL_USERINFO',
  UPDATE_USERINFO = 'UPDATE_USERINFO',
  UPDATE_SOCIAL_USERINFO = 'UPDATE_SOCIAL_USERINFO',
  UPDATE_WALLET_ADDRESS = 'UPDATE_WALLET_ADDRESS',
  REMOVE_USERINFO = 'REMOVE_USERINFO'
}

export type IAction = {
    type: IActionEum;
    payload?: any;
}
export type IContext = {
  state: IState;
  dispatch: Dispatch<IAction>;
}

export type IReduce = (state: IState, action: IAction) => Partial<IState>;
