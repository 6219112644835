import { useState } from 'react'

import { useDebounce, useIsomorphicLayoutEffect } from 'hooks';
const useClient = (number) => {
  const [isPc, setIsPc] = useState<string|boolean>('')
  const updateIsPc = () => {
    setIsPc(document.body.clientWidth > number)
  }
  const isPcFunc = useDebounce(updateIsPc, 500)
  useIsomorphicLayoutEffect(() => {
    updateIsPc()
    window.addEventListener('resize', isPcFunc)
    return () => window.removeEventListener('resize', isPcFunc)
  }, [])
  return isPc
}

export default useClient
