import React, { useCallback, useState } from 'react';

import { TabPane, Tabs } from 'base-components/index';
import cn from 'classnames'
import { useIsomorphicLayoutEffect } from 'hooks'
import { formatThousandthsNum, pubsub } from 'utils';

import { FOLLOW_TAB_KEY } from '../const'
import { FollowersTab } from '../followers-tab'
import I18n from '../i18n';
import { FollowersModalProps } from './type'

import styles from './FollowersModal.module.less';
export const FollowersModal: React.FC<FollowersModalProps> = (props) => {
  const {
    id, isMySelf, followerCount,
    requestFollowerApi, requestFollowingApi,
    useFollowUser, getProfileLink
  } = props;
  const [activeKey, setActiveKey] = useState(props.activeKey || FOLLOW_TAB_KEY.FOLLOWERS);
  const [followingCount, setFollowingCount] = useState(props.followingCount);

  const onChange = (activeKey) => {
    setActiveKey(activeKey)
  }

  const onFollowChange = useCallback((params) => {
    if (isMySelf) {
      // 自己的个人主页，在关注、粉丝弹窗里点关注、取关，对应数字处理
      setFollowingCount(params.isFollowNew ? followingCount + 1 : followingCount - 1)
    }
  }, [isMySelf, followingCount])

  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe('FOLLOW_USER_SUCCESS', onFollowChange);
    return () => {
      pubsub.unSubscribe('FOLLOW_USER_SUCCESS', onFollowChange);
    };
  }, [id, isMySelf, followingCount]);

  return (
    <div className={cn(styles.container)}>
      <Tabs
        type="line"
        onChange={onChange}
        activeKey={activeKey}
        className={styles.tabWrapper}
      >
        <TabPane
          key={FOLLOW_TAB_KEY.FOLLOWERS}
          tab={<h2>{`${formatThousandthsNum(followerCount)} ${I18n.t('followers')}`}</h2>}
        >
          <FollowersTab
            id={id}
            activeKey={activeKey}
            requestApi={requestFollowerApi}
            useFollowUser={useFollowUser}
            getProfileLink={getProfileLink}
          />
        </TabPane>
        <TabPane
          key={FOLLOW_TAB_KEY.FOLLOWING}
          tab={<h2>{`${formatThousandthsNum(followingCount)} ${I18n.t('following')}`}</h2>}
        >
          <FollowersTab
            id={id}
            activeKey={activeKey}
            requestApi={requestFollowingApi}
            useFollowUser={useFollowUser}
            getProfileLink={getProfileLink}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};
