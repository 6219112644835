import React from 'react';

import { type ButtonProps, Button as AntdButton, ConfigProvider } from 'antd';
import cn from 'classnames';

import styles from './index.module.less';

export interface IButtonProps extends ButtonProps{
  btnClassName?: string,
  colorType?: 'black' | 'green' | 'white',
  customizeSize?: 'xsmall' | 'default';
}

export const Button: React.FC<IButtonProps> = (
  props
) => {
  const {
    block,
    children,
    className,
    btnClassName,
    colorType = 'green',
    customizeSize = 'default',
    ...otherProps
  } = props;
  return (
    <ConfigProvider autoInsertSpaceInButton={false}>
      <div className={cn(styles.button, {
        [styles.blockBtn]: block,
        [styles.BlackColorType]: colorType === 'black',
        [styles.xsmall]: customizeSize === 'xsmall'
      }, className)}>
        <AntdButton {...otherProps} className={btnClassName}>
          {children}
        </AntdButton>
      </div>
    </ConfigProvider >
  );
};
