import { CommunityItem, getMyJoinCommunityList } from '@api/index';

import { usePagination } from '../';

const useMyJoinCommunity = (props) => {
  console.log('line6', props);
  const params = usePagination<CommunityItem>({
    fetchData: getMyJoinCommunityList,
    props: {
      userId: props.userId
    }
  });
  return params;
};

export { useMyJoinCommunity };
