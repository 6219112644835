import React from 'react';

import { Empty, ScrollLoading, SPIN_STATUS, useGetScrollList } from 'base-components';
import { useIsomorphicLayoutEffect } from 'hooks'

import { ListItem } from '../list-item';
import type { CommunityListProps } from './type'

import styles from './index.module.less'

export const CommunityList: React.FC<CommunityListProps> = (props) => {
  const {
    setCommunity, close,
    communityListRequestApi,
    communityListQueryParams
  } = props;
  const {
    status,
    fetch,
    rows,
    nextToken
  } = useGetScrollList({
    requestApi: communityListRequestApi,
    queryParams: communityListQueryParams
  })

  useIsomorphicLayoutEffect(() => {
    fetch()
  }, []);

  const onScroll = () => {
    if (!nextToken.current) {
      return;
    }
    fetch();
  };

  if (status === SPIN_STATUS.SUCCESS && rows.length === 0) {
    return <Empty className={styles.empty} />
  }

  return (
    <div className={styles.communityListContainer}>
      <ScrollLoading
        invisibleHeight={50}
        status={status}
        fetchData={onScroll}
        noMoreShow={!nextToken.current && rows.length !== 0}
      >
        {(rows || []).map(item => {
          const { id, title, icon } = item;
          return (
            <ListItem
              close={close}
              key={id}
              id={id}
              icon={icon}
              title={title}
              setCommunity={setCommunity}
            />
          )
        })}
      </ScrollLoading>

    </div>
  )
}
