import React from 'react';

import cn from 'classnames';

import { HeaderBgProps } from './index';

import styles from './headerBg.module.less';

export const HeaderBg: React.FC<HeaderBgProps> = (props) => {
  const { children, className, bg, rootClass, bgNode, onBgClick } = props;
  return (
    <div className={rootClass}>
      <div
        className={cn(styles.container, className)}
        style={{
          backgroundImage: `url(${bg})`
        }}
        onClick={() => onBgClick?.(bg)}
      >
        {bgNode}
      </div>
      <div className={cn(styles.childrenContainer)}>
        {children}
      </div>
    </div>
  );
};
