import React from 'react';

import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';

import { If } from '@/condition-render';

import { Identification } from './identification';
import type { AvatarProps } from './type';

import styles from './index.module.less';

const BaseAvatar: React.FC<AvatarProps> = (props) => {
  const {
    defaultAvatar,
    avatar,
    avatarSize,
    identificationSource,
    identificationSize,
    className,
    iconClassName,
    identificationClassName,
    children,
    borderRadius
  } = props;

  return (
    <div
      className={cn(styles.container, className)}
      data-buried="avatar"
    // style={{
    //   width: `${avatarSize}px`,
    //   height: `${avatarSize}px`
    // }}
    >
      {(avatar || defaultAvatar) && (
        <Image
          src={avatar || defaultAvatar || ''}
          width={avatarSize}
          height={avatarSize}
          loading={'eager'}
          className={cn(`image_borderRadius_${borderRadius ?? avatarSize}px`, iconClassName)}
          layout="fixed"
          alt={'avatar'}
        />
      )}
      <Identification
        className={cn(styles.identification, identificationClassName)}
        identificationSource={identificationSource}
        size={identificationSize}
      />
      {children}
    </div>
  );
};

const AvatarComponent: React.FC<AvatarProps> = (props) => {
  const {
    defaultAvatar,
    avatar,
    avatarSize = 40,
    identificationSource,
    identificationSize = 14,
    customerId,
    linkTo,
    children,
    className,
    iconClassName,
    borderRadius,
    onLinkClick = () => {}
  } = props;
  console.log(linkTo, 'avatat line 72 linkTolinkTo', !linkTo && !customerId)
  return (
    <If
      condition={!linkTo && !customerId}
      true={
        <BaseAvatar
          defaultAvatar={defaultAvatar}
          avatar={avatar}
          avatarSize={avatarSize}
          identificationSource={identificationSource}
          identificationSize={identificationSize}
          className={className}
          iconClassName={iconClassName}
          borderRadius={borderRadius}
        >
          {children}
        </BaseAvatar>
      }
      false={
        <span onClick={(e) => e.stopPropagation()}>
          <Link href={`${linkTo}${customerId || ''}`} className={styles.link} onClick={onLinkClick}>
            <BaseAvatar
              defaultAvatar={defaultAvatar}
              avatar={avatar}
              avatarSize={avatarSize}
              identificationSource={identificationSource}
              identificationSize={identificationSize}
              className={className}
              iconClassName={iconClassName}
              borderRadius={borderRadius}
            />
            {children}
          </Link>
        </span>
      }
    />
  );
};

export default AvatarComponent;
