import React, { useMemo } from 'react';

import { Button } from 'base-components';
import cn from 'classnames';
import { useClient } from 'hooks';

import I18n from '../i18n';
import { RenderBtnProps } from './type'

import styles from './RenderBtn.module.less';

export const RenderBtn: React.FC<RenderBtnProps> = (props) => {
  const {
    isMySelf,
    className,
    isFollow,
    loading,
    customizeSize,
    handleEditProfile,
    handleFollow
  } = props;
  const isPc = useClient(576);

  const handleClick = !isMySelf ? handleFollow : handleEditProfile

  const btnText = useMemo(() => {
    return isMySelf
      ? I18n.t('editProfile')
      : isFollow ? I18n.t('following') : I18n.t('follow')
  }, [isMySelf, isFollow])

  const size = isPc ? 'middle' : 'small'
  const colorType = isFollow ? 'black' : 'green';
  return (
    <div className={cn(styles.action, className)}>
      <Button
        type="primary"
        ghost={isMySelf}
        colorType={colorType}
        size={size}
        disabled={loading}
        onClick={handleClick}
        customizeSize={customizeSize}
      >
        {btnText}
      </Button>
    </div>
  );
};
