import isUrl from 'is-url';

function hide () {
  this.textbox.href = '';
  this.namebox.href = '';
  this.hide();
}
// 编辑链接
function edit (params, Delta) {
  const { index, length, text, href } = params;
  const delta = this.quill.getContents(index, length);
  let detail = new Delta().retain(index);
  detail = detail.delete(length);
  const attributes = delta.ops[0]?.attributes || {};
  let link;
  try {
    link = isUrl(href) ? href : undefined
  } catch (error) {
  }
  const content = text || href;
  detail = detail.insert(content, { ...attributes, link });
  this.quill.updateContents(detail, 'user');
  this.quill.setSelection(index + content?.length, 'silent')
}

export const editorLinkSave = (editor, Delta) => {
  const save = editor.theme.tooltip.save;
  /** 这里特意选用function定义函数，为了拿到编辑器this */
  editor.theme.tooltip.save = function (params) {
    const href = params?.href ?? this.textbox.value;
    const type = params?.type ?? this.root.getAttribute('data-mode');
    if (type !== 'link') {
      save.call(this)
      return;
    }
    const name = params?.value ?? this.namebox.value;
    if (this.linkRange) {
      edit.call(this, { ...this.linkRange, text: name, href }, Delta)
      delete this.linkRange;
      return hide.call(this)
    }
    const range = this.quill.getSelection(true);
    if (range) {
      edit.call(this, { ...range, text: name, href }, Delta);
      return hide.call(this)
    }
    hide.call(this)
  }
}
