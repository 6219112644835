import { useContext, useRef, useState } from 'react';

import { message } from 'base-components'
import { useIsomorphicLayoutEffect } from 'hooks';

import { GetCommunityListRes, PageResultResponse } from '@api/index';
import { TRexContext } from '@hooks/index';
import { handleNoLogin, I18n, noLoginCodeEum } from '@utils/index';

export const usePagination = <T extends {}>({
  fetchData,
  props
}: {
  props?: any;
  fetchData: (param: any) => Promise<PageResultResponse<GetCommunityListRes>| undefined>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [preDisabled, setPreDisabled] = useState<boolean>(false);
  const { state: { isLogin } } = useContext(TRexContext);
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);
  const [dataList, setDataList] = useState<Array<T>>([]);
  const [cacheList, setCacheList] = useState<any>([]);
  const cacheListRef = useRef<any>([]);
  const cacheRef = useRef<Record<number, T[]>>({});
  const nextTokenRef = useRef<string | undefined>();
  const pageNumRef = useRef<number>(0);

  useIsomorphicLayoutEffect(() => {
    cacheRef.current = [];
    cacheListRef.current = [];
    nextTokenRef.current = '';
    setCacheList([]);
    loadData({ init: true, pageNum: 0 });
    // props?.userId 个人主页发生变化的时候，重新渲染板块区域
  }, [isLogin, props?.userId]);

  const loadData = async ({ pageNum = 0, init = false } = {}) => {
    try {
      let rows = <any>[];
      if (cacheRef.current?.[pageNum] && !init) {
        rows = cacheRef.current[pageNum];
      } else {
        setLoading(true);
        const res = await fetchData({ nextToken: nextTokenRef.current, ...props });
        if (!res?.success) {
          // eslint-disable-next-line max-depth
          if (res?.code === noLoginCodeEum.ksocial) {
            handleNoLogin();
          }
          return;
        }
        const { obj } = res;
        const { next_token: nextToken } = obj || {};
        if ((!obj && cacheList.length) || (!obj?.rows.length && cacheList.length)) {
          message.info(I18n.t('lastPage'));
          setLoading(false);
          return;
        }
        nextTokenRef.current = nextToken;
        rows = obj?.rows;
      }
      setDataList(rows || []);
      pageNumRef.current = pageNum;
      console.log('line52', pageNum);
      setPreDisabled(pageNum === 0);
      setNextDisabled(!cacheRef.current?.[pageNum + 1] && !nextTokenRef.current);
      cacheRef.current[pageNum] = rows;
      const list = cacheListRef.current.concat(rows);
      cacheListRef.current = list;
      setCacheList(list);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const loadPre = () => {
    if (preDisabled) {
      return;
    }
    loadData({ pageNum: pageNumRef.current - 1 });
  };

  const loadNext = () => {
    if (nextDisabled) {
      return;
    }
    loadData({ pageNum: pageNumRef.current + 1 });
  };

  return {
    loading,
    preDisabled,
    nextDisabled,
    dataList,
    cacheList,
    loadPre,
    loadNext
  };
};
