import { useState } from 'react'

import { useDebounce } from './useDebounce';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

export const useClient = (clientWidth = 960) => {
  const [isPc, setisPc] = useState(false);
  const updateIsPc = () => {
    setisPc(document.body.clientWidth > clientWidth);
  };
  const isPcFunc = useDebounce(updateIsPc, 500);
  useIsomorphicLayoutEffect(() => {
    updateIsPc()
    window.addEventListener('resize', isPcFunc)
    return () => window.removeEventListener('resize', isPcFunc)
  }, [])
  return isPc
}
