import React, { useCallback, useState } from 'react';

import { AvatarText, Button } from 'base-components';
import cn from 'classnames';
import { useClient, useIsomorphicLayoutEffect } from 'hooks';
import cookie from 'js-cookie';
import { pubsub } from 'utils';

import I18n from '../i18n';
import { FollowerItemProps } from './type';

import styles from './FollowerItem.module.less';

export const FollowerItem: React.FC<FollowerItemProps> = (props) => {
  const {
    id,
    avatar,
    intro,
    nickName,
    uniqueName,
    relations,
    useFollowUser,
    linkTo
  } = props

  const { onFollow, loading: following = false } = useFollowUser?.();
  const [isFollow, setIsFollow] = useState(relations.some(i => i === 'FOLLOW'))
  const ownId = cookie.get('userId');
  const isPc = useClient(576);

  const onFollowChange = useCallback((params) => {
    if (params.targetUserId === id) {
      setIsFollow(params.isFollowNew)
    }
  }, [id])

  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe('FOLLOW_USER_SUCCESS', onFollowChange);
    return () => {
      pubsub.unSubscribe('FOLLOW_USER_SUCCESS', onFollowChange);
    };
  }, [id]);

  const colorType = isFollow ? 'black' : 'green';
  const btnText = isFollow ? I18n.t('following') : I18n.t('follow')
  const showBtn = id !== ownId; // 信息是自己不显示按钮

  return (
    <div className={cn(styles.container)}>
      <AvatarText
        avatarSize={isPc ? 48 : 40}
        avatar={avatar}
        linkTo={linkTo}
        customerId={''}
        nickName={<span className={cn(styles.nameRow)}>
          <span className={cn(styles.nickName, 'openSansSemiBold')}>{nickName}</span>
          <span className={cn(styles.uniqueName)}>{uniqueName}</span>
          </span>}
        userName={<span className={styles.intro}>{intro || I18n.t('no_intro')}</span>}
        userInfoClassName={styles.userInfo}
        className={styles.avatarText}
      />
      {
        showBtn && (<Button
          type="primary"
          size='small'
          colorType={colorType}
          btnClassName={styles.followBtn}
          onClick={() => { onFollow(isFollow, id) }}
          disabled={following}
        >
          {btnText}
        </Button>)
      }
    </div>
  );
};
