import React, { useRef, useState } from 'react';

import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';

import { Button, Input } from '@'

import I18n from '../i18n'
import type { H5LinkModalProps } from './type';

import styles from './H5LinkModal.module.less';

export const H5LinkModal: React.FC<H5LinkModalProps> = (props) => {
  const {
    className,
    resolve,
    close
  } = props;
  const [link, setLink] = useState('')
  const [linkName, setLinkName] = useState('')
  const inputRef = useRef<any>(null);

  const onDone = () => {
    const params = {
      type: 'link',
      href: link,
      value: linkName
    }
    resolve(params);
    close();
  }

  const onLinkChange = (e) => {
    setLink(e.target.value)
  };

  const onLinkNameChange = (e) => {
    setLinkName(e.target.value)
  };

  useIsomorphicLayoutEffect(() => {
    inputRef?.current!.focus({
      cursor: 'start'
    })
    const u = navigator.userAgent;
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isIOS) {
      window.scrollTo(0, document.body.clientHeight - 304 - 100)
    }
  }, [])

  return (
    <div className={cn(styles.container, className)}>
      <Input
        inputRef={inputRef}
        placeholder={I18n.t('linkPlaceHolder')}
        value={link}
        onChange={onLinkChange}
        className={styles.linknameInput}
      />
      <Input
        placeholder={I18n.t('linkNamePlaceHolder')}
        value={linkName}
        onChange={onLinkNameChange}
        className={styles.linknameInput}
      />
          <Button
            type="primary"
            size="middle"
            className={styles.doneBtn}
            onClick={onDone}
          >
            {I18n.t('done')}
          </Button>
    </div>
  );
};
