type IParams = {
  [key: string]: any
}
export const mapToQueryString = (map: IParams) => {
  const v: any[] = [];
  Object.keys(map).forEach((value: string, index, array) => {
    if (typeof map[value] !== 'undefined') {
      if (Array.isArray(map[value])) {
        // 兼容array类型参数only 使用后端为spring java的类型
        v.push(value + '=' + map[value]?.join?.(','));
      } else {
        const vv = map[value];
        v.push(encodeURI(value) + '=' + (vv ?? ''));
      }
    }
  });
  return v.join('&');
}
