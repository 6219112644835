import React, { memo, useRef } from 'react'

import { More } from 'atom-components'
import cn from 'classnames'

import { useMore } from './useMore'

import styles from './TextMore.module.less'

interface TextMoreProps {
  className?: string,
  children: React.ReactNode
}

const T:React.FC<TextMoreProps> = ({ children, className = '' }) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const { onClick, hasMore, isMore } = useMore(contentRef)

  return (
    <div className={cn(styles.contain, className)}>
      <div className={cn(styles.text)} ref={contentRef}
        style={{
          WebkitLineClamp: isMore ? 'unset' : 2
        }}
      >
        {children}
      </div>
      {
        hasMore && <More onClick={onClick} isExpand={isMore}/>
      }
    </div>
  )
}
export const TextMore = memo(T)
