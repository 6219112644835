import React from 'react';

import { AvatarText } from 'base-components';
import { useClient } from 'hooks';
import { isValidSourceTRex } from 'utils';

import type { AddBtnProps } from './type'

import styles from './index.module.less'

export const CommunityCard: React.FC<AddBtnProps> = (props) => {
  const isPc = useClient(576)
  const { community: { icon, title }, setCommunity } = props;
  const onDelet = () => {
    setCommunity(undefined)
  }

  const showIcon = isValidSourceTRex(icon, 'image') ? icon : '';

  return (
    <div className={styles.communityCard}>
      <AvatarText
        avatar={showIcon}
        avatarSize={isPc ? 32 : 28}
        borderRadius={8}
        userName={<span className={styles.communityName}>{title}</span>}
        className={styles.avatarText}
        userInfoClassName={styles.communityInfo}
      />
      <span className={styles.close} onClick={onDelet} />
    </div>
  )
}
