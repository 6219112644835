import React from 'react';

import { If } from 'base-components';
import cn from 'classnames';

import I18n from '@/add-question/utils/i18n';

import { CommunityItemProps } from '../type';
import { AddBtn } from './add-community';
import { CommunityCard } from './community-card';
import type { CommunityProps } from './type';

import styles from './index.module.less';

export const Community: React.FC<CommunityProps> = (props) => {
  const {
    community, setCommunity,
    communityListRequestApi,
    communityListQueryParams
  } = props;
  return (
    <div className={styles.communityContainer}>
      <div className={cn(styles.addTitle, 'openSansSemiBold')}>
        {I18n.t('add_community')}
      </div>
      <If
        condition={!!community?.id}
        true={
          <CommunityCard
            community={community as CommunityItemProps}
            setCommunity={setCommunity}
          />
        }
        false={
          <AddBtn
            setCommunity={setCommunity}
            communityListRequestApi={communityListRequestApi}
            communityListQueryParams={communityListQueryParams}
          />
        }
      />
    </div>
  );
};
