import React, { useRef, useState } from 'react';

import { Button, Popover } from 'antd';
import cn from 'classnames';

import type { SelectProps } from './select.type';
import { SelectContent } from './select-content';

import styles from './index.module.less';

export const Select: React.FC<SelectProps> = ({
  labelMode = 'common',
  popoverClass,
  popupContainer,
  className,
  renderValue,
  align = { offset: [0, 2] },
  label,
  mode = 'single',
  valueRender,
  selectBtnCalssName,
  options,
  value,
  onChange,
  ...rest
}) => {
  const [isExpand, setIsExpand] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const selectOnChange = (params) => {
    setIsExpand(false);
    onChange(params)
  }
  const getPopupContainer = (containerRef) => {
    return (triggerNode) => {
      return containerRef.current
    }
  }
  return (
    <div ref={containerRef} className={cn(
      styles.selectContainer,
      className,
      {
        [styles.responsiveContainer]: labelMode === 'responsive'
      }
    )}>
      <Popover
        trigger='click'
        placement="bottom"
        content={<SelectContent
          value={value}
          options={options}
          onChange={selectOnChange}
          {...rest}
        />}
        overlayClassName={cn(styles.selectPopover, popoverClass)}
        visible={isExpand}
        onVisibleChange={setIsExpand}
        destroyTooltipOnHide={true}
        align={align}
        getPopupContainer={
          popupContainer?.(containerRef) || getPopupContainer(containerRef)
        }
      >
        <Button className={cn(
          styles.selectButton,
          selectBtnCalssName,
          {
            [styles.selectButtonActive]: isExpand
          })}>
          {label && <span className={styles.label}>
            {label}
          </span>}
          <p className={cn(styles.value,
            {
              [styles.valueSpaceBetween]: !label
            })}
          >
            <span className={cn(styles.valueText)}>
              {valueRender?.(value) ||
                renderValue?.(options?.find?.(item => item.value === value)) ||
                options?.find?.(item => item.value === value)?.label ||
                value
              }
            </span>
            <span
              className={cn(
                styles.icon,
                styles.selectArrow,
                styles.iconDown,
                {
                  [styles.selectArrowUp]: isExpand
                })
              }
            />
          </p>
        </Button>
      </Popover>
    </div>
  )
}
