import React from 'react';

import cn from 'classnames';
import omit from 'lodash/omit';

import { ItemProps } from '../select.type';

import styles from '../index.module.less';

export const Item: React.FC<ItemProps> = (props) => {
  const {
    itemClassName,
    style,
    item,
    value,
    onChange,
    itemPrefix,
    itemSuffix
  } = props;
  const isActive = value === item.value;
  return (
    <li style={style} className={cn(styles.menuItem,
      itemClassName,
      item.className,
      {
        [styles.menuItemActive]: isActive
      })}
      onClick={
        () => onChange(omit(item, ['className', 'itemSuffix', 'itemPrefix']))
      }
      data-buired={item.dataBuried}
    >
      <p className={styles.content}>
        {item?.itemPrefix || itemPrefix}
        <span className={styles.label}>{item.label}</span>
      </p>
      {item?.itemSuffix || itemSuffix || (
        <span className={cn('icon', {
          'icon-check': isActive
        })} />
      )}
    </li>
  )
}
