import React from 'react';

import { FloatButton } from 'antd';
import cn from 'classnames';

import DefaultBackTop from './backTop.svg'
import type { IBackTopProps } from './type'

import styles from './index.module.less'

export const BackTop: React.FC<IBackTopProps> = (props) => {
  const { icon, className, ...rest } = props;
  return (
    <FloatButton.BackTop
      className={cn(styles.backTop, className)}
      icon={icon || <DefaultBackTop className={styles.backTopIcon} data-buried='back-top' />}
      {...rest}
    />
  )
}
