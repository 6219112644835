import { config, mapToQueryString, request } from '@utils/index';

import {
  Achievement,
  MemberPointLedgersReq,
  MemberPointLedgersRes,
  TaskReq,
  TaskResItem
} from './achieve.type';

/** 获取用户积分 */
export const getAchievement = (): Promise<APIResponse<Achievement>> => {
  const URL = `${config.api.host}${config.api.achievement.achievement
  }`;
  return request.get.call({}, URL);
};

/** 获取积分流水 */
export const getMemberPointLedgers = (
  params: MemberPointLedgersReq
): Promise<APIResponse<MemberPointLedgersRes>> => {
  const URL = `${config.api.host}${config.api.achievement.member.replace('{type}', params.type)
  }?${mapToQueryString(params ?? {})}`;
  return request.get.call({}, URL);
};

/** 获取任务列表 */
export const getTask = (
  params: TaskReq
): Promise<APIResponse<TaskResItem[]>> => {
  const URL = `${config.api.host}${config.api.achievement.task.replace('{type}', `${params.type}`)}`;
  return request.get.call({}, URL);
};

/** 接受任务 */
export const getTaskReceieve = (taskId: string): Promise<APIResponse> => {
  const URL = `${config.api.host}${config.api.achievement.receive.replace('{taskId}', taskId)}`;
  return request.get.call({}, URL);
};
