import React from 'react';

import { openModal } from 'base-components';

import { SuccessModal } from '../success-modal'

import styles from './index.module.less';

export const onOpenPostSuccessModal = async ({
  onClickView,
  questionImages,
  avatar,
  persistTime
}) => {
  const onClose = async () => {

  }

  const res = await openModal({
    mask: false,
    maskClosable: false,
    closable: true,
    className: styles.modal,
    cardClass: styles.successModalCard,
    closeClass: styles.successClose,
    headerClass: styles.successHeaderClass,
    onClose,
    children: (params) => (
      <SuccessModal
        onClickView={onClickView}
        questionImages={questionImages}
        avatar={avatar}
        persistTime={persistTime}
        {...params}
      />
    )
  });
  return res
}
