import React, { useState } from 'react';

import { Button, Input, openModal } from 'base-components';
import cn from 'classnames';
import Image from 'next/image';

import I18n from './i18n';

import styles from './index.module.less';

interface IProps {
  reject: <T = unknown>(reason?: T) => void;
  resolve: <P = unknown>(value?: P) => void;
  close: () => void;
  onVerify: (value: string) => Promise<{ success: boolean; msg?: string }>;
}

const RULE_ERROR_MESSAGE = I18n.t('RULE_ERROR_MESSAGE');
const REG = /^\w{5,16}$/;
const ConfirmModal: React.FC<IProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [value, setValue] = useState('');
  const { resolve, onVerify, close, className } = props;

  const handleChange = (e) => {
    const value = e.target.value;
    if (REG.test(value)) {
      setValue(value);
      setErrorMessage('');
    } else {
      setErrorMessage(RULE_ERROR_MESSAGE as string);
    }
  };

  const handleConfirm = async () => {
    const result = await onVerify(value);
    const { success, msg = '' } = result;
    if (success) {
      resolve(result);
      close();
    } else {
      setErrorMessage(msg);
    }
  };
  return (
    <div className={cn(styles.container, className)}>
      <Image
        alt="TRex"
        className={styles.image}
        src={require('./media/image1.webp')}
      />
      <div className={styles.button}>
        <div className={styles.title}>{I18n.t('userTitle')}</div>
        <div className={styles.desc}>{I18n.t('userDec')}</div>
        <Input
          prefix="@"
          maxLength={16}
          status={errorMessage ? 'error' : undefined}
          onChange={handleChange}
        />
        <span className={styles.errorMessage}>{errorMessage}</span>
      </div>
      <Button
        type="primary"
        size="large"
        disabled={!!errorMessage}
        onClick={handleConfirm}
        className={styles.button}
      >
        {I18n.t('userConfirm')}
      </Button>
    </div>
  );
};

const openConfirmUserNameModal = async (
  onVerify: (value: string) => Promise<{ success: boolean; msg?: string }>
) => {
  return openModal({
    mask: true,
    closable: false,
    closePosition: 'right',
    cardClass: styles.modalCard,
    children: (params) => <ConfirmModal {...params} onVerify={onVerify} />
  });
};

export { openConfirmUserNameModal };
