import React, { useMemo, useRef, useState } from 'react'

import classNames from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks'
import Image from 'next/image';
import { createRandomColorImg, isBase64, isValidSourceTRex } from 'utils';

import { prevewImage } from '@/PreviewImage';

import I18n from './i18n'
// @ts-ignore
import FailImg from './medium/imagFail.webp'
import { FeedMultipleImageType } from './type'

import styles from './index.module.less'

const WIDTH = 729
const HEIGHT = 445

export const FeedMultipleImage: React.FC<FeedMultipleImageType> = ({ imgGroup: initimgGroup, className }) => {
  const [width, setWidth] = useState<number | string>('auto')
  const [height, setheight] = useState<number | string>('auto')
  const containRef = useRef<HTMLDivElement>(null)

  useIsomorphicLayoutEffect(() => {
    getContainMsg()
    window.addEventListener('resize', getContainMsg)
    return () => window.removeEventListener('resize', getContainMsg)
  }, [])

  const imgGroup = initimgGroup.map(i => {
    if (isBase64(i)) return i
    if (isValidSourceTRex(i)) return i
    return false
  })

  const getContainMsg = () => {
    const imageContainWidth = containRef.current?.clientWidth || 0;
    const imageContainHeight = imageContainWidth / WIDTH * HEIGHT
    setWidth(imageContainWidth)
    setheight(imageContainHeight)
  }

  const onClick = (index) => {
    prevewImage({ src: imgGroup[index], imgGroup: imgGroup.filter(i => i) });
  }

  const blutDataUrlList = useMemo(() => {
    return createRandomColorImg(4)
  }, [])
  return (
    <div className={classNames(styles.imageContain, className,
      { [styles.mode1]: imgGroup.length === 1 },
      { [styles.mode2]: imgGroup.length === 2 },
      { [styles.mode3]: imgGroup.length === 3 },
      { [styles.mode4]: imgGroup.length >= 4 }
    )} style={{ width: '100%', height }} ref={containRef}>
      {
        width !== 'auto' ? imgGroup?.map((i, index) => {
          return (
            i ? (
              <div className={styles.img} onClick={(e) => {
                e.stopPropagation();
                onClick(index)
              }}>
                <Image
                  key={index}
                  alt="TRex"
                  src={i}
                  fill={true}
                  placeholder="blur"
                  blurDataURL={blutDataUrlList[index]}
                />
              </div>
            )
              : <div className={styles.errorContent}>
                <div className={styles.errorContentItem}>
                  <Image
                    alt="TRex"
                    src={FailImg}
                    width={134}
                    height={104}
                    placeholder="blur"
                    blurDataURL={blutDataUrlList[index]}
                  />
                  <div className={styles.errorTip}>{I18n.t('imageFail')}</div>
                </div>
              </div>
          )
        }) : <div style={{ height: HEIGHT, width: WIDTH }} />
      }
    </div>
  )
}
