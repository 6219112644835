import React, { useMemo, useState } from 'react';

import cn from 'classnames';
import { JoinIcon } from 'icons';
import { ImageProps } from 'next/image';
// import Image from 'next/legacy/image';
import { isValidSourceTRex } from 'utils';

import { AvatarText } from '../index';
import { CommunityItemProps } from './type';

import styles from './index.module.less';
const defaultAvatar =
  require('./media/defaultAvatar.webp') as ImageProps['src'];
const CommunityItemComponent: React.FC<CommunityItemProps> = (props) => {
  const {
    id,
    icon,
    isJoin,
    content,
    description,
    className,
    status,
    onJoin,
    onJump
  } = props;
  const [isFollow, setFollow] = useState(isJoin);
  const handleClickJoin = async (e) => {
    e.stopPropagation();
    const res = await onJoin?.();
    console.log('line32', res);
    if (res) {
      setFollow(true);
    }
  };
  const onJumpDetail = () => {
    onJump?.(status, id);
  };
  // const onFollowChange = () => {
  //   setFollow(true);
  // };
  const imageValid = useMemo(() => isValidSourceTRex(icon), [icon]);
  // useIsomorphicLayoutEffect(() => {
  //   pubsub.subscribe('FOLLOW_COMMUNITY_SUCCESS', onFollowChange);
  //   return () => {
  //     pubsub.unSubscribe('FOLLOW_COMMUNITY_SUCCESS', onFollowChange);
  //   };
  // }, [id]);
  return (
    <div className={cn(styles.container, className)} onClick={onJumpDetail}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          <AvatarText
            avatar={imageValid ? icon : defaultAvatar}
            avatarSize={40}
            isSquare={true}
          />
          <div className={cn(styles.content, 'openSansSemiBold')}>
            {content}
          </div>
        </div>
        {!isFollow && (
          <span onClick={handleClickJoin} className={styles.joinIcon}>
            <JoinIcon />
          </span>
        )}
      </div>
      <div className={cn(styles.desc)}>{description}</div>
    </div>
  );
};
const CommunityItem: React.FC<CommunityItemProps> = React.memo(
  CommunityItemComponent
);
export { CommunityItem };
