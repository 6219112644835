import { useRef, useState } from 'react';

import { message } from 'antd';
import { ScrollLoadingProps, SPIN_STATUS } from 'base-components';

import { getQuestionList, GetQuestionListReq, QuestionItem } from '@api/index';

import { FetchParams } from './type';

const LIMIT = 20;

const useAnswerListPage = (
  options: Partial<GetQuestionListReq & { total: number }>
) => {
  const {
    slug = '',
    id = 'resource_id',
    reverse = false,
    limit = LIMIT,
    with_user: withUser = true,
    own_relation: ownCommunity = [],
    with_activity: withActivity = false,
    with_community: withCommunity = false,
    'params[a_community]': communityIds,
    total,
    user_id: userId,
    ...otherProps
  } = options;
  const [rows, setRows] = useState<QuestionItem[]>([]);
  const [status, setStatus] = useState<ScrollLoadingProps['status']>(SPIN_STATUS.LOADING);
  const nextToken = useRef<string | undefined>();
  const rowsRef = useRef<QuestionItem[]>([]);

  const fetch = async (param?: FetchParams) => {
    setStatus((rowsRef.current?.length && param?.init) ? 'REFRESH' : SPIN_STATUS.LOADING);
    try {
      if (param?.init) {
        nextToken.current = '';
      }
      console.log('line78nextToken.current', param?.init, nextToken.current, param?.nextToken)
      const nextId = nextToken.current ? encodeURIComponent(nextToken.current) : ''
      const dataRes = await getQuestionList({
        slug: param?.slug ? param?.slug : slug,
        id,
        reverse,
        limit,
        with_activity: param?.withActivity ?? withActivity,
        user_id: userId,
        with_community: param?.withCommunity ?? withCommunity,
        own_relation: param?.ownCommunity ? param?.ownCommunity : ownCommunity,
        'params[a_cate]': param?.aCate ?? 30,
        with_user: withUser,
        withCollection: param?.withCollection,
        'params[a_community]': communityIds,
        id_lt: param?.nextToken ?? nextId,
        ...otherProps
      });
      const { obj } = dataRes;
      let newRows = obj.rows || [];
      if (param?.init) {
        rowsRef.current = newRows;
      } else {
        if (nextToken.current) {
          newRows = rowsRef.current.concat(newRows);
        }
      }
      rowsRef.current = newRows;
      nextToken.current = obj.nextToken;
      // total 获取数量
      if (!!total && newRows.length >= total) {
        rowsRef.current = newRows.slice(0, 50);
        nextToken.current = undefined;
      }
      setRows(rowsRef.current);
      setStatus(SPIN_STATUS.SUCCESS);
      return {
        rows: rowsRef.current,
        token: obj.nextToken
      };
    } catch {
      message.error('system.error')
      console.log('system.error')
    }
    setStatus(SPIN_STATUS.SUCCESS);
  };

  return {
    fetch,
    status,
    rows,
    nextToken
  };
};

export { useAnswerListPage };
