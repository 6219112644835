import React, { useImperativeHandle, useRef, useState } from 'react';

import { If, message, Progress } from 'base-components'
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';

import I18n from '../utils/i18n'
import { Author } from './author';
import { AnswerDescription } from './description';
import { PostButton } from './post-button';
import { TextCount } from './text-count';
import { AnswerTitle } from './title';
import type { AnswerModalProps } from './type';

import styles from './index.module.less';

export const AnswerModal: React.FC<AnswerModalProps> = (props) => {
  const u = navigator.userAgent;
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  const descRef = useRef<any>(null);
  const titleRef = useRef<any>(null);
  const contentRef = useRef<any>(null);

  const [titleInTop, setTitleInTop] = useState(false)
  const [dbData, setDbData] = useState({
    _id: ''
  });
  const [description, setDesc] = useState('');
  const [descLength, setCurrentDescLength] = useState(0); // 纯文本长度
  const [descText, setDescText] = useState(''); // 纯文本内容
  const [posting, setPosting] = useState(false);
  const [percent, setPercent] = useState(0)
  const [barShow, setBarShow] = useState(true)

  const {
    questionId,
    avatar,
    nickName,
    communityIcon,
    communityName,
    title,
    alioss,
    className,
    maxDescLength = 8000,
    refData,
    onPost,
    ...params
  } = props;

  const getInitData = async () => {
    try {
      const { queryLastBulletin } = await import('../utils');
      const db = await queryLastBulletin();
      if (db) {
        setDbData(db);
        if (questionId === db.questionId) {
          setDesc(db?.description || '');
          setCurrentDescLength(db?.descLength || '');
        }
      }
    } catch (e) {
      console.log('ANSWER--DB--QUERY--ERROR::', e)
    }
  };

  const onContentScroll = () => {
    if (contentRef?.current?.scrollTop > (titleRef?.current?.clientHeight - 48)) {
      setTitleInTop(true)
    } else {
      setTitleInTop(false)
    }
  }

  const onClickPost = async () => {
    setPosting(true)
    setPercent(90)
    const latestDesc = await descRef?.current?.getLatestData?.();
    const answerDetails = {
      questionId,
      desc: latestDesc
    }

    const res = await onPost?.(answerDetails)
    params.resolve({
      answerDetails: res?.obj,
      submitSuccess: res?.success,
      submitMsg: res?.msg,
      submitCode: res?.code,
      answerId: res?.obj?.id
    });

    if (res?.success) {
      setPercent(100)
      try {
        if (dbData._id) {
          const { deleteBulletin } = await import('../utils');
          deleteBulletin(dbData)
        }
      } catch (e) {
        console.log('ANSWER--DB--DELETE--ERROR::', e)
        setPosting(false)
      }
      setTimeout(() => {
        // 等进度条走到100再关弹窗
        params.close();
        message.success(I18n.t('answerPostedSuccess'))
      }, 500);
    } else {
      if (res.code === 1008) {
        message.error(I18n.t('questionStopTips'))
      } else {
        message.error(I18n.t('answerPostedFail'))
      }
      // 避免进度条归零有动效，暂时未找到更合适的方法
      setBarShow(false)
      setPercent(0)
      setTimeout(() => {
        setBarShow(true)
      }, 500);
    }
    setPosting(false)
  }

  useIsomorphicLayoutEffect(() => {
    getInitData();
  }, []);

  useImperativeHandle(refData, () => {
    return {
      dbData,
      description,
      descLength
    };
  }, [dbData, description, descLength]);

  const inputBlur = () => {
    if (isIOS) {
      window.addEventListener('touchmove', function () {
        descRef.current?.editorRef?.current?.blur?.()
      });
    }
  }

  useIsomorphicLayoutEffect(() => {
    if (isIOS) {
      window.addEventListener('scroll', inputBlur)
      contentRef?.current?.addEventListener('scroll', inputBlur)
      return () => {
        window.removeEventListener('scroll', inputBlur);
        contentRef?.current?.addEventListener('scroll', inputBlur);
      }
    }
  }, [])

  const postDisabled =
    !(descText?.replace('\n', '').trim().length) ||
    posting

  return (
    <div className={cn(styles.container, className)}>
      <If
        condition={barShow}
        true={<Progress
          className={styles.bar}
          type={'line'}
          percent={percent}
          showInfo={false}
          strokeColor={'var(--progressBar)'}
          strokeWidth={2}
        />}
        false={null}
      />
      <Author
        title={title}
        avatar={avatar}
        nickName={nickName}
        communityIcon={communityIcon}
        communityName={communityName}
        titleInTop={titleInTop}
      />
      <div className={styles.content}>
        <div
          id="answerScrollingContainer"
          ref={contentRef}
          onScroll={onContentScroll}
          className={styles.scrollContent}
        >
          <AnswerTitle
            title={title}
            titleRef={titleRef}
          />
          <AnswerDescription
            ref={descRef}
            alioss={alioss}
            maxDescLength={maxDescLength}
            setCurrentDescLength={setCurrentDescLength}
            setDesc={setDesc}
            setDescText={setDescText}
            defaultEditorValue={description}
            scrollingContainer="#answerScrollingContainer"
          />
          <div className={styles.separation} />
        </div>
      </div>
      <div id="answerEditorToolbar" />
      <div className={styles.textCountContainer}>
        <TextCount
          maxLength={maxDescLength}
          currentLength={descLength}
        />
      </div>
      <PostButton disabled={postDisabled} onClickPost={onClickPost} />
    </div>
  );
};
