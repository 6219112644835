import React from 'react';

import { More } from 'atom-components';
import { Avatar, Button, RichEditor } from 'base-components';
import cn from 'classnames';
import { useClient } from 'hooks';
import { formatThousandthsNum, isValidSourceTRex } from 'utils';

import { CountsRow } from '../counts-row';
import { HeaderBg } from '../headerBg';
import I18n from './i18n';
import { CommunityHeaderProps } from './type';

import styles from './communityHeader.module.less';

export const CommunityHeader: React.FC<
  CommunityHeaderProps & { bgImg: string }
> = (props) => {
  const {
    bgImg,
    likes = 0,
    answers = 0,
    views = 0,
    desc,
    communityName,
    creator,
    avatar,
    isJoin,
    onFollow,
    following
  } = props;
  const isPc = useClient(576);

  const countsMap = [{
    label: I18n.t('likes'),
    num: formatThousandthsNum(likes)
  }, {
    label: I18n.t('answers'),
    num: formatThousandthsNum(answers)
  }, {
    label: I18n.t('views'),
    num: formatThousandthsNum(views)
  }]

  const renderIcon = isValidSourceTRex(avatar, 'image') ? avatar : '';
  const colorType = isJoin ? 'black' : 'green';

  return (
    <HeaderBg bg={bgImg}>
      <div className={styles.main}>
        <div className={styles.avatarBox}>
          <div className={styles.avatar}>
            <Avatar
              avatarSize={isPc ? 120 : 100}
              avatar={renderIcon}
              borderRadius={24}
              className={styles.avatarComponent}
            />
          </div>
          <Button
            type="primary"
            size="middle"
            colorType={colorType}
            className={styles.followBtn}
            onClick={onFollow}
            disabled={following}
          >
            {isJoin ? I18n.t('unFollow') : I18n.t('follow')}
          </Button>
        </div>
        <div className={styles.nameRow}>
          <span className={cn(styles.communityName, 'openSansBold')}>
            {communityName}
          </span>
          <span className={styles.creator}>
            {I18n.t('creator')}&nbsp;:&nbsp;
            <span className={styles.creatorName}>{creator}</span>
          </span>
        </div>
        <div className={styles.descRow}>
          <RichEditor
            className={styles.desc}
            defaultValue={desc}
            readOnly
            maxLength={78}
            more={<More isExpand={false} />}
            hide={<More isExpand={true} />}
          />
        </div>
        <CountsRow countsMap={countsMap} />
      </div>
    </HeaderBg>
  );
};
