import React, { useEffect } from 'react'

export const useProgress = ({ refs, onProgress } : {
  refs: React.RefObject<HTMLDivElement>,
  onProgress: (progress: number) => void
}) => {
  const [left, setleft] = React.useState<number>(0)
  const [isTouchDevice, setIsTouchDevice] = React.useState<boolean>(false)

  useEffect(() => {
    const isTouchDevice = !!(
      typeof window !== 'undefined' &&
      typeof navigator !== 'undefined' &&
      ('ontouchstart' in window || navigator.maxTouchPoints > 0)
    )
    setIsTouchDevice(isTouchDevice)
  }, [])

  const onDrag = (e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
    if (!refs.current) return
    const { offsetWidth } = refs.current;
    const stop = e.currentTarget;
    const { offsetLeft } = stop;
    const start =
    'targetTouches' in e ? e.targetTouches[0].pageX : e.pageX
    stop.style.left = offsetLeft + 'px';
    const move = (e: TouchEvent | MouseEvent) => {
      const pageX = 'targetTouches' in e ? e.targetTouches[0].pageX : e.pageX
      let val = offsetLeft + pageX - start;
      if (val <= 0) val = 0;
      if (val >= offsetWidth - 15) val = offsetWidth - 15;
      setleft(val)
      const progress = val / offsetWidth
      onProgress(Number(progress.toFixed(2)))
    };

    const clear = () => {
      if (isTouchDevice) {
        document.removeEventListener('touchmove', move);
        document.removeEventListener('touchend', clear);
        return
      }
      document.removeEventListener('mousemove', move);
      document.removeEventListener('mouseup', clear);
      document.removeEventListener('mouseleave', clear);
    };
    if (isTouchDevice) {
      document.addEventListener('touchmove', move);
      document.addEventListener('touchend', clear);
      return
    }
    document.addEventListener('mousemove', move);
    document.addEventListener('mouseup', clear);
    document.addEventListener('mouseleave', clear);
  }

  console.log('isTouchDevice', isTouchDevice)
  return {
    onDrag,
    left
  }
}
