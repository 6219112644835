import React, { useImperativeHandle, useRef, useState } from 'react';

import { getImagesByRichText, If, message, Progress } from 'base-components'
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';

import I18n from '../utils/i18n'
import { Author } from './author';
import { Community } from './community';
import { FocusEleObj } from './constants';
import { QuestionDescription } from './description';
import { PostButton } from './post-button';
import { TextCount } from './text-count';
import { QuestionTitle } from './title';
import type { CommunityItemProps, QuestionModalProps } from './type';

import styles from './index.module.less';

export const QuestionModal: React.FC<QuestionModalProps> = (props) => {
  const u = navigator.userAgent;
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  const questionTitleRef = useRef<any>(null);
  const contentRef = useRef<any>(null);
  const titleRef = useRef<any>(null);
  const descRef = useRef<any>(null);
  const [titleInTop, setTitleInTop] = useState(false)
  const [dbData, setDbData] = useState<any>();
  const [focusElement, setFocusElement] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDesc] = useState('');
  const [descLength, setCurrentDescLength] = useState(0);
  const [posting, setPosting] = useState(false);
  const [community, setCommunity] = useState<CommunityItemProps | undefined>();
  const [percent, setPercent] = useState(0)
  const [barShow, setBarShow] = useState(true)

  const {
    avatar,
    nickName,
    userName,
    alioss,
    className,
    maxTitleLength = 500,
    maxDescLength = 5000,
    refData,
    onPost,
    communityListRequestApi,
    communityListQueryParams,
    ...params
  } = props;

  const getInitData = async () => {
    try {
      const { queryLastBulletin } = await import('../utils');

      const db = await queryLastBulletin();
      if (db) {
        setDbData(db);
        setTitle(db.title || '');
        setCommunity(db.community || {})
        setDesc(db.description || '');
        setCurrentDescLength(db.descLength || '');
      }
    } catch (e) {
      console.log('QUESTION--DB--QUERY--ERROR::', e)
    }
  };
  const onClickPost = async () => {
    setPosting(true)
    setPercent(90)
    const latestDesc = await descRef?.current?.getLatestData?.();
    const images = getImagesByRichText(latestDesc)
    const questionDetails = {
      title,
      desc: latestDesc,
      communityId: community?.id
    }

    const res = await onPost?.(questionDetails)
    params.resolve({
      questionDetails,
      images,
      submitSuccess: res?.success,
      questionId: res?.obj?.view_id
    });

    if (res?.success) {
      setPercent(100)
      try {
        if (dbData._id) {
          const { deleteBulletin } = await import('../utils');
          deleteBulletin(dbData)
        }
      } catch (e) {
        console.log('QUESTION--DB--DELETE--ERROR::', e)
        setPosting(false)
      }
      setTimeout(() => {
        // 等进度条走到100再关弹窗
        params.close();
      }, 500);
    } else {
      if (res.code === 1003) {
        message.error(I18n.t('community_deleted'))
      } else {
        message.error(I18n.t('questionPostedFail'))
      }
      // 避免进度条归零有动效，暂时未找到更合适的方法
      setBarShow(false)
      setPercent(0)
      setTimeout(() => {
        setBarShow(true)
      }, 500);
    }
    setPosting(false)
  }
  const inputBlur = () => {
    if (isIOS) {
      window.addEventListener('touchmove', function () {
        questionTitleRef.current?.blur?.();
        descRef.current?.editorRef?.current?.blur?.()
      });
    }
  }

  const onContentScroll = () => {
    if (contentRef?.current?.scrollTop > (titleRef?.current?.clientHeight - 48)) {
      setTitleInTop(true)
    } else {
      setTitleInTop(false)
    }
  }

  useIsomorphicLayoutEffect(() => {
    if (isIOS) {
      window.addEventListener('scroll', inputBlur)
      contentRef?.current?.addEventListener('scroll', inputBlur)
      return () => {
        window.removeEventListener('scroll', inputBlur);
        contentRef?.current?.addEventListener('scroll', inputBlur);
      }
    }
  }, [])

  useIsomorphicLayoutEffect(() => {
    getInitData();
  }, []);

  useImperativeHandle(refData, () => {
    return {
      dbData,
      title,
      community,
      description,
      descLength
    };
  }, [dbData, title, community, description, descLength]);

  return (
    <div className={cn(styles.container, className)}>
      <If
        condition={barShow}
        true={<Progress
          className={styles.bar}
          type={'line'}
          percent={percent}
          showInfo={false}
          strokeColor={'var(--progressBar)'}
          strokeWidth={2}
        />}
        false={null}
      />
      <Author
        avatar={avatar}
        nickName={nickName}
        userName={userName}
        titleInTop={titleInTop}
        title={title}
      />
      <div
        id="scrollingContainer"
        ref={contentRef}
        className={styles.content}
        onScroll={onContentScroll}
      >
        <QuestionTitle
          ref={questionTitleRef}
          titleRef={titleRef}
          maxTitleLength={maxTitleLength}
          setFocusElement={setFocusElement}
          title={title}
          setTitle={setTitle}
        />
        <Community
          community={community}
          setCommunity={setCommunity}
          communityListRequestApi={communityListRequestApi}
          communityListQueryParams={communityListQueryParams}
        />
        <QuestionDescription
          ref={descRef}
          alioss={alioss}
          maxDescLength={maxDescLength}
          setFocusElement={setFocusElement}
          setCurrentDescLength={setCurrentDescLength}
          setDesc={setDesc}
          defaultEditorValue={description}
          scrollingContainer="#scrollingContainer"
        />
        <div className={styles.separation} />
      </div>
      <div
        id="questionEditorToolbar"
        className={cn({ [styles.none]: focusElement === FocusEleObj.TITLE })}
      />
      <div className={styles.textCountContainer}>
        <TextCount
          maxLength={
            focusElement === FocusEleObj.TITLE ? maxTitleLength : maxDescLength
          }
          currentLength={
            focusElement === FocusEleObj.TITLE ? title.length : descLength
          }
        />
      </div>
      <PostButton disabled={!((title.trim()).length) || posting} onClickPost={onClickPost} />
    </div>
  );
};
