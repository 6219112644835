import React from 'react';

import { ImageText } from 'base-components/image-text';
import cn from 'classnames';
import {
  FacebookAuthProvider,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  TwitterAuthProvider,
  UserCredential
} from 'firebase/auth';
import { useIsomorphicLayoutEffect } from 'hooks';

import { AUTH_TYPE, MODE } from './constant';
import { ThirdPartyLoginProps } from './type';

import styles from './index.module.less';

const getProvider = (type: string) => {
  let provider;
  switch (type) {
    case AUTH_TYPE.TWITTER:
      provider = new TwitterAuthProvider();
      provider.addScope('email');
      break;

    case AUTH_TYPE.FACEBOOK:
      provider = new FacebookAuthProvider();
      provider.addScope('email');
      break;

    case AUTH_TYPE.GOOGLE:
      provider = new GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      break;
    default:
      break;
  }
  return provider;
};

const ThirdPartyLogin: React.FC<ThirdPartyLoginProps> = (props) => {
  const {
    oAuthList,
    mode,
    className,
    itemClassName,
    textClassName,
    loginSuccess,
    handleErrMsg
  } = props;

  // 三方登录 2
  const quickLogin = async (params?: UserCredential | null) => {
    try {
      let result = params;
      if (!result) {
        const _auth = getAuth();
        result = await getRedirectResult(_auth);
      }
      if (!result) {
        return;
      }
      const user = result.user;
      if (typeof loginSuccess === 'function') {
        loginSuccess(user);
      }
    } catch (error) {
      handleErrMsg(error);
    }
  };

  // 三方重定向登录 1
  const triggerLogin = async (type: string) => {
    const provider = getProvider(type);
    const auth = getAuth();
    if (mode === MODE.REDIRECT) {
      signInWithRedirect(auth, provider);
      return;
    }
    try {
      const res = await signInWithPopup(auth, provider);
      quickLogin(res);
    } catch (error) {
      handleErrMsg(error);
    }
  };

  useIsomorphicLayoutEffect(() => {
    quickLogin();
  }, []);

  if (!oAuthList?.length) {
    return null;
  }

  return (
    <div className={cn(styles.oAuthLogin, className)}>
      {oAuthList.map((item) => {
        const { text, src, type } = item;
        return (
          <ImageText
            key={type}
            btnText={text}
            src={src}
            imageWidth={30}
            imageHeight={30}
            className={itemClassName}
            textClassName={textClassName}
            onClick={() => triggerLogin(type)}
          />
        );
      })}
    </div>
  );
};

export { ThirdPartyLogin };
