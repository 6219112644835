import React from 'react';
import { FixedSizeList as List } from 'react-window';

import cn from 'classnames';

import { Item } from '../item';

import styles from '../index.module.less';

export const SelectList = (props) => {
  const { optionsData, optionWrapperClass, virtualListConfig, searchListScrollBottomTip, ...rest } = props;
  if (!optionsData?.length) {
    return null
  }
  if (!virtualListConfig) {
    return (
      <ul className={cn(styles.menuWrap, optionWrapperClass)}>
        {optionsData.map((item, index) => {
          return (
            <Item key={index} item={item} {...rest} />
          )
        })}
      </ul>
    )
  }
  const itemCount = searchListScrollBottomTip ? optionsData.length + 1 : optionsData.length
  return (
    <List
      height={virtualListConfig.height}
      width={virtualListConfig.width}
      itemCount={itemCount}
      itemData={optionsData}
      itemSize={virtualListConfig.itemSize}
      outerElementType="ul"
      className={cn(styles.menuWrap, optionWrapperClass)}
    >
      {(params) => {
        const { style, index, data } = params;
        if (index === optionsData.length) {
          return <li style={style} className={styles.emptyListItem}>{searchListScrollBottomTip}</li>
        }
        return (
          <Item style={style} item={data[index]} {...rest} />
        )
      }}
    </List>
  )
}
