import { createContext, useReducer } from 'react';

import { useIsomorphicLayoutEffect } from 'hooks';
import { pubsub } from 'utils';

import { IActionEum, IContext, IReduce, IState } from './type';

const initState = {
  isLogin: false,
  userInfo: {},
  socialUserInfo: {},
  walletAddress: ''
} as IState;

const initContext = {
  state: initState,
  dispatch: () => {}
}

// eslint-disable-next-line react-func/max-lines-per-function
const reduce: IReduce = (state, action) => {
  switch (action.type) {
    case IActionEum.SET_USERINFO: {
      const newState = {
        ...state,
        userInfo: { ...action.payload },
        isLogin: true
      };
      localStorage.setItem('userInfo', JSON.stringify(newState.userInfo))
      return newState;
    }
    case IActionEum.SET_SOCIAL_USERINFO: {
      const newState = {
        ...state,
        socialUserInfo: { ...action.payload }
      };
      localStorage.setItem('socialInfo', JSON.stringify(newState.socialUserInfo))
      return newState;
    }
    case IActionEum.UPDATE_WALLET_ADDRESS: {
      const walletAddress = action.payload;
      const newState = {
        ...state,
        walletAddress
      }
      localStorage.setItem('walletAddress', walletAddress);
      return newState;
    }
    case IActionEum.UPDATE_USERINFO: {
      const newState = {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload },
        isLogin: true
      };
      localStorage.setItem('userInfo', JSON.stringify(newState.userInfo))
      return newState;
    }
    case IActionEum.UPDATE_SOCIAL_USERINFO: {
      const newState = {
        ...state,
        socialUserInfo: { ...state.socialUserInfo, ...action.payload }
      };
      localStorage.setItem('socialInfo', JSON.stringify(newState.socialUserInfo))
      return newState;
    }
    case IActionEum.REMOVE_USERINFO: {
      // 视觉上保留信息状态，刷新页面之后初始化需要登录
      const newState = { ...state, isLogin: false };
      localStorage.removeItem?.('socialInfo');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('walletAddress');
      return newState;
    }
    default:
      throw new Error();
  }
}

export const useGlobalData = () => {
  const [state, dispatch] = useReducer<IReduce>(reduce, initState);
  const handleLogout = () => {
    dispatch({
      type: IActionEum.REMOVE_USERINFO
    })
  }
  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe('LOGOUT', handleLogout);
    return () => {
      pubsub.unSubscribe('LOGOUT', handleLogout);
    };
  }, [])
  return {
    state,
    dispatch
  }
}

export const TRexContext = createContext<IContext>(initContext as IContext);
