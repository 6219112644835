import React from 'react';

import { Avatar, Button } from 'base-components';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks'
import { isValidSourceTRex } from 'utils';

import I18n from './i18n';
import type { SuccessModalProps } from './type';

import styles from './index.module.less';

export const SuccessModal: React.FC<SuccessModalProps> = (props) => {
  const {
    onClickView,
    questionImages,
    avatar,
    className,
    persistTime,
    ...params
  } = props;

  useIsomorphicLayoutEffect(() => {
    if (persistTime) {
      setTimeout(() => {
        params.close();
      }, persistTime)
    }
  })

  const onClick = () => {
    onClickView?.();
    params.close();
  }

  const renderImg = questionImages?.[0] || avatar

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.content}>
        {
          isValidSourceTRex(renderImg, 'image') && <Avatar
            avatar={renderImg}
            avatarSize={40}
            borderRadius={8}
            className={styles.icon}
          />
        }
        <p className={cn(styles.text, 'openSansSemiBold')}>{I18n.t('question_post_success')}</p>
      </div>
      <Button
        type='primary'
        size='small'
        onClick={onClick}
      >
        {I18n.t('question_post_success_view')}
      </Button>
    </div>
  );
};
