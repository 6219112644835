import { useState } from 'react';

import { CommunityItem, getCommunityDetail } from '@api/index';
import { RELATION_TYPE } from '@constants/index';

export const useGetCommunityDetail = () => {
  const [communityDetail, setCommunityDetail] = useState<CommunityItem | undefined>();
  const [isLoading, setLoading] = useState(false);
  const [isStop, setIsStop] = useState(false);

  const fetchCommunityDetail = async (id: string) => {
    setLoading(true);
    const dataRes = await getCommunityDetail({
      id,
      with_user: true,
      with_relations: [RELATION_TYPE.JOIN]
    });
    if (dataRes.success) {
      setCommunityDetail(dataRes.obj);
    } else {
      if (dataRes.code === 1003) {
        setIsStop(true)
      }
    }
    setLoading(false)
  };
  return {
    isStop,
    isLoading,
    fetchCommunityDetail,
    communityDetail
  }
};
