import type { ReactNode } from 'react';

import type { CoreElProps, EditorRefCurrent as EditorRef, UseEditorParams } from '../CoreEl';
import type { SummaryProps } from '../Summary';

export enum RenderMode {
  SUMMARY = 'summary',
  FULL = 'full'
}

export interface RichEditorProps extends UseEditorParams,
  Pick<CoreElProps, 'toolbarClass'>,
  Pick<SummaryProps, 'isImages' | 'more' | 'moreClass'> {
  /** 收起按钮 */
  hide?: ReactNode;
  /** @deprecated (已废弃 请以 defaultIsExpend 来控制默认摘要还是全文) summary 描述摘要的形式；适合feed流列表 full 全文展示的形式；适合详情 */
  mode?: 'summary' | 'full' | RenderMode;
  /** @deprecated 收起状态下最多显示几行，超出...（已废弃，不再以行数为约束，修改为以字符长度为约束） */
  line?: number;
  /** @deprecated state: true 收起 state: false 展开 */
  onMoreClick?: (state: boolean) => void;
  /** hideChange 展开收起状态发生变更的回调 */
  hideChange?: (state: boolean) => void;
  /** 是否默认展开 默认值 false */
  defaultIsExpend?: boolean;
  /** 编辑态 最大可输入字数 ｜ 只读态 在摘要模式下最多显示的字符数 */
  maxLength?: number;
  /** 收起按钮 class */
  hideClass?: string;
  /** @deprecated 展开/收起 按钮（已废弃，如果不需要渲染不传 more 和 hide 即可） */
  isMore?: boolean;
  className?: string;
  summaryClass?: string;
  editorClass?: string;
  /** 如果某些操作 例如 插入链接 H5 和 PC 交互形式不一样 需要传入此参数 告知 编辑器 H5 的宽度是多少 */
  H5Width?: number;
}

export type OnMoreClick = (e?: any) => void;
export type EditorRefCurrent = EditorRef & {
  onMoreClick: OnMoreClick;
}
