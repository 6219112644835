import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';

import { InjectDomProps } from './type';

const injectFn = () => {
  let elCopy: HTMLElement | null = null;
  return ({ wrapper, children, unique = true }: InjectDomProps) => {
    let el: HTMLElement | null = document.createElement('div');
    wrapper.append(el);
    const removePreview = () => {
      if (!el) return;
      wrapper.removeChild(el);
      el = null;
      elCopy = null;
    };
    if (elCopy && unique) {
      elCopy?.parentNode?.removeChild?.(elCopy);
    }
    // 不唯一的时候，不记录elCopy
    elCopy = unique ? el : null;
    return new Promise((resolve, reject) => {
      const root = createRoot(el as HTMLElement);
      root.render(
        <Fragment>{children({ resolve, reject, removePreview })}</Fragment>
      );
    });
  };
};

export const injectDom = injectFn();
