import React from 'react';

import { AddIcon } from 'icons';

import { onOpenCommunityModal } from './addFun'
import type { AddBtnProps } from './type'

import styles from './index.module.less'

export const AddBtn: React.FC<AddBtnProps> = (props) => {
  const { setCommunity, communityListRequestApi, communityListQueryParams } = props;
  const onClick = () => {
    onOpenCommunityModal({
      setCommunity,
      communityListRequestApi,
      communityListQueryParams
    })
  }
  return (
    <div className={styles.addBtn} onClick={onClick}>
      <AddIcon className={styles.addIcon} />
    </div>
  )
}
